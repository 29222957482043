const apiRoutes = {
  publication: '/publications/:id',
  application: '/publications/:publicationId/postulation/:urlProcessType',
  applicationFields: '/publications/:publicationId/application_fields',
  companyPublications: '/company/:companyId/publications',
  companyDepartments: '/company/:companyId/departments',
  companies: '/companies',
  company: '/company/:companyUuid',
  contractTypes: '/contract_types',
  nationalities: '/nationalities',
  offerLetter: '/offer_letter/:id',
  offerLetterResolution: '/offer_letter/:id/resolution',
  postulantProfile: '/postulant-profile/:id',
  chat: '/chat/:id/messages',
  sendChatMessage: '/chat/:id/message',
};

export default apiRoutes;
