import UploadIcon from '@rexmas/rexmas-shared/images/upload-highlight.svg';
import xIcon from '@rexmas/rexmas-shared/images/x-dash.svg';
import { Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import { UnderlinedFileInput } from '@rexmas/rexmas-shared/lib/components/molecules/inputs';
import React, { MouseEvent } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { IFileRejection } from 'types/IFileRejection';

const StyledFileInput = styled(UnderlinedFileInput)`
  margin-top: 16px;
`;

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  name: string;
  placeholder: string;
  helperText?: string;
  className?: string;
  maxSize: number;
  accept: string[];
  rules: RegisterOptions;
  formMethods: UseFormReturn;
}

const FileInput: React.FunctionComponent<Props> = ({
  name,
  placeholder = '',
  helperText = '',
  className = '',
  maxSize,
  accept,
  rules,
  formMethods,
}) => {
  const getInputIcon = (value?: File) =>
    value ? (
      <StyledIcon
        src={xIcon}
        size={24}
        onClick={(event: MouseEvent) => {
          event.stopPropagation();
          formMethods.setValue(name, null);
          formMethods.trigger(name);
        }}
      />
    ) : (
      <StyledIcon src={UploadIcon} size={24} />
    );

  return (
    <Controller
      name={`${name}` as const}
      rules={rules}
      control={formMethods.control}
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => (
        <StyledFileInput
          value={value?.name || value?.file?.name || ''}
          onChange={(accepted: File[], rejected: IFileRejection[]) => {
            if (accepted.length > 0) {
              onChange(accepted[0]);
            } else {
              onChange(rejected[0]);
            }
            formMethods.trigger(name);
          }}
          placeholder={placeholder}
          error={invalid}
          accept={accept}
          maxSize={maxSize}
          helperText={invalid ? error?.message : helperText}
          InputProps={{
            endAdornment: getInputIcon(value),
          }}
          className={className}
        />
      )}
    />
  );
};

export default FileInput;
