import DOMPurify from 'dompurify';
import React from 'react';

interface Props {
  html: string | undefined;
}

const PurifiedElement: React.FunctionComponent<Props> = ({ html = '' }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(html),
    }}
  />
);

export default PurifiedElement;
