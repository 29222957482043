import moment from '@rexmas/rexmas-shared/lib/lib/moment';
import { UseFormReturn } from 'react-hook-form';
import IConstant from 'types/IConstant';
import {
  IPostulantProfileFormData,
  IPostulantProfileFormValue,
  IPostulantProfileSection,
} from 'types/IPostulantProfile';
import { Moment } from 'types/misc';

export const getIsSectionFilled = (
  section: IPostulantProfileSection,
  formMethods: UseFormReturn<IPostulantProfileFormData>,
): boolean => {
  const fieldIds = section.fields.map((field) => String(field.id));
  const sectionValues = formMethods.watch(fieldIds);
  return sectionValues.every((value) => value || value === 0);
};

export const getIsFormValueConstant = (
  formValue: IPostulantProfileFormValue,
): formValue is IConstant => {
  return (
    !!formValue && Object.prototype.hasOwnProperty.call(formValue, 'value')
  );
};

export const getIsFormValueMoment = (
  formValue: IPostulantProfileFormValue,
): formValue is Moment => {
  return moment.isMoment(formValue);
};
