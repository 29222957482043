import { SelectMultiple as SelectMultipleQuestion } from '@rexmas/rexmas-shared/lib/components/molecules/questions';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { toggleElementInArray } from '@rexmas/rexmas-shared/lib/utils/misc';
import React from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { IQuestion } from 'types/IQuestion';

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const StyledSelectMultipleQuestion = styled(SelectMultipleQuestion)`
  margin-bottom: 16px;
  .SelectMultiple-ChoiceText {
    font-size: 14px;
  }
  .MuiCheckbox-root {
    color: ${colors.highlighted};
    width: 24px;
    height: 24px;
    &:hover {
      background-color: ${colors.veryLightBlueGrey20};
    }
  }
`;

interface Props {
  name: number;
  question: IQuestion;
  rules?: RegisterOptions;
  formMethods: UseFormReturn;
}

const SelectMultiple: React.FunctionComponent<Props> = ({
  name,
  question,
  rules,
  formMethods,
}) => {
  return (
    <Controller
      name={`${name}` as const}
      control={formMethods?.control}
      rules={rules}
      defaultValue={[]}
      render={({ field: { onChange, value: selectedIndexes } }) => (
        <StyledSelectMultipleQuestion
          selectedIndexes={selectedIndexes}
          question={question}
          onChange={(event: ChangeEvent) =>
            onChange(
              toggleElementInArray(selectedIndexes, Number(event.target.value)),
            )
          }
        />
      )}
    />
  );
};

export default SelectMultiple;
