import { Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import { Skeleton } from '@rexmas/rexmas-shared/lib/components/atoms/misc';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import useDictionary from 'hooks/useDictionary';
import logoSeleccion from 'images/logo-seleccion-circle.svg';
import React from 'react';
import styled from 'styled-components';

const ColoredBackground = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.darkDash};
  padding: 24px 24px 274px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 24px 90px 274px;
  }
`;

const Logo = styled(Icon)`
  height: 56px;
  width: 133px;
  margin: 0 auto 24px 0;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 850px;
`;

const StyledText = styled(Text)`
  color: ${colors.whiteTwo};
`;

const SelectionProcessName = styled(Text)`
  color: ${colors.powderBlue};
`;

interface Props {
  isLoading?: boolean;
  selectionProcessName?: string;
}

const ChatHeader: React.FunctionComponent<Props> = ({
  isLoading,
  selectionProcessName,
}) => {
  const dictionary = useDictionary();

  return (
    <ColoredBackground>
      <Logo src={logoSeleccion} />
      <HeaderTextContainer>
        <StyledText variant="heading6">{dictionary.chat.header}</StyledText>
        <StyledText variant="style5">
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              {dictionary.chat.headerTitle}{' '}
              <SelectionProcessName variant="style5">
                {selectionProcessName}
              </SelectionProcessName>
            </>
          )}
        </StyledText>
      </HeaderTextContainer>
    </ColoredBackground>
  );
};

export default ChatHeader;
