import { showMessage } from '@rexmas/rexmas-shared/lib/redux/actions/messages';
import { useIntersectionObserver } from '@rexmas/rexmas-shared/lib/utils/hooks';
import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import { JobOffersTemplate } from 'components/templates';
import dictionary from 'conf/dictionaries/es_CL';
import urls from 'conf/navigation';
import { useCustomizeCompany } from 'mutations/companies';
import { useCompanies } from 'queries/companies';
import { useContractTypes } from 'queries/contractTypes';
import { useCompanyDepartments } from 'queries/departments';
import { useCompanyPublications } from 'queries/publication';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  CustomizeCompanyMutationData,
  ICompany,
  ICustomizeCompanyFormData,
} from 'types/ICompany';
import {
  IPublicationListData,
  IPublicationsFiltersFormData,
} from 'types/IPublication';

import Template404 from '../templates/404';

interface Params {
  companySlug: string;
}

const JobOffers: React.FunctionComponent = () => {
  const { companySlug } = useParams<Params>();
  const history = useHistory();
  const dispatch = useDispatch();
  const filtersFormMethods = useForm<IPublicationsFiltersFormData>();
  const [isCustomizeWebsiteDrawerOpen, setIsCustomizeWebsiteDrawerOpen] =
    useState(false);
  const filters = filtersFormMethods.watch();
  const { data: companiesList, isLoading: isLoadingCompanies } = useCompanies();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const customizeCompanyFormMethods = useForm<ICustomizeCompanyFormData>();
  const customizeCompanyMutation = useCustomizeCompany(selectedCompanyId);
  const { data: departments } = useCompanyDepartments(selectedCompanyId);
  const { data: contractTypes } = useContractTypes();
  const fetchNextPageRef = useRef<HTMLDivElement>(null);
  const {
    data: publicationsResults,
    isLoading: isLoadingPublications,
    hasNextPage,
    fetchNextPage,
  } = useCompanyPublications(selectedCompanyId, filters);

  const jobOffers = publicationsResults?.pages.reduce(
    (array: IPublicationListData[], page) => array.concat(page.results),
    [],
  );

  useIntersectionObserver({
    target: fetchNextPageRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage || !isLoadingPublications,
  });

  const setCompanyHeaderValues = (company: ICompany) => {
    customizeCompanyFormMethods.setValue('button_color', company.button_color);
    customizeCompanyFormMethods.setValue('text_color', company.text_color);
    customizeCompanyFormMethods.setValue('header_text', company.header_text);
    customizeCompanyFormMethods.setValue('thumbnail_logo', []);
    customizeCompanyFormMethods.setValue(
      'header_background_color',
      company.header_background_color,
    );
  };

  const setCompanyFooterValues = (company: ICompany) => {
    customizeCompanyFormMethods.setValue('footer_text', company.footer_text);
  };

  const onChangeSelectedCompany = (company: ICompany) => {
    setCurrentCompany(company);
    history.replace({
      pathname: replaceStringVariables(urls.jobOffers, {
        companySlug: company.company_slug,
      }),
    });
  };

  const setCurrentCompany = (company: ICompany) => {
    setSelectedCompanyId(company.uuid);
    setCompanyHeaderValues(company);
    setCompanyFooterValues(company);
  };

  const currentCompany = companiesList?.companies.find(
    (company) => company.company_slug === companySlug,
  );

  const selectedCompany = companiesList?.companies.find(
    (company) => company.uuid === selectedCompanyId,
  );

  if (currentCompany && !selectedCompany) {
    setCurrentCompany(currentCompany);
  }

  const onResetHeaderChanges = () => {
    setCompanyHeaderValues(selectedCompany as ICompany);
  };

  const onCustomizeWebsiteSubmit = (data: CustomizeCompanyMutationData) => {
    customizeCompanyMutation.mutate(data, {
      onSuccess: () => {
        dispatch(
          showMessage({
            message: dictionary.customizeWebsiteDrawer.changesSaved,
          }),
        );
        customizeCompanyFormMethods.setValue('thumbnail_logo', []);
      },
    });
  };

  const onSaveHeaderChanges = (data: ICustomizeCompanyFormData) => {
    const headerData = {
      header_background_color: JSON.stringify(data.header_background_color),
      button_color: JSON.stringify(data.button_color),
      text_color: JSON.stringify(data.text_color),
      header_text: data.header_text || '',
      thumbnail_logo: data.thumbnail_logo?.[0] || '',
    };
    onCustomizeWebsiteSubmit(headerData);
  };

  const onSaveFooterChanges = (data: ICustomizeCompanyFormData) => {
    const footerData = {
      footer_text: data.footer_text,
    };
    onCustomizeWebsiteSubmit(footerData);
  };

  if (!isLoadingCompanies && !isLoadingPublications && !currentCompany) {
    return (
      <Template404
        header={dictionary.jobOffers.noJobOffersAvailableHeader}
        firstSentence={dictionary.jobOffers.noJobOffersAvailableParagraph}
        secondSentence=""
      />
    );
  }

  return (
    <JobOffersTemplate
      isLoadingJobOffers={isLoadingPublications}
      isLoadingCompanies={isLoadingCompanies}
      isCustomizeWebsiteDrawerOpen={isCustomizeWebsiteDrawerOpen}
      isSavingCustomizeWebsiteChanges={customizeCompanyMutation.isLoading}
      isAdmin={companiesList?.is_admin}
      hasNextPage={hasNextPage}
      company={selectedCompany}
      companies={companiesList?.companies}
      departmentsOptions={departments}
      contractTypesOptions={contractTypes}
      jobOffers={jobOffers || []}
      filtersFormMethods={filtersFormMethods}
      customizeCompanyFormMethods={customizeCompanyFormMethods}
      fetchNextPageRef={fetchNextPageRef}
      onChangeSelectedCompany={onChangeSelectedCompany}
      onSaveHeaderChanges={customizeCompanyFormMethods.handleSubmit(
        onSaveHeaderChanges,
      )}
      onSaveFooterChanges={customizeCompanyFormMethods.handleSubmit(
        onSaveFooterChanges,
      )}
      onResetHeaderChanges={onResetHeaderChanges}
      setIsCustomizeWebsiteDrawerOpen={setIsCustomizeWebsiteDrawerOpen}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default JobOffers;
