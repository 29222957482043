import { AxiosError } from 'axios';
import apiRoutes from 'conf/api-routes';
import { COMPANIES } from 'conf/query-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { ICompanies } from 'types/ICompany';
import { apiFetcher } from 'utils/fetch';

export const useCompanies = (
  queryOptions = {},
): UseQueryResult<ICompanies, AxiosError> => {
  return useQuery(
    [COMPANIES],
    () =>
      apiFetcher({
        apiPath: apiRoutes.companies,
        withSubdomain: true,
      }).then((response) => response.data),
    {
      ...queryOptions,
    },
  );
};

export default useCompanies;
