import moment, { todayStartDate } from '@rexmas/rexmas-shared/lib/lib/moment';
import { HexAlphaColor } from 'types/ICompany';
import IConstant from 'types/IConstant';
import { IDictionary } from 'types/misc';

import dictionaryCL from '../conf/dictionaries/es_CL';

export const getErrorMessage = (error: any): string => {
  if (error.response?.data) {
    return (
      error.response.data.errors?.[0]?.message || error.response.data.detail
    );
  }
  return dictionaryCL.general.internalServerError;
};

export default getErrorMessage;

export const getElapsedDays = (date: string): number => {
  const dateFromStart = moment(date).clone().startOf('day');
  return todayStartDate.diff(dateFromStart, 'days');
};

export const normalizeString = (text: string): string =>
  text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export const getObjectsFromTextSearch = <T>(
  items: T[],
  attributes: string[],
  text = '',
): T[] => {
  if (text === '') return items;
  return items.filter(
    (item) =>
      attributes.filter((field) => {
        const value = item[field as keyof typeof item] as any;
        if (!value) return false;
        return normalizeString(value.toString()).includes(
          normalizeString(text),
        );
      }).length > 0,
  );
};

export const getFormattedCurrency = (
  value: number | undefined,
  dictionary: IDictionary,
): string => {
  return new Intl.NumberFormat(`es-${dictionary.general.countryCode}`, {
    style: 'currency',
    currency: dictionary.general.currency,
  }).format(value || 0);
};

export const to8DigitsHexColor = (color: HexAlphaColor): string => {
  let hexAlpha = Math.floor(255 * color.alpha).toString(16);
  hexAlpha = hexAlpha.length === 1 ? `0${hexAlpha}` : hexAlpha;
  return `${color.hex}${hexAlpha}`;
};

export const getFilterValueAsArray = <T>(value?: T): T[] => {
  return value ? [value] : [];
};

export const getObjectsFromArrayOfStrings = (array: string[]): IConstant[] => {
  return array.map((value) => ({ value, name: value }));
};

export const getIdFromPublicationSlug = (publicationSlug: string): string => {
  return publicationSlug.slice(-36);
};

export const formatBytesSize = (bytes: number | undefined | null): string => {
  if (bytes === undefined || bytes === null) {
    return '—';
  }
  if (bytes < 1024) {
    return `${bytes} B`;
  }
  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(0)} KB`;
  }
  return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
};
