import { ApplicantDataTemplate } from 'components/templates';
import { cities } from 'conf/constants';
import { UseFormReturn } from 'react-hook-form';

import { useNationalities } from '../../../queries/nationalities';

interface Props {
  publicationSlug: string | undefined;
  positionName: string;
  formMethods: UseFormReturn;
  onContinue: () => void;
  applicationFields: string[];
}

const ApplicantData: React.FunctionComponent<Props> = ({
  publicationSlug,
  positionName,
  formMethods,
  onContinue,
  applicationFields,
}) => {
  const { data: nationalities, isLoading: isLoadingNationalities } =
    useNationalities();
  const residence = formMethods.watch('residence');
  const city = formMethods.getValues('city');
  const currentCities = cities[residence?.value as keyof typeof cities];

  if (currentCities?.every(({ value }) => value !== city?.value)) {
    formMethods.setValue('city', null);
  }

  const onContinueToQuestions = formMethods.handleSubmit(onContinue);

  return (
    <ApplicantDataTemplate
      publicationSlug={publicationSlug}
      positionName={positionName}
      formMethods={formMethods}
      cities={currentCities || []}
      countries={nationalities || []}
      isLoadingNationalities={isLoadingNationalities}
      onContinue={onContinueToQuestions}
      applicationFields={applicationFields}
    />
  );
};

export default ApplicantData;
