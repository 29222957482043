import { QuestionsTemplate } from 'components/templates';
import { UseFormReturn } from 'react-hook-form';
import IFileRequest from 'types/IFileRequest';
import { IProcessQuestion } from 'types/IQuestion';

interface Props {
  isSubmittingApplication: boolean;
  positionName: string;
  questions: IProcessQuestion[] | undefined;
  fileRequests: IFileRequest[] | undefined;
  formMethods: UseFormReturn;
  onGoBack: () => void;
  onSubmitApplication: () => void;
}

const Questions: React.FunctionComponent<Props> = ({
  isSubmittingApplication,
  positionName,
  questions = [],
  fileRequests = [],
  formMethods,
  onGoBack,
  onSubmitApplication,
}) => {
  return (
    <QuestionsTemplate
      isSubmittingApplication={isSubmittingApplication}
      positionName={positionName}
      questions={questions}
      fileRequests={fileRequests}
      formMethods={formMethods}
      onGoBack={onGoBack}
      onSubmitApplication={onSubmitApplication}
    />
  );
};

export default Questions;
