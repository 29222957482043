const dictionary = {
  general: {
    countryCode: 'CO',
    currency: 'COP',
    currencyPrefix: '$ ',
    currencyThousandSeparator: ',',
    fieldIsRequired: 'Este campo es requerido',
    fileIsRequired: 'Este archivo es requerido',
    internalServerError: 'Error inesperado del servidor',
  },
  publication: {
    documentTitle: 'Rex+ Publicaciones - :publicationTitle',
    companyDescription: 'Descripción de la empresa',
    jobDescription: 'Descripción del empleo',
    jobSummary: 'Resumen del empleo',
    requirements: 'Requerimientos',
    applyingYouAgreeWith: 'Al postular aceptas nuestros',
    termsAndConditions: 'Términos y Condiciones',
    publishedOn: 'Publicado el',
    apply: 'Postularme',
    benefits: 'Beneficios',
    goBackToOffers: 'Volver a ofertas',
  },
  applicationStepsBase: {
    documentTitle: 'Rex+ Publicaciones - Postulación',
    dataPrivacyStatement:
      'Respetar tus datos es de suma importancia para nosotros. La información solicitada será utilizada para evaluar tu solicitud de postulación a la oferta.',
    continue: 'Continuar',
    goBack: 'Volver',
    apply: 'Postular',
  },
  applicantData: {
    personalData: 'Datos personales',
    subTitle:
      'Completa los siguientes datos para postular. Recuerda adjuntar tu CV en el tipo de formato solicitado. Los campos marcados con (*) son obligatorios.',
    subTitleNoCv:
      'Completa los siguientes datos para postular. Los campos marcados con (*) son obligatorios.',
    youAreApplyingTo: 'Estás postulando a la oferta de:',
    inputs: {
      firstName: 'Nombre*',
      lastName: 'Apellido*',
      nationalId: 'Cédula*',
      email: 'E-Mail*',
      phone: 'Teléfono*',
      linkedIn: 'LinkedIn',
      gender: 'Género*',
      genderMale: 'Masculino',
      genderFemale: 'Femenino',
      genderPreferNotToSay: 'Prefiero no decir',
      avatar: 'Foto',
      country: 'País*',
      residence: 'País de residencia*',
      address: 'Dirección*',
      loadingCountries: 'Cargando países ...',
      city: 'Ciudad*',
      salaryExpectation: 'Aspiración salarial*',
      fieldIsRequired: 'Este campo es requerido',
      invalidEmail: 'E-Mail inválido',
      invalidNationalId: 'Cédula inválida',
      invalidPhoneLength: 'Teléfono debe contener al menos 9 dígitos',
      cv: 'Adjunto CV*',
      cvHelperText: 'Tipo de formato: Word, pdf, txt.',
      invalidFileExtensionError: 'El archivo debe estar formato: :extensions',
      invalidFileSizeError: 'El archivo no debe superar :size MB',
      birthDate: 'Fecha de nacimiento*',
      dateFormatError: 'Debes indicar una fecha en formato DD/MM/AAAA',
      introductionVideo: 'Video de presentación',
      introductionVideoHelperText: 'Tipo de formato: mp4, mpg, webm, avi',
    },
  },
  questions: {
    applicationForm: 'Formulario de postulación',
    subTitle:
      '¡Vas bien! Ahora completa el siguiente formulario para finalizar la postulación.',
    freeTextPlaceholder: 'Ingresa tu respuesta',
    characterCount: ':count / :max caracteres.',
    attachHere: 'Adjuntar aquí',
  },
  completed: {
    applicationCompleted: 'Solicitud completada',
    applicationSuccess: 'Postulación realizada con éxito',
    subTitlePartOne: 'Agradecemos tu interés en ser el próximo',
    subTitlePartTwo:
      'de :company. Pronto nos contactaremos para informarte sobre tu postulación!',
    ourCompany: 'nuestra empresa',
    backToHome: 'Regresar al portal',
  },
  404: {
    header: 'Esta página no está disponible',
    firstSentence: 'No pudimos encontrar la página que estabas buscando',
    secondSentence:
      'Es posible que el enlace no sea correcto, inténtalo nuevamente',
    goBackButton: 'Volver a inicio',
  },
  notAvailable: {
    secondSentence:
      'La publicación se encuentra cerrada o ya no está disponible en este momento',
  },
  jobOffers: {
    workWithUsAt: 'Trabaja con nosotros en :companyName',
    searchPlaceholder: 'Cargo, palabra clave',
    departmentsPlaceholder: 'Áreas',
    contractTypesPlaceholder: 'Tipo de jornada',
    footer: 'Rex+ Selección 2022',
    new: 'Nuevo',
    noJobOffersAvailableHeader: 'No se encontraron ofertas laborales',
    noJobOffersAvailableParagraph:
      'Esta empresa no cuenta con ofertas laborales en este momento',
    noResultsFoundHeader: 'No se encontraron resultados',
    noResultsFoundHeaderParagraph:
      'No hay resultados asociados a esta búsqueda',
  },
  customizeWebsiteDrawer: {
    customizeWebsite: 'Personalizar portal',
    customizeWebsiteDescription: 'Personaliza tu portal de empresa.',
    backgroundColor: 'Color de fondo',
    buttonColor: 'Color de botón',
    headerTextColor: 'Color de texto de cabecera',
    header: 'Cabecera',
    headerText: 'Texto de cabecera',
    headerTextPlaceholder: 'Ingresa texto de cabecera',
    companyLogo: 'Logo de empresa',
    dragOrAddFile: 'Arrasta o añade archivo',
    companyLogoFileRestrictionsText:
      'Asegúrate de utilizar archivos que no superen los 3MB. Recomendaciones de imagen 100x100 px.',
    footer: 'Pie de página',
    footerText: 'Texto de pie de página',
    footerTextPlaceholder: 'Ingresa pie de página',
    helpCenter: 'Centro de ayuda',
    resetChanges: 'Restablecer cambios',
    saveChanges: 'Guardar cambios',
    changesSaved: 'Cambios guardados',
  },
  offerLetter: {
    documentTitle: 'Rex+ Carta oferta - :processName',
    title: 'Hola, :name',
    firstParagraph:
      'Estamos felices de informarte que has sido seleccionado en el proceso de búsqueda para ocupar nuestra vacante de:',
    secondParagraph:
      'Una vez leída la carta oferta y para finalizar el proceso, es necesario que aceptes o rechaces la propuesta.',
    acceptOrRejectOfferLetter: 'Aceptar o rechazar carta oferta',
    acceptOrRejectOfferLetterDescription:
      'Para poder aceptar o rechazar la carta oferta, por favor selecciona una opción.',
    chooseOption: 'Seleccionar opción',
    giveAReason: 'Por favor, indica un motivo',
    writeReason: 'Escribir motivo...',
    acceptOfferLetter: 'Acepto la carta oferta recibida',
    rejectOfferLetter: 'Rechazo la carta oferta recibida',
    sendResponse: 'Ok, enviar respuesta',
    downloadOfferLetter: 'Descargar carta oferta',
    thankYouForResponding: 'Muchas gracias por responder a la carta oferta.',
    weHaveReceivedYourResponse:
      'Hemos recibido tu respuesta y nos contactaremos contigo',
    sendSuccess: '¡Envío realizado correctamente!',
    ifYouHaveQuestionsYouCanWriteTo:
      'Si tienes dudas o consultas puedes escribir a:',
    offerLetterNotAvailable: 'Carta oferta no disponible',
    linkHasExpired: 'El enlace que has seguido ha caducado.',
  },
  postulantProfile: {
    header: '¡Bienvenido/a :name!',
    headerTitle:
      'Formulario de solicitud de información - Perfil del postulante',
    beforeBeginning: 'Antes de comenzar',
    considerTheFollowing: 'Por favor, ten en cuenta lo siguiente:',
    rememberToFillInAllFields:
      'Recuerda completar todos los puntos solicitados en el formulario.',
    filOutTheForm: '¡Ok, completar el formulario!',
    step1Message:
      'Para continuar con el proceso de contratación, es necesario que completes el formulario con la información solicitada.',
    step2Message: 'Revisa y confirma la información ingresada antes de enviar',
    fillInInformation: 'Completar información',
    confirmAndSubmit: 'Confirmar y enviar',
    requiredFields: '(*) Campos obligatorios',
    confirmAndSubmitInformation:
      'Ir a confirmar y enviar la información ingresada',
    submitInformation: 'Ok, enviar información',
    goBack: 'Volver',
    dateFormatError: 'Debes indicar una fecha en formato DD/MM/AAAA',
    minLengthMessage: 'Debe tener al menos :minLength caracteres',
    sendSuccess: '¡Envío realizado correctamente!',
    sendSuccessDescription:
      'Muchas gracias por completar la información solicitada. Con esta información podemos continuar con el proceso de contratación.',
    fieldsValidationErrors: 'Se encontraron errores en los campos',
  },
  chat: {
    header: '¡Bienvenido al canal de mensajería!',
    headerTitle:
      'Aquí podrás revisar, enviar y responder los mensajes provenientes del proceso de selección:',
    selectionProcessFinalized: '¡El proceso de selección ha finalizado!',
    selectionProcessFinalizedParagraph1:
      'Estimado postulante, el proceso de selección ha finalizado.',
    selectionProcessFinalizedParagraph2:
      'Gracias por haber participado del proceso.',
    selectionProcess: 'Proceso de selección: :selectionProcess',
    selectionProcessManager: 'Encargado de proceso de selección',
    sendMessagePlaceholder: 'Escribe un mensaje aquí',
    addFile: 'Añadir archivo',
    sendMessage: 'Enviar mensaje',
    add: 'Añadir',
    cancel: 'Cancelar',
    addFileHelperText: 'Arrastrar y soltar el archivo o haz click aquí',
    addFileRestrictionsText:
      'Asegúrate de utilizar archivos que no superen los 3 MB.',
  },
};

export default dictionary;
