import { showErrorAction } from '@rexmas/rexmas-shared/lib/redux/actionCreators/errors';
import { AxiosError, AxiosResponse } from 'axios';
import apiRoutes from 'conf/api-routes';
import { COMPANIES } from 'conf/query-keys';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { CustomizeCompanyMutationData, ICompany } from 'types/ICompany';
import { apiFetcher } from 'utils/fetch';
import { getErrorMessage } from 'utils/utils';

export const useCustomizeCompany = (
  companyUuid = '',
): UseMutationResult<
  AxiosResponse<ICompany>,
  AxiosError,
  CustomizeCompanyMutationData
> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const formData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });

      return apiFetcher({
        method: 'patch',
        apiPath: apiRoutes.company,
        urlParams: { companyUuid },
        data: formData,
        withSubdomain: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COMPANIES]);
      },
      onError: (error) => {
        dispatch(showErrorAction({ message: getErrorMessage(error) }));
      },
    },
  );
};

export default useCustomizeCompany;
