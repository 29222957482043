import { AxiosRequestConfig } from 'axios';

export enum CountryCode {
  CL = 'CL',
  PE = 'PE',
  CO = 'CO',
}

export type IDictionary = Record<string, any>;

export interface IApiFetcherConfig extends AxiosRequestConfig {
  apiPath: string;
  urlParams?: Record<string, string | undefined>;
  withSubdomain?: boolean;
}

export interface IDepartment {
  id: number;
  name: string;
}

export enum StepNumberTheme {
  PENDING = 'PENDING',
  DOING = 'DOING',
  DONE = 'DONE',
  REJECTED = 'REJECTED',
  WARNING = 'WARNING',
}

export interface IFieldError {
  field: string;
  message: string;
}

export interface Moment {
  format(string: string): string;
  toDate(): Date;
  isValid(): boolean;
}
