import editIcon from '@rexmas/rexmas-shared/images/edit-2.svg';
import { ColorableIcon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import { Line } from '@rexmas/rexmas-shared/lib/components/atoms/misc';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { elevations } from '@rexmas/rexmas-shared/lib/conf/constants/elevations';
import ColorCircle from 'components/atoms/ColorCircle';
import React from 'react';
import {
  ColorState,
  CustomPicker,
  HSLColor,
  HSVColor,
  RGBColor,
} from 'react-color';
import {
  Alpha,
  EditableInput,
  Hue,
  Saturation,
} from 'react-color/lib/components/common';
import styled from 'styled-components';

const Container = styled.div`
  width: 304px;
  background-color: ${colors.white};
`;

const ColorCirclesContainer = styled.div`
  display: flex;
  gap: 5px;
  padding: 12px 16px 8px;
`;

const Circle = styled.div<{ selected: boolean }>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1.5px solid ${colors.white};
  background-color: ${(props) => props.color};
  box-shadow: ${(props) => (props.selected ? elevations.elevation11 : 'none')};
  cursor: pointer;
`;

const ElementsContainer = styled.div`
  padding: 8px 16px 16px;
`;

const SaturationHueContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const SaturationContainer = styled.div`
  width: 236px;
  height: 180px;
  position: relative;
  overflow: hidden;
`;

const SaturationPointer = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1.5px solid ${colors.white};
  box-shadow: ${elevations.elevation12};
  transform: translate(-50%, -50%);
`;

const SliderPointer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: ${elevations.elevation1};
  transform: translate(-4px, -50%);
  background-color: ${colors.white};
`;

const HueContainer = styled.div`
  position: relative;
  width: 16px;
  margin-right: 4px;
`;

const AlphaContainer = styled.div`
  margin: 16px 0;
  position: relative;
  height: 12px;
`;

const AlphaPointer = styled(SliderPointer)`
  transform: translate(-50%, -6px);
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid ${colors.veryLightPinkGrey};
  border-radius: 5px;
  padding: 4px 8px 4px 4px;
  input {
    width: 100px;
    padding: 4px;
    border: none;
    color: ${colors.textDarkGrey};
    :focus {
      outline-color: ${colors.highlighted};
    }
  }
`;

const StyledColorCircle = styled(ColorCircle)`
  margin-left: auto;
`;

interface Props {
  hex?: string;
  hsl?: HSLColor;
  hsv?: HSVColor;
  rgb?: RGBColor;
  onChange: (color: Partial<ColorState> | string) => void;
}

const ColorPicker: React.FunctionComponent<Props> = ({
  hex,
  hsv,
  hsl,
  rgb,
  onChange,
}) => {
  const defaultColors = [
    colors.cobalt,
    colors.darkPrimary,
    colors.darkGradientBlue,
    colors.powderBlue,
    colors.highlighted,
    colors.mustardAlert,
    colors.highlightedOpaque,
    colors.dash,
  ];

  return (
    <Container>
      <ColorCirclesContainer>
        {defaultColors.map((defaultColor) => {
          return (
            <Circle
              key={defaultColor}
              color={defaultColor}
              selected={hex === defaultColor}
              onClick={() => onChange(defaultColor)}
            />
          );
        })}
      </ColorCirclesContainer>
      <Line color={colors.veryLightPinkGrey} />
      <ElementsContainer>
        <SaturationHueContainer>
          <SaturationContainer>
            <Saturation
              hsv={hsv}
              hsl={hsl}
              onChange={onChange}
              pointer={SaturationPointer}
              data-testid="color-picker-saturation"
            />
          </SaturationContainer>
          <HueContainer>
            <Hue
              hsl={hsl}
              pointer={SliderPointer}
              onChange={onChange}
              direction="vertical"
              data-testid="color-picker-hue"
            />
          </HueContainer>
        </SaturationHueContainer>
        <AlphaContainer>
          <Alpha
            rgb={rgb}
            hsl={hsl}
            onChange={onChange}
            pointer={AlphaPointer}
            radius="5px"
            data-testid="color-picker-alpha"
          />
        </AlphaContainer>
        <InputContainer>
          <ColorableIcon src={editIcon} color={colors.textLightGrey} />
          <EditableInput value={hex} onChange={onChange} />
          <StyledColorCircle color={hex} alpha={rgb?.a} />
        </InputContainer>
      </ElementsContainer>
    </Container>
  );
};

export default CustomPicker(ColorPicker);
