import ChatTemplate from 'components/templates/Chat';
import { CHAT } from 'conf/query-keys';
import { useSendChatMessage } from 'mutations/chat';
import { useChat } from 'queries/chat';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { IChat, ISendMessageFormValues, MessageSenderType } from 'types/IChat';

import Page404 from './404';

interface Params {
  id: string;
}

const Chat: React.FunctionComponent = () => {
  const { id } = useParams<Params>();
  const queryClient = useQueryClient();
  const { data: chat, isLoading, error } = useChat(id);
  const formMethods = useForm<ISendMessageFormValues>();
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const sendChatMessageMutation = useSendChatMessage(id);

  if (error?.response?.status === 404) {
    return <Page404 />;
  }

  const onSendMessage = (data: ISendMessageFormValues) => {
    sendChatMessageMutation.mutate(data, {
      onSuccess: ({ data: response }) => {
        formMethods.reset();
        const message = {
          id: response.message_id,
          body: data.body,
          sender_type: MessageSenderType.PROCESS_POSTULANT,
          sender_name: chat?.postulant_full_name || '',
          created_date: new Date().toISOString(),
          attachment: undefined,
          attachment_size: String(data.file?.size || '0'),
          filename: data.file?.name,
        };
        queryClient.setQueryData<IChat | undefined>(
          [CHAT, id],
          (chatData) =>
            chatData && {
              ...chatData,
              messages: [...chatData.messages, message],
            },
        );
        queryClient.invalidateQueries([CHAT, id]);
      },
    });
  };

  return (
    <ChatTemplate
      isLoading={isLoading}
      isSubmittingMessage={sendChatMessageMutation.isLoading}
      isAddFileModalOpen={isAddFileModalOpen}
      isSelectionProcessFinalized={error?.response?.status === 400}
      chat={chat}
      formMethods={formMethods}
      setIsAddFileModalOpen={setIsAddFileModalOpen}
      onSendMessage={formMethods.handleSubmit(onSendMessage)}
    />
  );
};

export default Chat;
