import { AxiosError } from 'axios';
import apiRoutes from 'conf/api-routes';
import { NATIONALITIES } from 'conf/query-keys';
import { useQuery, UseQueryResult } from 'react-query';
import IConstant from 'types/IConstant';
import { apiFetcher } from 'utils/fetch';

export const useNationalities = (
  queryOptions = {},
): UseQueryResult<IConstant[], AxiosError> => {
  return useQuery(
    [NATIONALITIES],
    () =>
      apiFetcher({
        apiPath: apiRoutes.nationalities,
        withSubdomain: false,
      }).then((response) => response.data),
    {
      ...queryOptions,
    },
  );
};

export default useNationalities;
