import { Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import Skeleton from '@rexmas/rexmas-shared/lib/components/atoms/misc/Skeleton';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import useDictionary from 'hooks/useDictionary';
import logoSeleccion from 'images/logo-seleccion-circle.svg';
import styled from 'styled-components';
import {
  IPostulantProfile,
  PostulantProfileState,
} from 'types/IPostulantProfile';

const ColoredBackground = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.darkDash};
  padding: 24px 24px 272px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 48px 60px 272px;
  }
`;

const Logo = styled(Icon)`
  height: 56px;
  width: 133px;
  margin: 0 auto 24px 0;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled(Text)`
  color: ${colors.whiteTwo};
`;

interface Props {
  isLoading: boolean;
  postulantProfile: IPostulantProfile | undefined;
}

const PostulantProfileHeader: React.FunctionComponent<Props> = ({
  isLoading,
  postulantProfile,
}) => {
  const dictionary = useDictionary();

  return (
    <ColoredBackground>
      <Logo src={logoSeleccion} />
      <HeaderTextContainer>
        {postulantProfile?.state !== PostulantProfileState.COMPLETED && (
          <StyledText variant="style31">
            {isLoading ? (
              <Skeleton width={280} />
            ) : (
              replaceStringVariables(dictionary.postulantProfile.header, {
                name: `${postulantProfile?.postulant_metadata.first_name} ${postulantProfile?.postulant_metadata.last_name}`,
              })
            )}
          </StyledText>
        )}
        <StyledText variant="heading4">
          {dictionary.postulantProfile.headerTitle}
        </StyledText>
      </HeaderTextContainer>
    </ColoredBackground>
  );
};

export default PostulantProfileHeader;
