import Popover from '@mui/material/Popover';
import arrowIcon from '@rexmas/rexmas-shared/images/chevron-down-highlight.svg';
import { Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import { OutlinedInput } from '@rexmas/rexmas-shared/lib/components/atoms/inputs';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { elevations } from '@rexmas/rexmas-shared/lib/conf/constants/elevations';
import ColorCircle from 'components/atoms/ColorCircle';
import ColorPicker from 'components/organisms/ColorPicker';
import React, { MouseEvent, useState } from 'react';
import { ColorState } from 'react-color';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { HexAlphaColor } from 'types/ICompany';
import { to8DigitsHexColor } from 'utils/utils';

const StyledOutlinedInput = styled(OutlinedInput)`
  &,
  input,
  img {
    cursor: pointer;
  }
  input {
    text-transform: uppercase;
    margin-left: 4px;
  }
  .MuiInputLabel-root {
    color: ${colors.textLightGrey};
  }
  .Mui-focused:not(.Mui-error) {
    &:after {
      border-color: ${colors.highlighted};
    }
  }
  .MuiInput-underline {
    &:hover:before {
      border-color: ${colors.dash};
    }
    &:before {
      border-color: ${colors.grey};
    }
  }
`;

const StyledColorCircle = styled(ColorCircle)`
  cursor: pointer;
`;

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    box-sizing: border-box;
    border: 0.5px solid ${colors.grey};
    border-radius: 16px;
    box-shadow: ${elevations.elevation10};
  }
`;

interface Props {
  name: string;
  defaultValue: HexAlphaColor;
  label?: string;
  helperText?: string;
  rules?: RegisterOptions;
  formMethods: UseFormReturn<any>;
  className?: string;
}

const ColorPickerInput: React.FunctionComponent<Props> = ({
  name,
  defaultValue,
  rules,
  label = '',
  helperText = '',
  formMethods,
  className = '',
}) => {
  const [color, setColor] = useState<ColorState | string>();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  return (
    <Controller
      name={`${name}` as const}
      rules={rules}
      control={formMethods.control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <StyledOutlinedInput
            onClick={(event: MouseEvent) => {
              setColor(to8DigitsHexColor(value));
              setAnchorEl(anchorEl ? null : event.currentTarget);
            }}
            value={value?.hex}
            onChange={() => null}
            label={label}
            variant="standard"
            helperText={error?.message || helperText}
            className={className}
            error={!!error}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <StyledColorCircle color={value?.hex} alpha={value?.alpha} />
              ),
              endAdornment: <Icon src={arrowIcon} />,
            }}
          />
          <StyledPopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 8, horizontal: 4 }}
          >
            <ColorPicker
              color={typeof color === 'string' ? color : color?.hsl}
              onChange={setColor}
              onChangeComplete={(newColor) =>
                onChange({ hex: newColor.hex, alpha: newColor.rgb.a })
              }
            />
          </StyledPopover>
        </>
      )}
    />
  );
};

export default ColorPickerInput;
