import Tooltip from '@mui/material/Tooltip';
import clockIcon from '@rexmas/rexmas-shared/images/clock.svg';
import InfoIcon from '@rexmas/rexmas-shared/images/info-light-blue.svg';
import linkIcon from '@rexmas/rexmas-shared/images/link.svg';
import shareIcon from '@rexmas/rexmas-shared/images/share-cobalt.svg';
import { Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import {
  Chip,
  RippleContainer,
} from '@rexmas/rexmas-shared/lib/components/atoms/misc';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { DropdownMenu } from '@rexmas/rexmas-shared/lib/components/molecules/navigation';
import { IconText } from '@rexmas/rexmas-shared/lib/components/molecules/text';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { elevations } from '@rexmas/rexmas-shared/lib/conf/constants/elevations';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import { showMessage } from '@rexmas/rexmas-shared/lib/redux/actions/messages';
import { useMediaQuery } from '@rexmas/rexmas-shared/lib/utils/hooks';
import { convertDOMBool } from '@rexmas/rexmas-shared/lib/utils/misc';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import styled from 'styled-components';
import { IBenefit, PortalCode } from 'types/IPublication';

import dictionary from '../../../conf/dictionaries/es_CL';

const StyledA = styled.a`
  text-decoration: none;
  color: ${colors.textLightGrey};
`;

const StyledCard = styled(RippleContainer)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  border-radius: 15px;
  box-shadow: ${elevations.default};
  background-color: ${colors.white};
  &:hover {
    background-color: ${colors.detailGrey};
  }
  @media screen and (min-width: ${mediaQueries.lg}px) {
    padding: 32px;
    align-items: center;
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 16px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media screen and (min-width: ${mediaQueries.lg}px) {
    flex-direction: row;
  }
`;

const JobName = styled(Text)`
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  gap: 16px;
`;

const PortalLogoContainer = styled.div`
  display: flex;
`;

const PortalLogo = styled(Icon)`
  object-fit: contain;
`;

const ContractType = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  @media screen and (min-width: ${mediaQueries.lg}px) {
    font-size: 21px;
  }
`;

const OtherInfoContainer = styled.div`
  display: flex;
  gap: 12px 24px;
  flex-wrap: wrap;
  align-items: center;
`;

const JobLocation = styled(Text)`
  display: flex;
  gap: 8px;
`;

const FlagIcon = styled(Icon)`
  width: 19px;
  height: 12px;
`;

const StyledIconText = styled(IconText)`
  .colorable-icon {
    padding: 0 12px;
  }
`;

const BenefitsContainer = styled.div`
  display: grid;
  grid-auto-rows: 24px;
  grid-template-columns: repeat(auto-fit, 24px);
  gap: 16px;
  margin: 0 68px 0 auto;
  width: 100%;
  @media screen and (min-width: ${mediaQueries.lg}px) {
    grid-template-columns: repeat(4, 24px);
    gap: 16px 24px;
    width: auto;
  }
`;

const StyledChip = styled(Chip)`
  && {
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    min-width: initial;
  }
`;

const ShareButton = styled(DropdownMenu)`
  border: 2px solid ${colors.cobalt};
  border-radius: 25px;
  color: ${colors.cobalt};
  padding-right: 14px;
  &:hover {
    background-color: ${colors.fadedBlue};
    .IconDropdown-Label {
      color: ${colors.white};
    }
  }
  .IconDropdown-Label {
    color: ${colors.cobalt};
    padding: 0 12px 0 12px;
    line-height: 15px;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
    [class*='Icon'] {
      background-color: ${colors.cobalt};
    }
  }
`;

const ShareButtonStyles = `
  display: block;
  width: 170px;
  text-align: left;
`;

const StyledWhatsappShareButton = styled(WhatsappShareButton)`
  ${ShareButtonStyles}
`;

const StyledLinkedinShareButton = styled(LinkedinShareButton)`
  ${ShareButtonStyles}
`;

const StyledFacebookShareButton = styled(FacebookShareButton)`
  ${ShareButtonStyles}
`;

const StyledTwitterShareButton = styled(TwitterShareButton)`
  ${ShareButtonStyles}
`;

const SocialNetworkTitleContainer = styled.div`
  display: flex;
`;

const SocialNetworkTitle = styled(Text)`
  margin-left: 8px;
  margin-top: 4px;
  font-size: 14px;
  color: ${colors.backgroundDarkGrey};
  line-height: 1.29;
`;

const ButtonWrapper = styled.div``;

interface Props {
  isNew: boolean;
  name: string;
  contractType: string;
  department: string;
  publishedDate: string;
  countryName: string;
  countryFlag: string;
  city: string;
  portalLogo: string;
  portalName: string;
  portalCode: PortalCode;
  publicationUrl: string;
  benefits: IBenefit[];
}

const JobOffer: React.FunctionComponent<Props> = ({
  isNew,
  name,
  contractType,
  department,
  publishedDate,
  countryName,
  countryFlag,
  city,
  benefits,
  portalLogo,
  portalName,
  portalCode,
  publicationUrl,
}) => {
  const isMobile = useMediaQuery('down', 'lg');
  const isNewChipVisible = convertDOMBool(isNew);
  const isPrivate = portalCode === PortalCode.PRIVATE;
  const dispatch = useDispatch();

  const getSharedUrl = (source: string, medium: string): string => {
    if (isPrivate) {
      const urlObj = new URL(publicationUrl);
      urlObj.searchParams.append('utm_source', source);
      urlObj.searchParams.append('utm_medium', medium);
      return urlObj.toString();
    }
    return publicationUrl;
  };

  const newOfferChip = (
    <StyledChip
      label={dictionary.jobOffers.new}
      theme="quaternary"
      visible={isNewChipVisible}
    />
  );

  const onLinkIconClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(getSharedUrl('Url', 'shared_url'));
    dispatch(showMessage({ message: 'Url copiada' }));
  };

  const menuItems = [
    {
      icon: linkIcon,
      color: colors.highlighted,
      label: 'Copiar url',
      onClick: () => onLinkIconClick(),
    },
    {
      Component: () => (
        <StyledLinkedinShareButton url={getSharedUrl('Linkedin', 'social')}>
          <SocialNetworkTitleContainer>
            <LinkedinIcon round size={24} />
            <SocialNetworkTitle>LinkedIn</SocialNetworkTitle>
          </SocialNetworkTitleContainer>
        </StyledLinkedinShareButton>
      ),
    },
    {
      Component: () => (
        <StyledFacebookShareButton url={getSharedUrl('Facebook', 'social')}>
          <SocialNetworkTitleContainer>
            <FacebookIcon round size={24} />
            <SocialNetworkTitle>Facebook</SocialNetworkTitle>
          </SocialNetworkTitleContainer>
        </StyledFacebookShareButton>
      ),
    },
    {
      Component: () => (
        <StyledTwitterShareButton url={getSharedUrl('Twitter', 'social')}>
          <SocialNetworkTitleContainer>
            <TwitterIcon round size={24} />
            <SocialNetworkTitle>Twitter</SocialNetworkTitle>
          </SocialNetworkTitleContainer>
        </StyledTwitterShareButton>
      ),
    },
    {
      Component: () => (
        <StyledWhatsappShareButton
          url={getSharedUrl('Whatsapp', 'social')}
          windowWidth={800}
          windowHeight={800}
        >
          <SocialNetworkTitleContainer>
            <WhatsappIcon round size={24} />
            <SocialNetworkTitle>Whatsapp</SocialNetworkTitle>
          </SocialNetworkTitleContainer>
        </StyledWhatsappShareButton>
      ),
    },
  ];

  return (
    <StyledA
      href={publicationUrl}
      target={isPrivate ? '_self' : '_blank'}
      rel="noreferrer"
    >
      <StyledCard>
        <div>
          <TitleContainer>
            <JobName variant="style23">
              {name}
              {isMobile && newOfferChip}
            </JobName>
            <ContractType variant="style32">{contractType}</ContractType>
          </TitleContainer>
          <OtherInfoContainer>
            <JobLocation variant="style19">
              <FlagIcon src={countryFlag} alt={countryName} />
              {`${city}, ${countryName}`}
            </JobLocation>
            <StyledIconText
              text={department}
              iconColor={colors.veryLightBlueGrey}
              iconSrc={InfoIcon}
              textClassName="text"
              textVariant="style19"
            />
            <StyledIconText
              text={publishedDate}
              iconColor={colors.veryLightBlueGrey}
              iconSrc={clockIcon}
              textClassName="text"
              textVariant="style19"
            />
            {!isPrivate && (
              <Tooltip title={portalName} placement="top">
                <PortalLogoContainer>
                  <PortalLogo src={portalLogo} size={24} alt={portalName} />
                </PortalLogoContainer>
              </Tooltip>
            )}
            <ButtonWrapper onClick={(event: any) => event.preventDefault()}>
              <ShareButton
                label={dictionary.jobOffers.share}
                src={shareIcon}
                menuItems={menuItems}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                disablePortal
              />
            </ButtonWrapper>
          </OtherInfoContainer>
        </div>
        <BenefitsContainer data-testid="job-benefits">
          {benefits.map((benefit) => (
            <Tooltip key={benefit.id} title={benefit.name} placement="top">
              <div>
                <Icon src={benefit.icon_url} alt={benefit.name} />
              </div>
            </Tooltip>
          ))}
        </BenefitsContainer>
        {!isMobile && newOfferChip}
      </StyledCard>
    </StyledA>
  );
};

export default JobOffer;
