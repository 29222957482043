import Backdrop from '@mui/material/Backdrop';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import arrowLeftIcon from '@rexmas/rexmas-shared/images/arrow-left-dash.svg';
import arrowRightIcon from '@rexmas/rexmas-shared/images/arrow-right-blue.svg';
import editIcon from '@rexmas/rexmas-shared/images/edit-2.svg';
import refreshIcon from '@rexmas/rexmas-shared/images/refresh.svg';
import closeIcon from '@rexmas/rexmas-shared/images/x-cobalt.svg';
import {
  IconButton,
  RoundedButton,
} from '@rexmas/rexmas-shared/lib/components/atoms/buttons';
import {
  ColorableIcon,
  Icon,
} from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import { Line } from '@rexmas/rexmas-shared/lib/components/atoms/misc';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { RoundedIconButton } from '@rexmas/rexmas-shared/lib/components/molecules/buttons';
import FileUpload from '@rexmas/rexmas-shared/lib/components/molecules/forms/FileUpload';
import TextAreaFieldInput from '@rexmas/rexmas-shared/lib/components/molecules/forms/inputs/TextAreaFieldInput';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { elevations } from '@rexmas/rexmas-shared/lib/conf/constants/elevations';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import ColorPickerInput from 'components/molecules/inputs/ColorPickerInput';
import {
  COMPANY_LOGO_FILE_EXTENSIONS,
  COMPANY_LOGO_MAX_FILE_SIZE,
} from 'conf/companies';
import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { ICompany, ICustomizeCompanyFormData } from 'types/ICompany';

import dictionary from '../../conf/dictionaries/es_CL';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 100%;
    box-shadow: ${elevations.elevation1};
    overflow-x: hidden;
    @media screen and (min-width: ${mediaQueries.sm}px) {
      width: 366px;
    }
  }
`;

const StyledBackdrop = styled(Backdrop)`
  background-color: ${colors.transparent};
`;

const Title = styled(Text)`
  color: ${colors.cobalt};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0 24px;
`;

const Description = styled(Text)`
  margin: 24px;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 20px;
  cursor: pointer;
  :hover {
    background-color: ${colors.whiteTwo};
  }
`;

const SectionText = styled(Text)`
  margin: 0 auto 0 8px;
`;

const StyledLine = styled(Line)`
  margin-left: 48px;
`;

const StyledRoundedIconButton = styled(RoundedIconButton)`
  width: auto;
  margin: 24px 16px 0 auto;
  max-width: 170px;
  > div {
    font-size: 14px;
    font-weight: 400;
  }
  background-color: ${colors.transparent};
  color: ${colors.backgroundDarkGrey};
  &:hover {
    background-color: ${colors.transparent};
    color: ${colors.backgroundDarkGrey};
  }
`;

const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 0 16px;
  gap: 8px;
`;

const ResetChangesButton = styled(IconButton)`
  margin: 0 16px 0 auto;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 22px 24px;
`;

const StyledColorPickerInput = styled(ColorPickerInput)`
  margin-bottom: 24px;
`;

const StyledTextAreaInput = styled(TextAreaFieldInput)`
  textarea {
    font-size: 14px;
  }
`;

const StyledFileUpload = styled(FileUpload)`
  .file-upload-input {
    min-height: 88px;
    padding: 16px 0;
    background-image: none;
    border-radius: 5px;
    border: 1px solid ${colors.veryLightPinkGrey};
    span {
      color: ${colors.highlightedOpaque};
    }
  }
`;

const FooterInputContainer = styled.div`
  margin: 32px 0;
`;

const SaveChangesButton = styled(RoundedButton)`
  border: 2px solid ${colors.cobalt};
  text-transform: uppercase;
  padding: 8px 16px;
  height: 42px;
  border-radius: 22px;
  margin-left: auto;
  min-width: 170px;
`;

const SaveChangesText = styled(Text)`
  color: ${colors.cobalt};
`;

interface Props {
  open: boolean;
  isSavingChanges: boolean;
  selectedCompany: ICompany;
  formMethods: UseFormReturn<ICustomizeCompanyFormData>;
  onClose: () => void;
  onSaveHeaderChanges: () => void;
  onSaveFooterChanges: () => void;
  onResetHeaderChanges: () => void;
}

const CustomizeWebsiteDrawer: React.FunctionComponent<Props> = ({
  open,
  isSavingChanges,
  selectedCompany,
  formMethods,
  onClose,
  onSaveHeaderChanges,
  onSaveFooterChanges,
  onResetHeaderChanges,
}) => {
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>();
  const f = dictionary.customizeWebsiteDrawer;
  const logo = formMethods.watch('thumbnail_logo')?.[0];

  const headerInputs = (
    <div>
      <StyledColorPickerInput
        name="header_background_color"
        defaultValue={selectedCompany.header_background_color}
        formMethods={formMethods}
        label={f.backgroundColor}
      />
      <StyledColorPickerInput
        name="button_color"
        defaultValue={selectedCompany.button_color}
        formMethods={formMethods}
        label={f.buttonColor}
      />
      <StyledColorPickerInput
        name="text_color"
        defaultValue={selectedCompany.text_color}
        formMethods={formMethods}
        label={f.headerTextColor}
      />
      <Text variant="style19">{f.headerText}</Text>
      <StyledTextAreaInput
        name="header_text"
        defaultValue={selectedCompany.header_text}
        placeholder={f.headerTextPlaceholder}
        formMethods={formMethods}
        maxCount={200}
      />
      <StyledFileUpload
        name="thumbnail_logo"
        file={logo}
        titleText={f.companyLogo}
        helperText={f.dragOrAddFile}
        fileRestrictionsText={f.companyLogoFileRestrictionsText}
        formMethods={formMethods}
        isLoading={false}
        optional
        accept={COMPANY_LOGO_FILE_EXTENSIONS}
        maxSize={COMPANY_LOGO_MAX_FILE_SIZE}
      />
    </div>
  );

  const footerInputs = (
    <FooterInputContainer>
      <Text variant="style19">{f.footerText}</Text>
      <StyledTextAreaInput
        name="footer_text"
        defaultValue={selectedCompany.footer_text}
        placeholder={f.footerTextPlaceholder}
        formMethods={formMethods}
        maxCount={200}
      />
    </FooterInputContainer>
  );

  const sections = [
    {
      label: f.header,
      icon: editIcon,
      inputs: headerInputs,
      onResetChanges: onResetHeaderChanges,
      onSaveChanges: onSaveHeaderChanges,
    },
    {
      label: f.footer,
      icon: editIcon,
      inputs: footerInputs,
      onSaveChanges: onSaveFooterChanges,
    },
  ];

  const selectedSection =
    selectedSectionIndex !== undefined && sections[selectedSectionIndex];

  return (
    <StyledDrawer
      open={open}
      onClose={onClose}
      BackdropComponent={StyledBackdrop}
      anchor="right"
      disableEnforceFocus
      SlideProps={{
        onExited: () => setSelectedSectionIndex(undefined),
      }}
    >
      {!selectedSection && (
        <>
          <TitleContainer>
            <Title variant="style5">{f.customizeWebsite}</Title>
            <IconButton onClick={onClose}>
              <Icon src={closeIcon} />
            </IconButton>
          </TitleContainer>
          <Description>{f.customizeWebsiteDescription}</Description>
          {sections.map((section, index) => (
            <SectionsContainer key={section.label}>
              <SectionRow onClick={() => setSelectedSectionIndex(index)}>
                <ColorableIcon
                  src={section.icon}
                  color={colors.veryLightBlueGrey}
                />
                <SectionText>{section.label}</SectionText>
                <ColorableIcon
                  src={arrowRightIcon}
                  color={colors.highlighted}
                />
              </SectionRow>
              <StyledLine color={colors.veryLightPinkGrey} />
            </SectionsContainer>
          ))}
        </>
      )}
      {selectedSection && (
        <>
          <SectionTitleContainer>
            <IconButton onClick={() => setSelectedSectionIndex(undefined)}>
              <ColorableIcon src={arrowLeftIcon} color={colors.textLightGrey} />
            </IconButton>
            <Title variant="style5">{selectedSection.label}</Title>
          </SectionTitleContainer>
          {selectedSection.onResetChanges && (
            <Tooltip title={f.resetChanges} placement="left">
              <ResetChangesButton onClick={selectedSection.onResetChanges}>
                <ColorableIcon src={refreshIcon} color={colors.highlighted} />
              </ResetChangesButton>
            </Tooltip>
          )}
          <InputsContainer>
            {selectedSection.inputs}
            <SaveChangesButton
              isLoading={isSavingChanges}
              disabled={isSavingChanges}
              variant="quinary"
              onClick={selectedSection.onSaveChanges}
            >
              <SaveChangesText variant="style23">
                {f.saveChanges}
              </SaveChangesText>
            </SaveChangesButton>
          </InputsContainer>
        </>
      )}
    </StyledDrawer>
  );
};

export default CustomizeWebsiteDrawer;
