import { AxiosError } from 'axios';
import apiRoutes from 'conf/api-routes';
import { OFFER_LETTER } from 'conf/query-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { IOfferLetter } from 'types/IOfferLetter';
import { apiFetcher } from 'utils/fetch';

export const useOfferLetter = (
  id: string,
  queryOptions = {},
): UseQueryResult<IOfferLetter, AxiosError> => {
  return useQuery<IOfferLetter, AxiosError>(
    [OFFER_LETTER, id],
    () =>
      apiFetcher({
        apiPath: apiRoutes.offerLetter,
        urlParams: { id },
      }).then((response) => response.data),
    {
      staleTime: Infinity,
      retry: false,
      ...queryOptions,
    },
  );
};

export default useOfferLetter;
