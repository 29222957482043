import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import React from 'react';
import { Checkboard } from 'react-color/lib/components/common';
import styled from 'styled-components';

const CheckboardContainer = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${colors.lightGrey};
  flex: 0 0 auto;
`;

const Color = styled.div<{ color: string; alpha: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${(props) => props.alpha};
  background-color: ${(props) => props.color};
`;

interface Props {
  color?: string;
  alpha?: number;
  className?: string;
}

const ColorCircle: React.FunctionComponent<Props> = ({
  color = colors.black,
  alpha = 1,
  className = '',
}) => (
  <CheckboardContainer className={className}>
    <Checkboard size={10} />
    <Color color={color} alpha={alpha} />
  </CheckboardContainer>
);

export default ColorCircle;
