import { showErrorAction } from '@rexmas/rexmas-shared/lib/redux/actionCreators/errors';
import { AxiosError, AxiosResponse } from 'axios';
import apiRoutes from 'conf/api-routes';
import { useMutation, UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { ISendMessageFormValues, ISendMessageResponse } from 'types/IChat';
import { apiFetcher } from 'utils/fetch';
import { getErrorMessage } from 'utils/utils';

export const useSendChatMessage = (
  id: string,
): UseMutationResult<
  AxiosResponse<ISendMessageResponse>,
  AxiosError,
  ISendMessageFormValues
> => {
  const dispatch = useDispatch();
  return useMutation(
    (data) => {
      const formData = new FormData();
      formData.append('body', data.body);
      if (data.file) {
        formData.append('attachment', data.file);
      }
      return apiFetcher({
        method: 'post',
        apiPath: apiRoutes.sendChatMessage,
        urlParams: { id },
        data: formData,
      });
    },
    {
      onError: (error) => {
        dispatch(showErrorAction({ message: getErrorMessage(error) }));
      },
    },
  );
};

export default useSendChatMessage;
