import { citiesChile, citiesColombia, citiesPeru } from './cities';
import dictionaryCL from './dictionaries/es_CL';
import dictionaryCO from './dictionaries/es_CO';
import dictionaryPE from './dictionaries/es_PE';

export const constants = {
  protocolUrl: process.env.REACT_APP_BACKEND_PROTOCOL || 'http',
  domain: process.env.REACT_APP_BACKEND_DOMAIN || 'localhost:8004',
  hasMultipleSubDomains:
    process.env.REACT_APP_HAS_MULTIPLE_SUBDOMAINS === 'true',
  apiVersion: process.env.REACT_APP_BACKEND_VERSION || 'api/v1',
  publicationQueryStaleTime: 1000 * 60 * 10,
};

export const genders = [
  {
    name: dictionaryCL.applicantData.inputs.genderMale,
    value: 1,
  },
  {
    name: dictionaryCL.applicantData.inputs.genderFemale,
    value: 2,
  },
  {
    name: dictionaryCL.applicantData.inputs.genderPreferNotToSay,
    value: 0,
  },
];

export const countries = [
  {
    name: 'Chile',
    value: 'CL',
  },
  {
    name: 'Perú',
    value: 'PE',
  },
  {
    name: 'Colombia',
    value: 'CO',
  },
];

export const cities = {
  CL: citiesChile,
  PE: citiesPeru,
  CO: citiesColombia,
};

export const processTypes = {
  FULL: 'FULL',
  MINIMUM: 'MINIMUM',
};

export const dictionaries = {
  CL: dictionaryCL,
  PE: dictionaryPE,
  CO: dictionaryCO,
};
