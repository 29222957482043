import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import { FileErrorCode, IFileRejection } from 'types/IFileRejection';

import dictionary from './dictionaries/es_CL';

export const CV_MAX_FILE_SIZE_MB = 3;
export const CV_MAX_FILE_SIZE_B = CV_MAX_FILE_SIZE_MB * 1024 * 1024;
export const CV_FILE_EXTENSIONS = ['.docx', '.pdf', '.txt'];

export const AVATAR_MAX_FILE_SIZE_MB = 3;
export const AVATAR_MAX_FILE_SIZE_B = AVATAR_MAX_FILE_SIZE_MB * 1024 * 1024;
export const AVATAR_FILE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];

export const FILE_REQUEST_MAX_FILE_SIZE_MB = 6;
export const FILE_REQUEST_MAX_FILE_SIZE_B =
  FILE_REQUEST_MAX_FILE_SIZE_MB * 1024 * 1024;
export const FILE_REQUEST_FILE_EXTENSIONS = [
  '.docx',
  '.pdf',
  '.xlsx',
  '.png',
  '.jpg',
  '.jpeg',
  '.svg',
];

export const INTRODUCTION_VIDEO_MAX_FILE_SIZE_MB = 10;
export const INTRODUCTION_VIDEO_MAX_FILE_SIZE_B =
  INTRODUCTION_VIDEO_MAX_FILE_SIZE_MB * 1024 * 1024;
export const INTRODUCTION_VIDEO_FILE_EXTENSIONS = [
  '.mp4',
  '.mpg',
  '.mpeg',
  '.webm',
  '.avi',
];

type Options = { maxFileSize: number; allowedExtensions: string[] };

export const getInvalidFileErrorMessage = (
  value: File | IFileRejection,
  options: Options,
): string | boolean => {
  if (value instanceof File) return true;
  const errorCode = value?.errors?.[0]?.code;

  const invalidFileSizeMessage = replaceStringVariables(
    dictionary.applicantData.inputs.invalidFileSizeError,
    { size: options.maxFileSize },
  );

  const invalidFileExtensionMessage = replaceStringVariables(
    dictionary.applicantData.inputs.invalidFileExtensionError,
    { extensions: options.allowedExtensions.join(', ') },
  );

  switch (errorCode) {
    case FileErrorCode.FileInvalidType:
      return invalidFileExtensionMessage;
    case FileErrorCode.FileTooLarge:
      return invalidFileSizeMessage;
    default:
      return false;
  }
};
