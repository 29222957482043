import { dictionaries } from 'conf/constants';
import { DictionaryContext } from 'conf/dictionaryContext';
import { useContext } from 'react';
import IPublication from 'types/IPublication';
import { CountryCode, IDictionary } from 'types/misc';

const useDictionary = (publication?: IPublication): IDictionary => {
  const { dictionary, setDictionary } = useContext(DictionaryContext);

  const currentCountryCode = dictionary.general.countryCode;
  const countryCode =
    (publication?.country_data.code as CountryCode) || currentCountryCode;

  if (currentCountryCode !== countryCode) {
    setDictionary(dictionaries[countryCode]);
  }

  return dictionary;
};

export default useDictionary;
