import ErrorTemplate from '@rexmas/rexmas-shared/lib/components/templates/Error';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import styled from 'styled-components';

import dictionary from '../../conf/dictionaries/es_CL';

const Container = styled.div`
  min-height: 100vh;
  padding: 84px 16px;
  background: linear-gradient(
    to bottom,
    ${colors.darkPrimary} 330px,
    ${colors.white} 0%
  );
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 84px;
  }
`;

interface Props {
  header?: string;
  firstSentence?: string;
  secondSentence?: string;
}

const Component404: React.FunctionComponent<Props> = ({
  header = dictionary[404].header,
  firstSentence = dictionary[404].firstSentence,
  secondSentence = dictionary[404].secondSentence,
}) => {
  return (
    <Container>
      <ErrorTemplate
        header={header}
        firstSentence={firstSentence}
        secondSentence={secondSentence}
        goBackButton={dictionary[404].goBackButton}
        goBackPath="/"
        withGoBackButton={false}
      />
    </Container>
  );
};

export default Component404;
