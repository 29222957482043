import { Textarea } from '@rexmas/rexmas-shared/lib/components/atoms/inputs';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import dictionary from 'conf/dictionaries/es_CL';
import React from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const StyledTextArea = styled(Textarea)`
  margin-bottom: 8px;
  .MuiInputBase-root {
    font-size: 14px;
    line-height: 22px;
    background-color: ${colors.whiteTwo};
    color: ${colors.textDarkGrey};
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${colors.veryLightPinkGrey};
  }
`;

const CountCharactersText = styled(Text)`
  color: ${colors.textDarkGrey};
`;

interface Props {
  name: number;
  maxLength: number;
  placeholder: string;
  rules?: RegisterOptions;
  formMethods: UseFormReturn;
}

const FreeText: React.FunctionComponent<Props> = ({
  name,
  placeholder,
  rules,
  formMethods,
  maxLength,
}) => {
  return (
    <Controller
      name={`${name}` as const}
      control={formMethods?.control}
      rules={rules}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <Container>
          <StyledTextArea
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            inputProps={{ maxLength }}
          />
          <CountCharactersText>
            {replaceStringVariables(dictionary.questions.characterCount, {
              count: value?.length || '0',
              max: maxLength,
            })}
          </CountCharactersText>
        </Container>
      )}
    />
  );
};

export default FreeText;
