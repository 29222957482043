import { getObjectsFromArrayOfStrings } from '../utils/utils';

const citiesNamesChile = [
  'Algarrobo',
  'Alhué',
  'Alto Biobío',
  'Alto Hospicio',
  'Alto del Carmen',
  'Ancud',
  'Andacollo',
  'Angol',
  'Antofagasta',
  'Antuco',
  'Antártica',
  'Arauco',
  'Arica',
  'Aysén',
  'Batuco',
  'Buin',
  'Bulnes',
  'Cabildo',
  'Cabo de Hornos',
  'Cabrero',
  'Calama',
  'Calbuco',
  'Caldera',
  'Calera',
  'Calera de Tango',
  'Calle Larga',
  'Camarones',
  'Camina',
  'Canela',
  'Carahue',
  'Cartagena',
  'Casablanca',
  'Castro',
  'Catemu',
  'Cauquenes',
  'Cañete',
  'Cerrillos',
  'Cerro Navia',
  'Chaitén',
  'Chanco',
  'Chañaral',
  'Chicureo',
  'Chiguayante',
  'Chile Chico',
  'Chillán',
  'Chillán Viejo',
  'Chimbarongo',
  'Chol Chol',
  'Chonchi',
  'Chépica',
  'Ciruelos',
  'Cisnes',
  'Cobquecura',
  'Cochamó',
  'Cochrane',
  'Codegua',
  'Coelemu',
  'Coihueco',
  'Coinco',
  'Colbun',
  'Colchane',
  'Colina',
  'Collipulli',
  'Coltauco',
  'Combarbalá',
  'Concepción',
  'Conchalí',
  'Concón',
  'Constitución',
  'Contulmo',
  'Copiapó',
  'Coquimbo',
  'Coronel',
  'Corral',
  'Coyhaique',
  'Cunco',
  'Curacautín',
  'Curaco de Vélez',
  'Curanilahue',
  'Curarrehue',
  'Curcaví',
  'Curepto',
  'Curicó',
  'Dalcahue',
  'Diego de Alamgro',
  'Diguillín',
  'Doñihue',
  'El Bosque',
  'El Carmen',
  'El Monto',
  'El Quisco',
  'El Tabo',
  'Empedrado',
  'Ercilla',
  'Estación Central',
  'Farellones',
  'Florida',
  'Freire',
  'Freirina',
  'Fresia',
  'Frutillar',
  'Futaleufú',
  'Futrono',
  'Galvarino',
  'General Lagos',
  'Gorbea',
  'Graneros',
  'Guaitecas',
  'Hijuelas',
  'Hospital',
  'Huakañé',
  'Hualaihué',
  'Hualpén',
  'Hualqui',
  'Huara',
  'Huasco',
  'Huechuraba',
  'Illapel',
  'Independencia',
  'Iquique',
  'Isla Maipo',
  'Isla de Pascua',
  'Juan Fernández',
  'La Cisterna',
  'La Cruz',
  'La Estrella',
  'La Florida',
  'La Granja',
  'La Higuera',
  'La Ligua',
  'La Pintana',
  'La Reina',
  'La Serena',
  'La Unión',
  'Lago Ranco',
  'Lago Verde',
  'Laguna Blanca',
  'Laja',
  'Lampa',
  'Lanco',
  'Las Cabras',
  'Las Condes',
  'Lautaro',
  'Lebu',
  'Licantén',
  'Limache',
  'Linares',
  'Litueche',
  'Llaillay',
  'Llanquihue',
  'Lo Barnechea',
  'Lo Espejo',
  'Lo Prado',
  'Lolol',
  'Loncoche',
  'Longaví',
  'Lonquimay',
  'Los Andes',
  'Los Lagos',
  'Los Molles',
  'Los Muermos',
  'Los Sauces',
  'Los Vilos',
  'Los Álamos',
  'Los Ángeles',
  'Lota',
  'Lumaco',
  'Machalí',
  'Macul',
  'Maipú',
  'Malloa',
  'Marchigue',
  'Mariquina',
  'María Elena',
  'María Pinto',
  'Maule',
  'Maullín',
  'Mejillones',
  'Melipeuco',
  'Melipilla',
  'Molin',
  'Monte Patria',
  'Mostazal',
  'Mulchen',
  'Máfil',
  'Nacimiento',
  'Nancagua',
  'Navidad',
  'Negrete',
  'Ninhue',
  'Ñiquén',
  'Nogales',
  'Nueva Imperial',
  'Ñuñoa',
  "O'Higgins",
  'Olivar',
  'Olmué',
  'Osorno',
  'Ovalle',
  'Padre Hurtado',
  'Padre Las Casas',
  'Paillaco',
  'Paine',
  'Palena',
  'Palmilla',
  'Panguipulli',
  'Panquehue',
  'Papudo',
  'Paredones',
  'Parral',
  'Pedro Aguirre Cerda',
  'Pelarco',
  'Pelluhue',
  'Pemuco',
  'Pencahue',
  'Penco',
  'Peralillo',
  'Perquenco',
  'Petorca',
  'Peumo',
  'Peñaflor',
  'Peñalolén',
  'Pica',
  'Pichidegua',
  'Pichilemu',
  'Pinto',
  'Pirque',
  'Pisagua',
  'Pitrufquén',
  'Placilla',
  'Portezuelo',
  'Porvenir',
  'Pozo Almonte',
  'Primavera',
  'Providencia',
  'Provincia de Atacama',
  'Puchuncaví',
  'Pucón',
  'Pudahuel',
  'Puente Alto',
  'Puerto Montt',
  'Puerto Natales',
  'Puerto Octay',
  'Puerto Varas',
  'Pumanque',
  'Punilla',
  'Punta Arenas',
  'Puqueldón',
  'Purranque',
  'Purén',
  'Putaendo',
  'Putre',
  'Puyehue',
  'Queilén',
  'Quellón',
  'Quemchi',
  'Quilaco',
  'Quilicura',
  'Quilleco',
  'Quillota',
  'Quillón',
  'Quilpué',
  'Quinchao',
  'Quinta Normal',
  'Quinta de Tillcoco',
  'Quintero',
  'Quirihue',
  'Rancagua',
  'Rauco',
  'Recoleta',
  'Renaico',
  'Renca',
  'Rengo',
  'Requínoa',
  'Retiro',
  'Rinconada',
  'Romeral',
  'Ránquil',
  'Río Bueno',
  'Río Claro',
  'Río Ibañez',
  'Río Negro',
  'Río Verde',
  'Saavedra',
  'Sagrada Familia',
  'San Antonio',
  'San Bernardo',
  'San Carlos',
  'San Clemente',
  'San Cruz',
  'San Esteban',
  'San Fabián',
  'San Felipe',
  'San Fernando',
  'San Gregorio',
  'San Ignacio',
  'San Javier',
  'San Joaquín',
  'San José de Maipo',
  'San Juan de la Costa',
  'San Miguel',
  'San Nicolás',
  'San Pablo',
  'San Pedro',
  'San Pedro de Atacama',
  'San Pedro de la Paz',
  'San Rafael',
  'San Ramón',
  'San Rosendo',
  'San Vicente',
  'Santa Bárbara',
  'Santa Juana',
  'Santa María',
  'Santiago',
  'Santo Domingo',
  'Sierra Gorda',
  'Talagante',
  'Talca',
  'Talcahuano',
  'Taltal',
  'Temuco',
  'Teno',
  'Teodoro Schmidt',
  'Tierra Amarilla',
  'Tiltil',
  'Timaukel',
  'Tirúa',
  'Tocopilla',
  'Toltén',
  'Tomé',
  'Torres del Paine',
  'Tortel',
  'Traiguén',
  'Trehuaco',
  'Tucapel',
  'Valdivia',
  'Vallenar',
  'Valparaíso',
  'Vichuquén',
  'Victoria',
  'Vilcún',
  'Villa Alegre',
  'Villa Alemana',
  'Villarrica',
  'Vitacura',
  'Viña del Mar',
  'Yerbas Buenas',
  'Yumbel',
  'Yungay',
  'Zapallar',
];

const citiesNamesPeru = [
  'Abancay',
  'Acobamba',
  'Acomayo',
  'Aija',
  'Alto Amazonas',
  'Ambo',
  'Andahuaylas',
  'Angaraes',
  'Anta',
  'Antabamba',
  'Antonio Raymond',
  'Arequipa',
  'Ascope',
  'Asunción',
  'Atalaya',
  'Ate',
  'Ayabaca',
  'Aymaraes',
  'Azángaro',
  'Bagua',
  'Barranca',
  'Barranco',
  'Bellavista',
  'Bolognesi',
  'Bolívar',
  'Bongará',
  'Breña',
  'Cajabamba',
  'Cajamarca',
  'Cajatambo',
  'Calca',
  'Callao',
  'Camaná',
  'Canas',
  'Canchis',
  'Candarave',
  'Cangallo',
  'Canta',
  'Carabaya',
  'Caraveli',
  'Carhuaz',
  'Carlos Fermín Fitzcarrald',
  'Casma',
  'Castilla',
  'Castrovirreyna',
  'Caylloma',
  'Cañete',
  'Celendín',
  'Cercado de Lima',
  'Chachapoyas',
  'Chanchamayo',
  'Chepén',
  'Chicheros',
  'Chiclayo',
  'Chincha',
  'Chorrillos',
  'Chota',
  'Chucuito',
  'Chumbivilcas',
  'Chupaca',
  'Churcampa',
  'Comas',
  'Concepción',
  'Condesuyos',
  'Condorcanqui',
  'Contralmirante Villar',
  'Contumazá',
  'Coronel Portillo',
  'Corongo',
  'Cotabambas',
  'Cusco',
  'Cutervo',
  'Daniel Alcides Carrión',
  'Datem de Marañón',
  'Dos de Mayo',
  'El Agustino',
  'El Collao',
  'El Dorado',
  'Espinar',
  'Ferreñafe',
  'General Sánchez Cerro',
  'Gran Chimú',
  'Grau',
  'Huacané',
  'Huacaybamba',
  'Hualgayoc',
  'Huallaga',
  'Huamalíes',
  'Huamanga',
  'Huancabamba',
  'Huancasancos',
  'Huancavelica',
  'Huancayo',
  'Huanta',
  'Huanuco',
  'Huaral',
  'Huaraz',
  'Huari',
  'Huarmey',
  'Huarochirí',
  'Huaura',
  'Huaylas',
  'Huaytará',
  'Ica',
  'Ilo',
  'Independencia',
  'Islay',
  'Jauja',
  'Jaén',
  'Jesús María',
  'Jorge Basadre',
  'Julcán',
  'Junín',
  'La Convención',
  'La Mar',
  'La Molina',
  'La Unión',
  'La Victoria',
  'Lamas',
  'Lambayeque',
  'Lampa',
  'Lauricocha',
  'Leoncio Prado',
  'Lima Metropolitana',
  'Lince',
  'Loreto',
  'Los Olivos',
  'Lucanas',
  'Luya',
  'Magdalena del Mar',
  'Manu',
  'Marañón',
  'Mariscal Cáceres',
  'Mariscal Luzuriaga',
  'Mariscal Nieto',
  'Mariscal Ramón Castilla',
  'Maynas',
  'Melgar',
  'Miraflores',
  'Moho',
  'Morropón',
  'Moyobamba',
  'Nazca',
  'Ocros',
  'Otuzco',
  'Oxapampa',
  'Oyón',
  'Pacasmayo',
  'Pachitea',
  'Padre Abad',
  'Paita',
  'Pallasca',
  'Palpa',
  'Parinacochas',
  'Paruro',
  'Pasco',
  'Pataz',
  'Paucartambo',
  'Picota',
  'Pisco',
  'Piura',
  'Pomabamba',
  'Pueblo Libre',
  'Puente Piedra',
  'Puerto Inca',
  'Puno',
  'Purús',
  'Putumayo',
  'Páucar del Sara Sara',
  'Quispicanchi',
  'Recuay',
  'Requena',
  'Rimac',
  'Rioja',
  'Rodríguez de Mendoza',
  'San Antonio de Putiña',
  'San Borja',
  'San Ignacio',
  'San Isidro',
  'San Juan de Lurigancho',
  'San Juan de Miraflores',
  'San Luis',
  'San Marcos',
  'San Martin de Porres',
  'San Martín',
  'San Miguel',
  'San Pablo',
  'San Román',
  'Sanchez Carrión',
  'Sandía',
  'Santa',
  'Santa Anita',
  'Santa Cruz',
  'Santa Rosa',
  'Santiago de Chuco',
  'Santiago de Surco',
  'Satipo',
  'Sechura',
  'Sihuas',
  'Sucre',
  'Sullana',
  'Surquillo',
  'Tacna',
  'Tahuamanu',
  'Talara',
  'Tambopata',
  'Tarata',
  'Tarma',
  'Tayacaja',
  'Tocache',
  'Trujillo',
  'Tumbes',
  'Ucayali',
  'Urubamba',
  'Utcubamba',
  'Vilcashuaman',
  'Villa El Salvador',
  'Villa María del Triunfo',
  'Virú',
  'Víctor Fajardo',
  'Yarowilca',
  'Yauli',
  'Yauyos',
  'Yungay',
  'Yunguyo',
  'Zarumilla',
];

const citiesNamesColombia = ['Barranquilla', 'Bogotá', 'Calí', 'Medellín'];

export const citiesChile = getObjectsFromArrayOfStrings(citiesNamesChile);
export const citiesPeru = getObjectsFromArrayOfStrings(citiesNamesPeru);
export const citiesColombia = getObjectsFromArrayOfStrings(citiesNamesColombia);
