import { Range as RangeQuestion } from '@rexmas/rexmas-shared/lib/components/molecules/questions';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import React from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { IQuestion } from 'types/IQuestion';

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const StyledRangeQuestion = styled(RangeQuestion)`
  margin-bottom: 16px;
  .MuiRadio-root {
    &:hover {
      background-color: ${colors.veryLightBlueGrey20};
    }
  }
`;

interface Props {
  name: number;
  question: IQuestion;
  rules?: RegisterOptions;
  formMethods: UseFormReturn;
}

const Range: React.FunctionComponent<Props> = ({
  name,
  question,
  rules,
  formMethods,
}) => {
  return (
    <Controller
      name={`${name}` as const}
      control={formMethods?.control}
      rules={rules}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <StyledRangeQuestion
          selectedValue={value}
          question={question}
          onChange={(event: ChangeEvent) =>
            onChange(Number(event.target.value))
          }
        />
      )}
    />
  );
};

export default Range;
