import { AxiosError, AxiosResponse } from 'axios';
import apiRoutes from 'conf/api-routes';
import {
  getIsFormValueConstant,
  getIsFormValueMoment,
} from 'conf/postulantProfile';
import { POSTULANT_PROFILE } from 'conf/query-keys';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import {
  IPostulantProfile,
  IPostulantProfileFormData,
  PostulantProfileState,
} from 'types/IPostulantProfile';
import { apiFetcher } from 'utils/fetch';

export const useSendPostulantProfileAnswers = (
  id: string,
): UseMutationResult<
  AxiosResponse<IPostulantProfile>,
  AxiosError,
  IPostulantProfileFormData
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const parsedData: Record<string, any> = {};
      Object.entries(data).forEach(([key, formValue]) => {
        if (getIsFormValueConstant(formValue)) {
          parsedData[key] = formValue.value;
        } else if (getIsFormValueMoment(formValue)) {
          parsedData[key] = formValue.format('YYYY-MM-DD');
        } else {
          parsedData[key] = formValue;
        }
      });

      return apiFetcher({
        method: 'post',
        apiPath: apiRoutes.postulantProfile,
        urlParams: { id },
        data: parsedData,
      });
    },
    {
      onSuccess: () => {
        queryClient.setQueryData<IPostulantProfile | undefined>(
          [POSTULANT_PROFILE, id],
          (profileData) =>
            profileData && {
              ...profileData,
              state: PostulantProfileState.COMPLETED,
            },
        );
        queryClient.invalidateQueries([POSTULANT_PROFILE, id]);
      },
    },
  );
};

export default useSendPostulantProfileAnswers;
