export const SEND_MESSAGE_MAX_FILE_SIZE = 3 * 1024 * 1024;
export const SEND_MESSAGE_FILE_EXTENSIONS = [
  '.txt',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.png',
  '.jpeg',
];

export const getMessageDate = (date: string): string => {
  return new Intl.DateTimeFormat('es-CL', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
};
