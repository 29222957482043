import {
  createTheme,
  StyledEngineProvider,
  THEME_ID,
  ThemeProvider,
} from '@mui/material/styles';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import MessageBoundary from 'components/MessageBoundary';
import dictionaryCL from 'conf/dictionaries/es_CL';
import { DictionaryContext } from 'conf/dictionaryContext';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import Routes from 'Routes';
import { IDictionary } from 'types/misc';

import store from './redux/store';

const breakpoints = {
  breakpoints: {
    values: mediaQueries,
  },
};

const theme = createTheme(breakpoints);
const queryClient = new QueryClient();

const App: React.FunctionComponent = () => {
  const [dictionary, setDictionary] = useState<IDictionary>(dictionaryCL);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={{ [THEME_ID]: theme }}>
            <MessageBoundary>
              <DictionaryContext.Provider value={{ dictionary, setDictionary }}>
                <Routes />
              </DictionaryContext.Provider>
            </MessageBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
