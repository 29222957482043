import { InputBaseProps } from '@mui/material';
import { AutoComplete } from '@rexmas/rexmas-shared/lib/components/molecules/inputs';
import React, { useState } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

type Option = Record<string, any>;
type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

interface Props {
  disabled?: boolean;
  name: string;
  placeholder: string;
  helperText?: string;
  className?: string;
  options: Array<Option>;
  getOptionLabel: (option: Option) => string;
  rules?: RegisterOptions;
  hideSearch?: boolean;
  clearable?: boolean;
  formMethods: UseFormReturn;
  InputProps?: InputBaseProps;
  defaultValue?: Option | null;
  loading?: boolean;
  loadingText?: string;
  onChangeAction?: (data: any) => void;
}

const AutoCompleteInput: React.FunctionComponent<Props> = ({
  disabled = false,
  name,
  placeholder = '',
  helperText,
  className,
  options,
  getOptionLabel,
  rules,
  formMethods,
  hideSearch = false,
  clearable = false,
  defaultValue = null,
  InputProps,
  loading = false,
  loadingText = '',
  onChangeAction,
}) => {
  const [searchInputValue, setSearchInputValue] = useState('');

  return (
    <Controller
      name={`${name}` as const}
      rules={rules}
      control={formMethods.control}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, ref },
        fieldState: { invalid, error },
      }) => (
        <AutoComplete
          value={getOptionLabel ? getOptionLabel(value) : value}
          onChange={(_event: ChangeEvent, selectedValue: Option) => {
            if (onChangeAction) {
              onChangeAction(selectedValue);
            }
            onChange(selectedValue);
            formMethods.trigger(name);
          }}
          options={options}
          placeholder={placeholder}
          label={value ? placeholder : '\0'}
          inputValue={searchInputValue}
          onInputChange={(event: ChangeEvent) =>
            setSearchInputValue(event?.target.value)
          }
          getOptionLabel={getOptionLabel}
          getOptionElement={getOptionLabel}
          error={invalid}
          helperText={invalid ? error?.message : helperText}
          hideSearch={hideSearch}
          isLineChart
          disabled={disabled}
          clearable={clearable}
          className={className}
          loading={loading}
          loadingText={loadingText}
          InputProps={{
            inputRef: ref,
            ...InputProps,
          }}
        />
      )}
    />
  );
};

export default AutoCompleteInput;
