import IConstant from './IConstant';
import { Moment } from './misc';

export enum PostulantProfileState {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum PostulantProfileFieldType {
  CHAR_FIELD = 'CHAR_FIELD',
  TEXT_FIELD = 'TEXT_FIELD',
  DATE_FIELD = 'DATE_FIELD',
  AUTOCOMPLETE_FIELD = 'AUTOCOMPLETE_FIELD',
}

export enum PostulantProfileFieldAlphabet {
  NUMERIC = 'NUMERIC',
  ALPHABETIC = 'ALPHABETIC',
  DECIMAL = 'DECIMAL',
  ALPHANUMERIC = 'ALPHANUMERIC',
}

export interface IPostulantProfileFieldValidation {
  required: boolean;
  min_length: string | null;
  max_length: string | null;
  min_value: number | null;
  max_value: number | null;
  pattern: string | null;
  custom_validation: string | null;
  allow_null: boolean;
  allow_blank: boolean;
  alphabet: PostulantProfileFieldAlphabet | '';
}

export interface IPostulantProfileField {
  id: number;
  name: string;
  placeholder: string;
  helper_text: string;
  field_type: PostulantProfileFieldType;
  position_in_section: number;
  validation: IPostulantProfileFieldValidation;
  choices: IConstant[];
  content?: string;
  payroll_identifier: string;
}

export interface IPostulantProfileSection {
  id: number;
  name: string;
  fields: IPostulantProfileField[];
}

interface IPostulantMetadata {
  first_name: string;
  last_name: string;
  email: string;
}

export interface IPostulantProfile {
  uuid: string;
  state: PostulantProfileState;
  company_name: string;
  company_logo: string;
  postulant_metadata: IPostulantMetadata;
  sections: IPostulantProfileSection[];
}

export type IPostulantProfileFormValue =
  | undefined
  | null
  | string
  | number
  | Moment
  | IConstant;

export interface IPostulantProfileFormData {
  [key: string]: IPostulantProfileFormValue;
}
