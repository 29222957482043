import { showErrorAction } from '@rexmas/rexmas-shared/lib/redux/actionCreators/errors';
import apiRoutes from 'conf/api-routes';
import { useMutation, UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IAnswer } from 'types/IQuestion';
import { apiFetcher } from 'utils/fetch';
import { getErrorMessage } from 'utils/utils';

const getIsNoGender = (genderValue: number) => {
  return genderValue === 0;
};

const usePostApplication = (
  publicationId: string,
  requiredFields: string[],
  urlProcessType: string,
): UseMutationResult => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  return useMutation(
    (data: any) => {
      const validFields: Record<string, any> = {
        first_name: {
          fieldName: 'first_name',
          formValue: data.first_name,
        },
        last_name: {
          fieldName: 'last_name',
          formValue: data.last_name,
        },
        email: {
          fieldName: 'email',
          formValue: data.email,
        },
        phone: {
          fieldName: 'phone',
          formValue: data.phone,
        },
        gender: {
          fieldName: 'gender',
          formValue: data.gender?.value,
        },
        salary_claim: {
          fieldName: 'salary_claim',
          formValue: data.salary,
        },
        national_id: {
          fieldName: 'national_id',
          formValue: data.national_id,
        },
        linkedin: {
          fieldName: 'linkedin',
          formValue: data.linkedIn,
        },
        country: {
          fieldName: 'country',
          formValue: data.country?.value,
        },
        city: {
          fieldName: 'city',
          formValue: data.city?.value,
        },
        address: {
          fieldName: 'address',
          formValue: data.address,
        },
        cv: {
          fieldName: 'cv_file',
          formValue: data.cv,
        },
        birth_date: {
          fieldName: 'birth_date',
          formValue:
            data.birth_date === ''
              ? null
              : data.birth_date?.format('YYYY-MM-DD'),
        },
        residence: {
          fieldName: 'residence',
          formValue: data.residence?.value,
        },
        introduction_video: {
          fieldName: 'introduction_video_file',
          formValue: data.introduction_video,
        },
      };
      const formData = new FormData();
      const isNoGender = getIsNoGender(validFields.gender.formValue);

      requiredFields.forEach((field: string) => {
        if (
          validFields[field] &&
          (validFields[field].formValue || (isNoGender && field === 'gender'))
        ) {
          formData.append(
            validFields[field].fieldName,
            validFields[field].formValue,
          );
        }
      });
      if (requiredFields.includes('profile_pic') && data.avatar) {
        formData.append('profile_pic_file', data.avatar);
      }
      data.answers.forEach((answer: IAnswer) => {
        formData.append('answers', JSON.stringify(answer));
      });
      data.fileRequests.forEach((fileRequest: { id: number; file: File }) => {
        formData.append('file_request_ids', String(fileRequest.id));
        formData.append('file_requests', fileRequest.file);
      });

      return apiFetcher({
        method: 'post',
        apiPath: apiRoutes.application,
        urlParams: { publicationId, urlProcessType },
        data: formData,
        params: new URLSearchParams(search),
      });
    },
    {
      onError: (error) => {
        dispatch(showErrorAction({ message: getErrorMessage(error) }));
      },
    },
  );
};

export default usePostApplication;
