import OfferLetterTemplate from 'components/templates/OfferLetter';
import { useSendOfferLetterResolution } from 'mutations/offerLetter';
import { useOfferLetter } from 'queries/offerLetter';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { IOfferLetterFormData } from 'types/IOfferLetter';
import { getIsClientError } from 'utils/fetch';

import Page404 from './404';

interface Params {
  id: string;
}

const OfferLetter: React.FunctionComponent = () => {
  const { id } = useParams<Params>();
  const { data: offerLetter, isLoading, isError, error } = useOfferLetter(id);
  const formMethods = useForm<IOfferLetterFormData>();
  const offerLetterResolutionMutation = useSendOfferLetterResolution(id);

  if (getIsClientError(isError, error)) {
    return <Page404 />;
  }

  const onSubmitResolution = (data: IOfferLetterFormData) => {
    offerLetterResolutionMutation.mutate(data);
  };

  return (
    <OfferLetterTemplate
      isLoading={isLoading}
      isSubmittingResolution={offerLetterResolutionMutation.isLoading}
      isSubmitSuccess={offerLetterResolutionMutation.isSuccess}
      offerLetter={offerLetter}
      formMethods={formMethods}
      onSubmit={formMethods.handleSubmit(onSubmitResolution)}
    />
  );
};

export default OfferLetter;
