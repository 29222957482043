const urls = {
  publication: '/:publicationSlug',
  applicationSteps: '/:publicationSlug/application-process',
  jobOffers: `/${process.env.REACT_APP_JOBS_PATHNAME || 'jobs'}/:companySlug`,
  offerLetter: '/offer_letter/:id',
  postulantProfile: '/applicant_profile_form/:id',
  chat: '/chat/:id',
};

export default urls;
