import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { SnackbarCustom } from '@rexmas/rexmas-shared/lib/components/molecules/snackbars';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { dismissErrorAction } from '@rexmas/rexmas-shared/lib/redux/actionCreators/errors';
import { dismissMessageAction } from '@rexmas/rexmas-shared/lib/redux/actionCreators/messages';
import { errorSelector } from '@rexmas/rexmas-shared/lib/redux/selectors/errors';
import { messageSelector } from '@rexmas/rexmas-shared/lib/redux/selectors/messages';
import {
  useActions,
  useShallowEqualSelector,
} from '@rexmas/rexmas-shared/lib/utils/hooks';
import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledText = styled(Text)`
  color: ${colors.white};
  font-weight: 500;
`;

interface Props {
  children: ReactNode;
}

const MessageBoundary: React.FunctionComponent<Props> = ({ children }) => {
  const dismissErrorMessage = useActions(dismissErrorAction);
  const dismissMessage = useActions(dismissMessageAction);

  const error = useShallowEqualSelector(errorSelector);
  const message = useShallowEqualSelector(messageSelector);

  return (
    <>
      {children}
      <SnackbarCustom
        open={error.hasError}
        variant="error"
        onClose={dismissErrorMessage}
        autoHideDuration={5000}
      >
        <StyledText>{error.user.message}</StyledText>
      </SnackbarCustom>
      <SnackbarCustom
        open={message.hasMessage}
        variant={message.messageVariant}
        onClose={dismissMessage}
        autoHideDuration={5000}
      >
        <StyledText>{message.message}</StyledText>
      </SnackbarCustom>
    </>
  );
};

export default MessageBoundary;
