import { useDebounce } from '@rexmas/rexmas-shared/lib/utils/hooks';
import { AxiosError } from 'axios';
import apiRoutes from 'conf/api-routes';
import { constants } from 'conf/constants';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import IPublication, {
  ICompanyPublications,
  IPublicationsFiltersFormData,
} from 'types/IPublication';

import {
  COMPANY_PUBLICATIONS,
  PUBLICATION,
  PUBLICATIONTYPE,
} from '../conf/query-keys';
import { apiFetcher } from '../utils/fetch';

export const usePublication = (
  id: string,
  queryOptions = {},
): UseQueryResult<IPublication, AxiosError> => {
  return useQuery<IPublication, AxiosError>(
    [PUBLICATION, id],
    () =>
      apiFetcher({
        apiPath: apiRoutes.publication,
        urlParams: { id },
      }).then((response) => response.data),
    {
      staleTime: constants.publicationQueryStaleTime,
      retry: false,
      ...queryOptions,
    },
  );
};

export const useApplicationFields = (
  publicationId: string,
  queryOptions = {},
): UseQueryResult<string[], AxiosError> => {
  return useQuery<string[], AxiosError>(
    [PUBLICATIONTYPE, publicationId],
    () =>
      apiFetcher({
        apiPath: apiRoutes.applicationFields,
        urlParams: { publicationId },
      }).then((response) => response.data),
    {
      ...queryOptions,
    },
  );
};

export const useCompanyPublications = (
  companyId: string | undefined,
  filters: IPublicationsFiltersFormData,
  queryOptions = {},
): UseInfiniteQueryResult<ICompanyPublications, AxiosError> => {
  const debouncedSearch = useDebounce(filters?.searchText, 300);
  const params = {
    search: debouncedSearch || '',
    contract_type_key: filters.contractType?.value,
    department_id: filters.department?.id,
    limit: 5,
  };

  return useInfiniteQuery(
    [COMPANY_PUBLICATIONS, companyId, params],
    ({ pageParam }) =>
      apiFetcher({
        apiPath: apiRoutes.companyPublications,
        urlParams: { companyId },
        withSubdomain: true,
        params: {
          ...params,
          page_id: pageParam,
        },
      }).then((response) => response.data),
    {
      enabled: !!companyId,
      getNextPageParam: (lastPage) => lastPage?.page_id || undefined,
      ...queryOptions,
    },
  );
};
