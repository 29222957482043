import { CompletedApplicationTemplate } from 'components/templates';

interface Props {
  publicationSlug: string | undefined;
  positionName: string;
  companyName?: string;
}

const CompletedApplication: React.FunctionComponent<Props> = ({
  publicationSlug,
  positionName,
  companyName = '',
}) => {
  return (
    <CompletedApplicationTemplate
      publicationSlug={publicationSlug}
      positionName={positionName}
      companyName={companyName}
    />
  );
};

export default CompletedApplication;
