import { showErrorAction } from '@rexmas/rexmas-shared/lib/redux/actionCreators/errors';
import { AxiosError, AxiosResponse } from 'axios';
import apiRoutes from 'conf/api-routes';
import { OFFER_LETTER } from 'conf/query-keys';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { IOfferLetter, IOfferLetterFormData } from 'types/IOfferLetter';
import { apiFetcher } from 'utils/fetch';
import { getErrorMessage } from 'utils/utils';

export const useSendOfferLetterResolution = (
  id: string,
): UseMutationResult<
  AxiosResponse<IOfferLetter>,
  AxiosError,
  IOfferLetterFormData
> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      apiFetcher({
        method: 'post',
        apiPath: apiRoutes.offerLetterResolution,
        urlParams: { id },
        data: {
          ...data,
          state: data.state?.value,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([OFFER_LETTER, id]);
      },
      onError: (error) => {
        dispatch(showErrorAction({ message: getErrorMessage(error) }));
      },
    },
  );
};

export default useSendOfferLetterResolution;
