import { isToday } from '@rexmas/rexmas-shared/lib/lib/moment';
import { getElapsedDays } from 'utils/utils';

export const getPublishedDate = (date: string): string => {
  const daysPublished = getElapsedDays(date);

  if (daysPublished < 8) {
    return new Intl.RelativeTimeFormat('es', {
      numeric: isToday(date) ? 'auto' : 'always',
    }).format(-daysPublished, 'day');
  }

  return new Intl.DateTimeFormat('es', {
    day: 'numeric',
    month: 'long',
  }).format(new Date(date));
};

export default getPublishedDate;
