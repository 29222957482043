export enum MessageSenderType {
  USER = 'USER',
  PROCESS_POSTULANT = 'PROCESS_POSTULANT',
}

export interface IMessage {
  id: number;
  body: string;
  sender_type: string;
  sender_name: string;
  created_date: string;
  attachment: string | undefined;
  attachment_size: string | undefined;
  filename: string | undefined;
}

export interface IChat {
  chat_uuid: string;
  selection_process_name: string;
  user_full_name: string;
  user_avatar: string | null;
  postulant_avatar: string | null;
  postulant_full_name: string | null;
  messages: IMessage[];
}

export interface ISendMessageFormValues {
  body: string;
  file?: File;
}

export interface ISendMessageResponse {
  message_id: number;
}
