import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import arrowLeftIcon from '@rexmas/rexmas-shared/images/arrow-left-dash.svg';
import briefcaseIcon from '@rexmas/rexmas-shared/images/briefcase-light-blue.svg';
import calendarIcon from '@rexmas/rexmas-shared/images/calendar.svg';
import clockIcon from '@rexmas/rexmas-shared/images/clock.svg';
import DollarIcon from '@rexmas/rexmas-shared/images/dollar-sign-highlight.svg';
import homeIcon from '@rexmas/rexmas-shared/images/home-highlight.svg';
import InfoIcon from '@rexmas/rexmas-shared/images/info.svg';
import { Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import Skeleton from '@rexmas/rexmas-shared/lib/components/atoms/misc/Skeleton';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { RoundedIconButton } from '@rexmas/rexmas-shared/lib/components/molecules/buttons';
import { TitleSubtitleLineHeader } from '@rexmas/rexmas-shared/lib/components/molecules/headers';
import { IconText } from '@rexmas/rexmas-shared/lib/components/molecules/text';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { elevations } from '@rexmas/rexmas-shared/lib/conf/constants/elevations';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import PurifiedElement from 'components/atoms/PurifiedElement';
import { getCompanyColors } from 'conf/companies';
import useDictionary from 'hooks/useDictionary';
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import IPublication from 'types/IPublication';
import { getFormattedCurrency } from 'utils/utils';

const Container = styled.div`
  min-height: 100vh;
`;

const HeaderBackgroundContainer = styled.div<{
  'is-back-button-visible': boolean;
}>`
  background: ${(props) => props.theme.backgroundColor};
  padding: 64px 16px 96px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 126px 84px 96px;
    ${(props) => props['is-back-button-visible'] && 'padding-top: 70px'};
  }
  a {
    display: inline-block;
  }
`;

const GoBackButton = styled(RoundedIconButton)`
  width: auto;
  margin: 0 0 20px -18px;
  padding-right: 20px;
  color: ${(props) => props.theme.textColor};
  background-color: ${colors.transparent};
  border: none;
  > div {
    font-size: 18px;
    font-weight: 400;
  }
  &:hover {
    color: ${(props) => props.theme.textColor};
    background-color: ${colors.transparent};
  }
`;

const HeaderContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px 172px 16px 84px;
  background-color: ${(props) => props.theme.backgroundColor};
  z-index: 10;
  @media screen and (min-width: ${mediaQueries.lg}px) {
    display: flex;
    gap: 32px;
  }
`;

const SummaryHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyText = styled(Text)`
  font-weight: 400;
  color: ${(props) => props.theme.textColor};
`;

const PositionNameText = styled(Text)`
  font-size: 36px;
  color: ${(props) => props.theme.textColor};
`;

const FlagIcon = styled(Icon)`
  width: 19px;
  height: 12px;
`;

const PositionLocationText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  color: ${(props) => props.theme.textColor};
`;

const CardsContainer = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  margin-top: -64px;
  gap: 24px;
  padding: 0 16px 32px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 0 84px 64px;
  }
  @media screen and (min-width: ${mediaQueries.lg}px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 32px;
  border-radius: 15px;
  box-shadow: ${elevations.elevation6};
  background-color: ${colors.white};
  @media screen and (min-width: ${mediaQueries.lg}px) {
    width: auto;
    min-width: 350px;
  }
`;

const JobSummaryList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 24px 0;
  row-gap: 12px;
`;

const StyledTitleSubtitleLineHeader = styled(TitleSubtitleLineHeader)`
  .LineHeader-Title {
    color: ${colors.backgroundDarkGrey};
  }
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding-right: 28px;
  }
`;

const DescriptionText = styled(Text)`
  display: block;
  margin: 8px 0 24px 0;
  line-height: 22px;
`;

const ApplyButton = styled(RoundedIconButton)`
  width: auto;
  min-width: 160px;
  height: 42px;
  padding-right: 20px;
  background-color: ${colors.lightHighlighted};
  &:hover {
    background-color: ${colors.highlighted};
  }
  > div {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &.Mui-disabled .MuiButton-label {
    color: ${colors.whiteTwo};
  }
`;

const JobSummaryIconText = styled(IconText)`
  align-self: flex-start;
  align-items: center;
  .colorable-icon {
    padding: 0 12px;
  }
`;

const BenefitsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px 8px;
  margin-top: 8px;
`;

const Benefit = styled(IconText)`
  align-items: center;
  .colorable-icon {
    padding: 0 12px;
  }
`;

const getIsLoading = (isLoading: boolean, publication?: IPublication) => {
  return isLoading || !publication;
};

const getShowCompanyData = (isLoading: boolean, publication?: IPublication) => {
  return !isLoading && !publication?.is_enterprise_confidential;
};

const getPublishedDate = (publication?: IPublication) => {
  return new Date(publication?.created_on || '');
};

const positionSummaryHeaderSkeleton = (
  <>
    <Skeleton width={160} />
    <Skeleton height={52} width={340} />
    <Skeleton width={280} />
  </>
);

const isVisibleGoBackButton = (publication?: IPublication) => {
  return !!(
    publication?.is_enterprise_confidential === false && publication?.url_return
  );
};

const getPositionSummaryHeader = (
  publication: IPublication | undefined,
  showCompany: boolean,
  isLoading: boolean,
) => {
  if (isLoading) {
    return positionSummaryHeaderSkeleton;
  }
  return (
    <SummaryHeaderContainer data-testid="publication-summary-header">
      <CompanyText variant="heading6">
        {showCompany && publication?.enterprise?.name}
      </CompanyText>
      <PositionNameText variant="style5">{publication?.title}</PositionNameText>
      <PositionLocationText variant="heading6">
        <FlagIcon
          src={publication?.country_data.flag}
          alt={publication?.country_data.code}
        />
        {publication?.city} | {publication?.contract_type}
      </PositionLocationText>
    </SummaryHeaderContainer>
  );
};

interface Props {
  isPublicationLoading: boolean;
  publication: IPublication | undefined;
}

const PublicationTemplate: React.FunctionComponent<Props> = ({
  isPublicationLoading,
  publication,
}) => {
  const location = useLocation();
  const dictionary = useDictionary(publication);
  const pageColors = getCompanyColors(publication);
  const trigger = useScrollTrigger({ threshold: 600, disableHysteresis: true });
  const isLoading = getIsLoading(isPublicationLoading, publication);
  const showCompanyData = getShowCompanyData(isLoading, publication);
  const jobAddress = `${publication?.city}, ${publication?.region}`;
  const publishedDate = getPublishedDate(publication).toLocaleString('es', {
    day: 'numeric',
    month: 'long',
  });
  const jobSalaryRange = `${getFormattedCurrency(
    publication?.salary_range?.lower,
    dictionary,
  )} - ${getFormattedCurrency(publication?.salary_range?.upper, dictionary)}`;

  const jobSummary = [
    { id: 1, text: publication?.title, icon: briefcaseIcon },
    {
      id: 2,
      text: `${dictionary.publication.publishedOn} ${publishedDate}`,
      icon: calendarIcon,
    },
    { id: 3, text: jobAddress, icon: homeIcon },
    { id: 4, text: publication?.contract_type, icon: clockIcon },
    { id: 5, text: publication?.department, icon: InfoIcon },
    {
      id: 6,
      text: jobSalaryRange,
      icon: DollarIcon,
      hide: publication?.is_salary_confidential,
    },
  ].filter(({ hide }) => !hide);

  const positionSummaryHeader = getPositionSummaryHeader(
    publication,
    showCompanyData,
    isLoading,
  );

  const applyButton = (
    <Link to={`${publication?.slug}/application-process${location.search}`}>
      <ApplyButton
        icon={briefcaseIcon}
        iconColor={colors.white}
        isIconLeft
        variant="secondary"
        text={dictionary.publication.apply}
      />
    </Link>
  );

  const descriptionSkeleton = (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton width="70%" />
    </>
  );

  const isBackButtonVisible = isVisibleGoBackButton(publication);

  return (
    <ThemeProvider theme={pageColors}>
      <Container>
        <HeaderBackgroundContainer is-back-button-visible={isBackButtonVisible}>
          {isBackButtonVisible && (
            <a href={publication?.url_return}>
              <GoBackButton
                isIconLeft
                icon={arrowLeftIcon}
                iconColor={pageColors.textColor}
                iconSize={24}
                variant="terciary"
                text={dictionary.publication.goBackToOffers}
              />
            </a>
          )}
          {positionSummaryHeader}
        </HeaderBackgroundContainer>
        <CardsContainer>
          <Card data-testid="publication-descriptions">
            <StyledTitleSubtitleLineHeader
              title={dictionary.publication.jobDescription}
            >
              <DescriptionText variant="style19">
                {isLoading ? (
                  descriptionSkeleton
                ) : (
                  <PurifiedElement html={publication?.description} />
                )}
              </DescriptionText>
            </StyledTitleSubtitleLineHeader>
            <StyledTitleSubtitleLineHeader
              title={dictionary.publication.requirements}
            >
              <DescriptionText variant="style19">
                {isLoading ? (
                  descriptionSkeleton
                ) : (
                  <PurifiedElement html={publication?.requirements} />
                )}
              </DescriptionText>
            </StyledTitleSubtitleLineHeader>
            {showCompanyData && (
              <StyledTitleSubtitleLineHeader
                title={dictionary.publication.companyDescription}
              >
                <DescriptionText variant="style19">
                  {publication?.enterprise?.description}
                </DescriptionText>
              </StyledTitleSubtitleLineHeader>
            )}
            {!!publication?.benefits.length && (
              <StyledTitleSubtitleLineHeader
                title={dictionary.publication.benefits}
              >
                <BenefitsContainer data-testid="benefits">
                  {publication.benefits.map((benefit) => (
                    <Benefit
                      key={benefit.id}
                      text={benefit.name}
                      iconColor={colors.veryLightBlueGrey}
                      iconSrc={benefit.icon_url}
                      textClassName="text"
                      textVariant="style19"
                    />
                  ))}
                </BenefitsContainer>
              </StyledTitleSubtitleLineHeader>
            )}
          </Card>
          <Card data-testid="publication-summary">
            <Text variant="heading6">{dictionary.publication.jobSummary}</Text>
            <JobSummaryList>
              {jobSummary.map((entry) => (
                <Fragment key={entry.id}>
                  {isLoading ? (
                    <Skeleton height={24} width={240} />
                  ) : (
                    <JobSummaryIconText
                      text={entry.text}
                      iconColor={colors.veryLightBlueGrey}
                      iconSrc={entry.icon}
                      textClassName="text"
                      textVariant="style19"
                    />
                  )}
                </Fragment>
              ))}
            </JobSummaryList>
            {!isLoading && applyButton}
          </Card>
        </CardsContainer>
        <Slide appear={false} direction="down" in={trigger}>
          <HeaderContainer>
            {positionSummaryHeader}
            {applyButton}
          </HeaderContainer>
        </Slide>
      </Container>
    </ThemeProvider>
  );
};

export default PublicationTemplate;
