import { AxiosError } from 'axios';
import apiRoutes from 'conf/api-routes';
import { COMPANY_DEPARTMENTS } from 'conf/query-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { IDepartment } from 'types/misc';
import { apiFetcher } from 'utils/fetch';

export const useCompanyDepartments = (
  companyId: string | undefined,
  queryOptions = {},
): UseQueryResult<IDepartment[], AxiosError> => {
  return useQuery(
    [COMPANY_DEPARTMENTS, companyId],
    () =>
      apiFetcher({
        apiPath: apiRoutes.companyDepartments,
        urlParams: { companyId },
        withSubdomain: true,
      }).then((response) => response.data),
    {
      enabled: !!companyId,
      ...queryOptions,
    },
  );
};

export default useCompanyDepartments;
