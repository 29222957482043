import checkIcon from '@rexmas/rexmas-shared/images/check-green.svg';
import closeCircleIcon from '@rexmas/rexmas-shared/images/close-circle.svg';
import downloadIcon from '@rexmas/rexmas-shared/images/download-light-blue.svg';
import InfoIcon from '@rexmas/rexmas-shared/images/info-light-blue.svg';
import plusIcon from '@rexmas/rexmas-shared/images/plus-highlight.svg';
import {
  IconButton,
  RoundedButton,
} from '@rexmas/rexmas-shared/lib/components/atoms/buttons';
import { Avatar, Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import {
  CircularProgress,
  Skeleton,
} from '@rexmas/rexmas-shared/lib/components/atoms/misc';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import RoundedIconButton from '@rexmas/rexmas-shared/lib/components/molecules/buttons/RoundedIconButton';
import TextAreaFieldInput from '@rexmas/rexmas-shared/lib/components/molecules/forms/inputs/TextAreaFieldInput';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { elevations } from '@rexmas/rexmas-shared/lib/conf/constants/elevations';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import ChatHeader from 'components/organisms/chat/ChatHeader';
import { getMessageDate } from 'conf/chat';
import useDictionary from 'hooks/useDictionary';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { IChat, ISendMessageFormValues, MessageSenderType } from 'types/IChat';
import { formatBytesSize } from 'utils/utils';

import AddFileModal from '../organisms/chat/AddFileModal';

const Container = styled.div`
  min-height: 100vh;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 0 60px 60px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -232px;
  max-width: 922px;
  min-height: 450px;
  width: 100%;
  padding: 24px 32px;
  border-radius: 15px;
  box-shadow: ${elevations.elevation1};
  background-color: ${colors.white};
`;

const CardHeaderContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
`;

const ApplicantHeaderContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 50%;
  padding: 0 24px 0 0;
`;

const StyledAvatar = styled(Avatar)`
  width: 42px;
  height: 42px;
`;

const UserHeaderContainer = styled(ApplicantHeaderContainer)`
  border-left: 1px solid ${colors.veryLightBlueGrey};
  justify-content: flex-end;
  padding: 0 0 0 24px;
`;

const UserMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  white-space: pre-line;
`;

const UserNameText = styled(Text)`
  font-size: 16px;
  color: ${(props) => props.color as string};
`;

const NameSubText = styled(Text)`
  color: ${colors.dash};
`;

const DateText = styled(Text)`
  color: ${colors.textLightGrey};
  margin-left: 8px;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-top: 1px solid ${colors.veryLightBlueGrey};
  border-bottom: 1px solid ${colors.veryLightBlueGrey};
  padding: 24px 0;
  min-height: 300px;
`;

const MessageContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

const StyledTextArea = styled(TextAreaFieldInput)`
  .MuiInputBase-root {
    background-color: ${colors.aliceBlue};
    border-radius: 16px;
    border: none;
  }
  && fieldset {
    border-color: ${colors.transparent};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 12px;
`;

const AddFileButton = styled(RoundedIconButton)`
  width: auto;
  max-width: 1000px;
  min-width: 0;
  color: ${colors.highlighted};
  background-color: ${colors.transparent};
  padding: 0 20px 0 12px;
  margin-left: -16px;
  :hover {
    color: ${colors.highlighted};
    background-color: ${colors.whiteTwo};
  }
  > div {
    font-size: 14px;
  }
`;

const SubmitButton = styled(RoundedButton)`
  min-width: 128px;
  height: auto;
  border-radius: 32px;
  padding: 6px 8px;
  color: ${(props) => (props.disabled ? colors.textLightGrey : colors.white)};
  letter-spacing: normal;
  .MuiCircularProgress-root {
    color: ${colors.textLightGrey};
    ${(props) => props.isLoading && 'margin: 2px 0;'}
  }
`;

const AttachedFileContainer = styled.div`
  margin-top: 16px;
`;

const AttachedFile = styled.div`
  display: flex;
  padding: 8px 16px;
  border: dashed 1px ${colors.dash};
  border-radius: 15px;
  width: 260px;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const FilenameSizeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Filename = styled(Text)`
  max-width: 150px;
  word-wrap: break-word;
`;

const FinalizedCard = styled(Card)`
  align-items: center;
  max-width: 600px;
  text-align: center;
  padding: 24px 24px 64px;
  margin-top: -180px;
  min-height: 0;
`;

const CardTitle = styled(Text)`
  color: ${colors.cobalt};
  margin: 12px 0 28px;
`;

const CircularProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const AvatarSkeleton = styled(Skeleton)`
  flex: 1 0 auto;
`;

interface Props {
  isLoading: boolean;
  isSubmittingMessage: boolean;
  isAddFileModalOpen: boolean;
  isSelectionProcessFinalized: boolean;
  chat: IChat | undefined;
  formMethods: UseFormReturn<ISendMessageFormValues>;
  setIsAddFileModalOpen: (open: boolean) => void;
  onSendMessage: () => void;
}

const ChatTemplate: React.FunctionComponent<Props> = ({
  isLoading,
  isSubmittingMessage,
  isAddFileModalOpen,
  isSelectionProcessFinalized,
  chat,
  formMethods,
  setIsAddFileModalOpen,
  onSendMessage,
}) => {
  const dictionary = useDictionary();
  const { body, file: attachedFile } = formMethods.watch();
  const isFormValid = body || attachedFile;

  if (isLoading) {
    return (
      <Container>
        <ChatHeader isLoading />
        <CardContainer>
          <Card>
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          </Card>
        </CardContainer>
      </Container>
    );
  }

  if (isSelectionProcessFinalized) {
    return (
      <Container>
        <ChatHeader selectionProcessName="-- --" />
        <CardContainer>
          <FinalizedCard>
            <Icon src={InfoIcon} size={36} />
            <CardTitle variant="heading6">
              {dictionary.chat.selectionProcessFinalized}
            </CardTitle>
            <Text>{dictionary.chat.selectionProcessFinalizedParagraph1}</Text>
            <Text>{dictionary.chat.selectionProcessFinalizedParagraph2}</Text>
          </FinalizedCard>
        </CardContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ChatHeader selectionProcessName={chat?.selection_process_name} />
      <CardContainer>
        <Card>
          <CardHeaderContainer>
            <ApplicantHeaderContainer>
              <StyledAvatar src={chat?.postulant_avatar || ''} />
              <UserMessageContainer>
                <UserNameText variant="style5" color={colors.darkGradientBlue}>
                  {chat?.postulant_full_name}
                </UserNameText>
                <NameSubText variant="style19">
                  {replaceStringVariables(dictionary.chat.selectionProcess, {
                    selectionProcess: chat?.selection_process_name,
                  })}
                </NameSubText>
              </UserMessageContainer>
            </ApplicantHeaderContainer>
            <UserHeaderContainer>
              <StyledAvatar src={chat?.user_avatar || ''} />
              <UserMessageContainer>
                <UserNameText variant="style5" color={colors.dash}>
                  {chat?.user_full_name}
                </UserNameText>
                <NameSubText variant="style19">
                  {dictionary.chat.selectionProcessManager}
                </NameSubText>
              </UserMessageContainer>
            </UserHeaderContainer>
          </CardHeaderContainer>
          <MessagesContainer>
            {chat?.messages.map((message) => (
              <MessageContainer key={message.id}>
                <StyledAvatar
                  src={
                    message.sender_type === MessageSenderType.USER
                      ? chat?.user_avatar || ''
                      : chat.postulant_avatar || ''
                  }
                />
                <MessageContainer>
                  <UserMessageContainer>
                    <UserNameText
                      variant="style5"
                      color={
                        message.sender_type === MessageSenderType.USER
                          ? colors.dash
                          : colors.darkGradientBlue
                      }
                    >
                      {message.sender_name}
                      <DateText variant="style29">
                        {getMessageDate(message.created_date)}
                      </DateText>
                    </UserNameText>
                    <Text variant="style19">{message.body}</Text>
                    {message.filename && (
                      <AttachedFile>
                        <FilenameSizeContainer>
                          <Filename>{message.filename}</Filename>
                          <Text variant="style40">
                            {message.attachment_size &&
                              formatBytesSize(Number(message.attachment_size))}
                          </Text>
                        </FilenameSizeContainer>
                        <IconButton
                          disabled={!message.attachment}
                          onClick={() => window.open(message.attachment)}
                        >
                          <Icon src={downloadIcon} />
                        </IconButton>
                      </AttachedFile>
                    )}
                  </UserMessageContainer>
                </MessageContainer>
              </MessageContainer>
            ))}
            {isSubmittingMessage && (
              <MessageContainer>
                <AvatarSkeleton variant="circular" width={42} height={42} />
                <MessageContainer>
                  <UserMessageContainer>
                    <Skeleton width={100} />
                    <Skeleton height={30} />
                  </UserMessageContainer>
                </MessageContainer>
              </MessageContainer>
            )}
          </MessagesContainer>
          {attachedFile && (
            <AttachedFileContainer>
              <AttachedFile>
                <Icon src={checkIcon} />
                <FilenameSizeContainer>
                  <Filename>{attachedFile.name}</Filename>
                  <Text variant="style40">
                    {formatBytesSize(attachedFile.size)}
                  </Text>
                </FilenameSizeContainer>
                <IconButton
                  onClick={() => formMethods.setValue('file', undefined)}
                >
                  <Icon src={closeCircleIcon} />
                </IconButton>
              </AttachedFile>
            </AttachedFileContainer>
          )}
          <StyledTextArea
            name="body"
            placeholder={dictionary.chat.sendMessagePlaceholder}
            formMethods={formMethods}
            maxCount={2000}
            showCharacterCount={false}
          />
          <ButtonsContainer>
            <AddFileButton
              variant="secondary"
              text={dictionary.chat.addFile}
              icon={plusIcon}
              iconColor={colors.highlighted}
              iconSize={24}
              isIconLeft
              onClick={() => setIsAddFileModalOpen(true)}
            />
            <SubmitButton
              variant="secondary"
              onClick={onSendMessage}
              isLoading={isSubmittingMessage}
              disabled={isSubmittingMessage || !isFormValid}
            >
              {dictionary.chat.sendMessage}
            </SubmitButton>
          </ButtonsContainer>
        </Card>
      </CardContainer>
      <AddFileModal
        open={isAddFileModalOpen}
        onAddFile={(file) => formMethods.setValue('file', file)}
        onClose={() => setIsAddFileModalOpen(false)}
      />
    </Container>
  );
};

export default ChatTemplate;
