import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import { PublicationTemplate } from 'components/templates';
import Component404 from 'components/templates/404';
import dictionary from 'conf/dictionaries/es_CL';
import { usePublication } from 'queries/publication';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getIs400Error, getIsClientError } from 'utils/fetch';
import { getIdFromPublicationSlug } from 'utils/utils';

import Page404 from './404';

interface Params {
  publicationSlug: string;
}

const Publication: React.FunctionComponent = () => {
  const { publicationSlug } = useParams<Params>();
  const id = getIdFromPublicationSlug(publicationSlug);
  const { data: publication, isLoading, isError, error } = usePublication(id);
  const publicationTitle = publication?.title;

  useEffect(() => {
    if (publicationTitle) {
      document.title = replaceStringVariables(
        dictionary.publication.documentTitle,
        { publicationTitle },
      );
    }
  }, [publicationTitle]);

  if (getIs400Error(isError, error)) {
    return (
      <Component404 secondSentence={dictionary.notAvailable.secondSentence} />
    );
  }

  if (getIsClientError(isError, error)) {
    return <Page404 />;
  }

  return (
    <PublicationTemplate
      isPublicationLoading={isLoading}
      publication={publication}
    />
  );
};

export default Publication;
