import arrowLeftIcon from '@rexmas/rexmas-shared/images/arrow-left-dash.svg';
import checkIcon from '@rexmas/rexmas-shared/images/check-circle-green.svg';
import infoIcon from '@rexmas/rexmas-shared/images/info.svg';
import userIcon from '@rexmas/rexmas-shared/images/user-grey-blue.svg';
import { RoundedButton } from '@rexmas/rexmas-shared/lib/components/atoms/buttons';
import {
  ColorableIcon,
  Icon,
} from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { RoundedIconButton } from '@rexmas/rexmas-shared/lib/components/molecules/buttons';
import StepNumber from '@rexmas/rexmas-shared/lib/components/organisms/steps/StepNumber';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { elevations } from '@rexmas/rexmas-shared/lib/conf/constants/elevations';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import PostulantProfileField from 'components/molecules/postulant-profile/PostulantProfileField';
import PostulantProfileHeader from 'components/molecules/postulant-profile/PostulantProfileHeader';
import { getIsSectionFilled } from 'conf/postulantProfile';
import useDictionary from 'hooks/useDictionary';
import checkHighlightIcon from 'images/check-circle-highlight.svg';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled, { css } from 'styled-components';
import IConstant from 'types/IConstant';
import {
  IPostulantProfile,
  IPostulantProfileField,
  IPostulantProfileFormData,
  PostulantProfileState,
} from 'types/IPostulantProfile';
import { StepNumberTheme } from 'types/misc';

const Container = styled.div`
  min-height: 100vh;
  background-color: ${colors.aliceBlue};
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 0 60px 60px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 24px 24px;
  border-radius: 15px;
  box-shadow: ${elevations.elevation1};
  background-color: ${colors.white};
  margin: -224px 24px 0px;
`;

const CardTitle = styled(Text)`
  color: ${colors.cobalt};
  margin: 12px 0 28px;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 24px 0;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GoBackButton = styled(RoundedIconButton)`
  width: auto;
  padding: 8px 16px 8px 8px;
  background-color: ${colors.transparent};
  > div {
    font-size: 14px;
    margin: 0;
  }
  &:hover {
    color: ${colors.cobalt};
    background-color: ${colors.transparent};
  }
  @media screen and (min-width: ${mediaQueries.sm}px) {
    margin-right: auto;
  }
`;

const StyledRoundedButton = styled(RoundedButton)`
  min-width: 158px;
  height: auto;
  border-radius: 32px;
  padding: 6px 16px;
  margin: 0 auto 0;
  color: ${(props) => (props.disabled ? colors.textLightGrey : colors.white)};
  letter-spacing: normal;
`;

const SubmitButton = styled(StyledRoundedButton)`
  min-width: 180px;
  &:hover {
    background-color: ${colors.darkGreen};
  }
  color: ${colors.cobalt};
  .MuiCircularProgress-root {
    color: ${colors.textLightGrey};
    ${(props) => props.isLoading && 'margin: 2px 0;'}
  }
`;

const PostulantProfileCard = styled(Card)`
  padding: 28px 28px 32px;
  max-width: 1250px;
`;

const InformationCard = styled(Card)`
  padding: 32px 24px;
  max-width: 468px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 32px 58px;
  }
`;

const CardIcon = styled(Icon)`
  margin: 0 auto;
`;

const DashText = styled(Text)`
  color: ${colors.dash};
  margin: 16px 0 58px;
  line-height: 22px;
`;

const FormHeaderText = styled(Text)`
  margin: 0 auto;
`;

const SendSuccessText = styled(Text)`
  margin: 12px 0;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
  padding: 0 32px 0 48px;
  margin: 12px auto;
`;

const activeStep = css`
  color: ${colors.whiteTwo};
  border-color: ${colors.highlighted};
  background-color: ${colors.highlighted};
`;

const activeStepGreen = css`
  color: ${colors.whiteTwo};
  border-color: ${colors.green};
  background-color: ${colors.green};
`;

const StyledStepNumber = styled(StepNumber)`
  .StepNumber-Number {
    font-size: 22px;
    font-weight: normal;
    color: ${colors.cobalt};
    background-color: ${colors.white};
    border: 1px solid ${colors.cobalt};
    min-height: 48px;
    min-width: 48px;
    ${(props) => props.step.number <= props['current-step'] && activeStep};
    ${(props) =>
      props.step.number <= props['current-step'] &&
      props.step.isLast &&
      activeStepGreen};
  }
  .StepNumber-Line {
    opacity: 1;
    height: 1px;
    background-color: ${colors.veryLightBlueGrey};
  }
  .StepNumber-TitleContainer {
    display: none;
  }
`;

const StepsTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin: 0 auto 16px;
  max-width: 488px;
  text-align: center;
`;

const StepText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.dash};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
`;

const SectionsContainer = styled.div`
  padding: 16px 0;
  @media screen and (min-width: ${mediaQueries.md}px) {
    padding: 24px 16px;
  }
  @media screen and (min-width: ${mediaQueries.xl}px) {
    padding: 24px 120px 32px 80px;
  }
`;

const SectionContainer = styled.div`
  padding: 28px 0;
`;

const SectionName = styled.div<{ step: number }>`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  gap: 16px;
  flex-wrap: wrap;
  color: ${(props) => (props.step === 1 ? colors.dash : colors.textDarkGrey)};
`;

const RequiredFieldsText = styled(Text)`
  color: ${colors.error};
  margin-left: auto;
  @media screen and (max-width: ${mediaQueries.sm}px) {
    order: -1;
    flex: 1 0 100%;
  }
`;

const FieldsContainer = styled.div`
  display: grid;
  row-gap: 32px;
  column-gap: 24px;
  grid-template-columns: 1fr;
  padding: 12px 0 0 0;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: ${mediaQueries.md}px) {
    padding: 16px 0 0 32px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: ${mediaQueries.xl}px) {
    padding: 20px 0 0 64px;
  }
  .MuiInputBase-input {
    padding: 6px 0;
  }
  [class*='MuiInputLabel-root'] {
    white-space: nowrap;
  }
  [class*='OutlinedInput'],
  [class*='AutoComplete__Container'],
  [class*='DatePicker__Container'] {
    height: auto;
    overflow: hidden;
  }
  [class*='MuiFormHelperText-root']:not(.Mui-error) {
    color: ${colors.textLightGrey};
  }
`;

interface Props {
  isLoading: boolean;
  isSubmitting: boolean;
  formStep: number;
  postulantProfile: IPostulantProfile | undefined;
  formMethods: UseFormReturn<IPostulantProfileFormData>;
  onGoToFirstStep: () => void;
  onGoToSecondStep: () => void;
  onSubmit: () => void;
}

const PostulantProfileTemplate: React.FunctionComponent<Props> = ({
  isLoading,
  isSubmitting,
  formStep,
  postulantProfile,
  formMethods,
  onGoToFirstStep,
  onGoToSecondStep,
  onSubmit,
}) => {
  const dictionary = useDictionary();
  const [showIntroductionMessage, setShowIntroductionMessage] = useState(true);

  const fieldsWatch = formMethods.watch();

  const getFieldByPayrollIdentifier = (payrollIdentifier: string) =>
    postulantProfile?.sections
      .map((section) =>
        section.fields
          .filter(
            (sectionField) =>
              sectionField.payroll_identifier === payrollIdentifier,
          )
          .flat(),
      )
      .flat()
      .find(Boolean);

  const onRegionChange = (option: IConstant) => {
    const cityField = getFieldByPayrollIdentifier('ciudad');
    const communeField = getFieldByPayrollIdentifier('comuna');
    if (cityField) {
      formMethods.setValue(`${cityField.id}`, null);
    }
    if (communeField) {
      formMethods.setValue(`${communeField.id}`, null);
    }
  };

  const getChoices = (field: IPostulantProfileField) => {
    const regionField = getFieldByPayrollIdentifier('region');
    if (regionField) {
      const regionSelected = fieldsWatch[`${regionField.id}`] as IConstant;
      if (
        ['ciudad', 'comuna'].includes(field.payroll_identifier) &&
        regionSelected
      ) {
        const payrollField = getFieldByPayrollIdentifier(
          field.payroll_identifier,
        );
        if (payrollField) {
          return payrollField.choices.filter((choice) =>
            choice.value.startsWith(regionSelected.value),
          );
        }
      }
    }
    return field.choices;
  };

  if (postulantProfile?.state === PostulantProfileState.COMPLETED) {
    return (
      <Container>
        <PostulantProfileHeader
          isLoading={isLoading}
          postulantProfile={postulantProfile}
        />
        <CardContainer>
          <InformationCard>
            <CardIcon src={checkIcon} size={36} />
            <CardTitle variant="heading6">
              {dictionary.postulantProfile.sendSuccess}
            </CardTitle>
            <SendSuccessText>
              {dictionary.postulantProfile.sendSuccessDescription}
            </SendSuccessText>
          </InformationCard>
        </CardContainer>
      </Container>
    );
  }

  if (showIntroductionMessage) {
    return (
      <Container>
        <PostulantProfileHeader
          isLoading={isLoading}
          postulantProfile={postulantProfile}
        />
        <CardContainer>
          <InformationCard>
            <CardIcon src={userIcon} size={36} />
            <CardTitle variant="heading6">
              {dictionary.postulantProfile.beforeBeginning}
            </CardTitle>
            <Text variant="style36">
              {dictionary.postulantProfile.considerTheFollowing}
            </Text>
            <DashText variant="style18">
              {dictionary.postulantProfile.rememberToFillInAllFields}
            </DashText>
            <StyledRoundedButton
              variant="secondary"
              disabled={isLoading}
              onClick={() => setShowIntroductionMessage(false)}
            >
              {dictionary.postulantProfile.filOutTheForm}
            </StyledRoundedButton>
          </InformationCard>
        </CardContainer>
      </Container>
    );
  }

  return (
    <Container>
      <PostulantProfileHeader
        isLoading={isLoading}
        postulantProfile={postulantProfile}
      />
      <CardContainer>
        <PostulantProfileCard>
          <FormHeaderText variant="heading6">
            {formStep === 1
              ? dictionary.postulantProfile.step1Message
              : dictionary.postulantProfile.step2Message}
          </FormHeaderText>
          <SectionsContainer>
            <StepsContainer>
              <StyledStepNumber
                step={{
                  number: 1,
                  isLast: false,
                  variant: StepNumberTheme.DOING,
                  ...(formStep > 1 && { icon: checkHighlightIcon }),
                }}
                current-step={formStep}
              />
              <StyledStepNumber
                step={{
                  number: 2,
                  variant: StepNumberTheme.DOING,
                  isLast: true,
                }}
                current-step={formStep}
              />
            </StepsContainer>
            <StepsTextContainer>
              <StepText active={formStep === 1} variant="style19">
                {dictionary.postulantProfile.fillInInformation}
              </StepText>
              <StepText active={formStep === 2} variant="style19">
                {dictionary.postulantProfile.confirmAndSubmit}
              </StepText>
            </StepsTextContainer>
            {postulantProfile?.sections.map((section, index) => (
              <SectionContainer key={section.id}>
                <SectionName step={formStep}>
                  {formStep === 1 &&
                    (getIsSectionFilled(section, formMethods) ? (
                      <ColorableIcon src={checkIcon} color={colors.darkGreen} />
                    ) : (
                      <ColorableIcon
                        src={infoIcon}
                        color={colors.mustardAlert}
                      />
                    ))}
                  {section.name}
                  {index === 0 && formStep === 1 && (
                    <RequiredFieldsText>
                      {dictionary.postulantProfile.requiredFields}
                    </RequiredFieldsText>
                  )}
                </SectionName>
                <FieldsContainer>
                  {section.fields.map((field) => (
                    <PostulantProfileField
                      key={field.id}
                      disabled={formStep === 2}
                      field={field}
                      formMethods={formMethods}
                      onChange={
                        field.payroll_identifier === 'region'
                          ? onRegionChange
                          : undefined
                      }
                      choices={getChoices(field)}
                    />
                  ))}
                </FieldsContainer>
              </SectionContainer>
            ))}
          </SectionsContainer>
          {formStep === 1 && (
            <StyledRoundedButton variant="secondary" onClick={onGoToSecondStep}>
              {dictionary.postulantProfile.confirmAndSubmitInformation}
            </StyledRoundedButton>
          )}
          {formStep === 2 && (
            <ButtonsContainer>
              <GoBackButton
                isIconLeft
                icon={arrowLeftIcon}
                iconColor={colors.cobalt}
                iconSize={24}
                variant="terciary"
                text={dictionary.postulantProfile.goBack}
                disabled={isSubmitting}
                onClick={onGoToFirstStep}
              />
              <SubmitButton
                onClick={onSubmit}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {dictionary.postulantProfile.submitInformation}
              </SubmitButton>
            </ButtonsContainer>
          )}
        </PostulantProfileCard>
      </CardContainer>
    </Container>
  );
};

export default PostulantProfileTemplate;
