import IConstant from './IConstant';

export interface IOfferLetter {
  uuid: string;
  applicant_first_name: string;
  applicant_last_name: string;
  applicant_email: string;
  process_name: string;
  company: string;
  company_logo: string;
  support_email: string;
  tenant_id: number;
  application_id: number;
  offer_letter_file: string;
  state: OfferLetterState;
}

export interface IOfferLetterFormData {
  state?: IConstant;
  message?: string;
}

export enum OfferLetterState {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum OfferLetterResponseOption {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}
