import { AxiosError } from 'axios';
import apiRoutes from 'conf/api-routes';
import { CHAT } from 'conf/query-keys';
import { useQuery, UseQueryResult } from 'react-query';
import { IChat } from 'types/IChat';
import { apiFetcher } from 'utils/fetch';

export const useChat = (
  id: string,
  queryOptions = {},
): UseQueryResult<IChat, AxiosError> => {
  return useQuery(
    [CHAT, id],
    () =>
      apiFetcher({
        apiPath: apiRoutes.chat,
        urlParams: { id },
      }).then((response) => response.data),
    {
      staleTime: Infinity,
      retry: false,
      ...queryOptions,
    },
  );
};

export default useChat;
