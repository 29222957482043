import { InputBaseProps } from '@mui/material/InputBase';
import { OutlinedInput } from '@rexmas/rexmas-shared/lib/components/atoms/inputs';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';

const TextField = styled(OutlinedInput)`
  .MuiFormLabel-root {
    color: ${colors.disabledGrey};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${colors.highlighted};
  }
  .MuiInput-underline.Mui-focused {
    &:after {
      border-color: ${colors.highlighted};
    }
  }
  .MuiInput-underline {
    &:hover:before {
      border-color: ${colors.dash};
    }
    &:before {
      border-color: ${colors.grey};
    }
  }
  .MuiInput-underline.Mui-disabled {
    &:before {
      border-bottom-style: solid;
      border-color: ${colors.grey};
    }
  }
  .MuiInput-underline.Mui-error {
    &:before {
      border-color: ${colors.red};
      border-width: 2px;
    }
  }
`;

interface Props {
  name: string;
  label: string;
  helperText?: string;
  disabled?: boolean;
  className?: string;
  InputProps?: InputBaseProps;
  inputProps?: any;
  rules?: RegisterOptions;
  control: Control;
  defaultValue?: string;
}

const TextFieldInput: React.FunctionComponent<Props> = ({
  name,
  label,
  helperText,
  disabled,
  className,
  inputProps,
  InputProps,
  rules,
  control,
  defaultValue = '',
}) => {
  return (
    <Controller
      name={`${name}` as const}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { invalid, error },
      }) => (
        <TextField
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          disabled={disabled}
          error={invalid}
          helperText={invalid ? error?.message : helperText}
          variant="standard"
          className={className}
          inputProps={inputProps}
          InputProps={InputProps}
          inputRef={ref}
        />
      )}
    />
  );
};

export default TextFieldInput;
