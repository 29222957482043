import {
  ChatPage,
  JobOffersPage,
  OfferLetterPage,
  Page404,
  PostulantProfilePage,
  PublicationPage,
} from 'components/pages';
import ApplicationSteps from 'components/pages/ApplicationSteps';
import urls from 'conf/navigation';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const Routes: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route path={urls.publication} exact>
        <PublicationPage />
      </Route>
      <Route path={urls.applicationSteps} exact>
        <ApplicationSteps />
      </Route>
      <Route path={urls.jobOffers} exact>
        <JobOffersPage />
      </Route>
      <Route path={urls.offerLetter} exact>
        <OfferLetterPage />
      </Route>
      <Route path={urls.postulantProfile} exact>
        <PostulantProfilePage />
      </Route>
      <Route path={urls.chat} exact>
        <ChatPage />
      </Route>
      <Route>
        <Page404 />
      </Route>
    </Switch>
  </Router>
);

export default Routes;
