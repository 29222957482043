export const PUBLICATION = 'Publication';
export const PUBLICATIONTYPE = 'PublicationType';
export const COMPANY_PUBLICATIONS = 'CompanyPublications';
export const COMPANY_DEPARTMENTS = 'CompanyDepartments';
export const COMPANIES = 'Companies';
export const CONTRACT_TYPES = 'ContractTypes';
export const NATIONALITIES = 'Nationalities';
export const OFFER_LETTER = 'OfferLetter';
export const POSTULANT_PROFILE = 'PostulantProfile';
export const CHAT = 'Chat';
