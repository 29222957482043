import arrowLeftIcon from '@rexmas/rexmas-shared/images/arrow-left-dash.svg';
import arrowRightIcon from '@rexmas/rexmas-shared/images/arrow-right-blue.svg';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { RoundedIconButton } from '@rexmas/rexmas-shared/lib/components/molecules/buttons';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import FileInput from 'components/molecules/inputs/FileInput';
import FreeTextQuestion from 'components/molecules/inputs/questions/FreeText';
import RangeQuestion from 'components/molecules/inputs/questions/Range';
import SelectMultipleQuestion from 'components/molecules/inputs/questions/SelectMultiple';
import {
  FILE_REQUEST_FILE_EXTENSIONS,
  FILE_REQUEST_MAX_FILE_SIZE_B,
  FILE_REQUEST_MAX_FILE_SIZE_MB,
  getInvalidFileErrorMessage,
} from 'conf/applicantData';
import dictionary from 'conf/dictionaries/es_CL';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import IFileRequest from 'types/IFileRequest';
import { IProcessQuestion, QuestionType } from 'types/IQuestion';

import PostulationFormBase from '../ApplicationStepsBase';

const SubTitleText = styled(Text)`
  display: block;
  margin-top: 16px;
`;

const PositionName = styled(Text)`
  color: ${colors.cobalt};
  font-size: inherit;
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin: 40px 0;
`;

const Question = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledFileInput = styled(FileInput)`
  max-width: 360px;
  margin-top: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
`;

const ApplyButton = styled(RoundedIconButton)`
  width: auto;
  height: 42px;
  padding: 0 12px 0 20px;
  margin-bottom: 24px;
  min-width: 144px;
  > div {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &.Mui-disabled .MuiCircularProgress-svg {
    color: ${colors.cobalt};
  }
`;

const GoBackButton = styled(ApplyButton)`
  border: none;
  padding: 0 20px 0 12px;
  &:hover {
    color: ${colors.cobalt};
    background-color: ${colors.transparent};
  }
`;

interface Props {
  isSubmittingApplication: boolean;
  positionName: string;
  questions: IProcessQuestion[];
  fileRequests: IFileRequest[];
  formMethods: UseFormReturn;
  onGoBack: () => void;
  onSubmitApplication: () => void;
}

const Questions: React.FunctionComponent<Props> = ({
  isSubmittingApplication,
  positionName,
  questions,
  fileRequests,
  formMethods,
  onGoBack,
  onSubmitApplication,
}) => {
  const title = (
    <>
      {dictionary.applicantData.youAreApplyingTo}
      <PositionName variant="style23"> {positionName}</PositionName>
    </>
  );

  return (
    <PostulationFormBase
      currentStep={2}
      header={dictionary.questions.applicationForm}
      title={title}
    >
      <SubTitleText variant="style19">
        {dictionary.questions.subTitle}
      </SubTitleText>
      <QuestionsContainer>
        {questions.map(({ id, question }: IProcessQuestion, index: number) => (
          <Question key={id} data-testid={`question-${id}`}>
            <Text>
              {index + 1}.- {question.content}
            </Text>
            {question.question_type === QuestionType.FREE_TEXT && (
              <FreeTextQuestion
                name={id}
                maxLength={200}
                placeholder={dictionary.questions.freeTextPlaceholder}
                formMethods={formMethods}
              />
            )}
            {question.question_type === QuestionType.RANGE && (
              <RangeQuestion
                name={id}
                question={question}
                formMethods={formMethods}
              />
            )}
            {question.question_type === QuestionType.SELECT_MULTIPLE && (
              <SelectMultipleQuestion
                name={id}
                question={question}
                formMethods={formMethods}
              />
            )}
          </Question>
        ))}
        {fileRequests.map(({ id, name }, index: number) => (
          <Question key={id} data-testid={`file-${id}`}>
            <Text>
              {questions.length + index + 1}.- {name}
            </Text>
            <StyledFileInput
              name={`file-${id}`}
              placeholder={dictionary.questions.attachHere}
              maxSize={FILE_REQUEST_MAX_FILE_SIZE_B}
              accept={FILE_REQUEST_FILE_EXTENSIONS}
              formMethods={formMethods}
              rules={{
                required: dictionary.general.fileIsRequired,
                validate: {
                  validFile: (file) =>
                    getInvalidFileErrorMessage(file, {
                      maxFileSize: FILE_REQUEST_MAX_FILE_SIZE_MB,
                      allowedExtensions: FILE_REQUEST_FILE_EXTENSIONS,
                    }),
                },
              }}
            />
          </Question>
        ))}
      </QuestionsContainer>
      <ButtonsContainer>
        <GoBackButton
          isIconLeft
          icon={arrowLeftIcon}
          variant="terciary"
          text={dictionary.applicationStepsBase.goBack}
          data-testid="go-back-button"
          onClick={onGoBack}
        />
        <ApplyButton
          isLoading={isSubmittingApplication}
          disabled={isSubmittingApplication}
          icon={arrowRightIcon}
          iconColor={colors.dash}
          variant="terciary"
          text={dictionary.applicationStepsBase.apply}
          data-testid="continue-button"
          onClick={onSubmitApplication}
        />
      </ButtonsContainer>
    </PostulationFormBase>
  );
};

export default Questions;
