export enum QuestionType {
  FREE_TEXT = 'FREE_TEXT',
  RANGE = 'RANGE',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
}

export interface IProcessQuestion {
  id: number;
  order: number;
  is_active: boolean;
  question: IQuestion;
}

export interface IQuestion {
  question_type: QuestionType;
  content: string;
  range?: {
    lower: number;
    upper: number;
  };
  choices?: IChoice[];
}

export interface IChoice {
  content: string;
}

export interface IAnswer {
  question_id: number;
  question_type: QuestionType;
  content?: string;
  options_selected?: IChoice[];
}
