import {
  hasSubDomain,
  replaceStringVariables,
} from '@rexmas/rexmas-shared/lib/utils/misc';
import axios, { AxiosError } from 'axios';
import { constants } from 'conf/constants';
import { IApiFetcherConfig } from 'types/misc';

export const apiFetcher = (requestParams: IApiFetcherConfig): Promise<any> => {
  const {
    data,
    method,
    apiPath,
    urlParams,
    params,
    responseType,
    withSubdomain,
  } = requestParams;
  const fullUrl = replaceStringVariables(apiPath, urlParams);
  const axiosInstance = getAxiosInstance(withSubdomain);

  return axiosInstance({
    url: fullUrl,
    method: method || 'get',
    data,
    params,
    responseType,
  });
};

export const getIs400Error = (
  isError: boolean,
  error: AxiosError | null,
): boolean => {
  return isError && error?.response?.status === 400;
};

export const getIsClientError = (
  isError: boolean,
  error: AxiosError | null,
): boolean => {
  if (isError) {
    const status = error?.response?.status;
    if (status === 400 || status === 404) {
      return true;
    }
  }
  return false;
};

const customHasSubDomain = (url: string) => {
  const regex = /^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.[\w-]+\.\w+)$/;
  return url.match(regex);
};

const getDomain = () => {
  const { domain, hasMultipleSubDomains } = constants;
  const subdomain = window.location.hostname.split('.')[0];
  const hasDomainFunction = hasMultipleSubDomains
    ? customHasSubDomain
    : hasSubDomain;
  const host = hasDomainFunction(domain)
    ? domain.split('.').slice(1).join('.')
    : domain;
  return `${subdomain}.${host}`;
};

const getAxiosInstance = (withSubdomain = false) => {
  const { domain: backendDomain, protocolUrl, apiVersion } = constants;
  const domain = withSubdomain ? getDomain() : backendDomain;
  const baseURL = `${protocolUrl}://${domain}/${apiVersion}`;
  return axios.create({
    baseURL,
  });
};
