import calendarIcon from '@rexmas/rexmas-shared/images/calendar-highlight.svg';
import { Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import DatePickerInput from '@rexmas/rexmas-shared/lib/components/molecules/forms/inputs/DatePickerInput';
import TextAreaFieldInput from '@rexmas/rexmas-shared/lib/components/molecules/forms/inputs/TextAreaFieldInput';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import moment from '@rexmas/rexmas-shared/lib/lib/moment';
import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import AutoCompleteInput from 'components/molecules/inputs/AutoCompleteInput';
import TextFieldInput from 'components/molecules/inputs/TextFieldInput';
import useDictionary from 'hooks/useDictionary';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import IConstant from 'types/IConstant';
import {
  IPostulantProfileField,
  IPostulantProfileFormData,
  PostulantProfileFieldAlphabet,
  PostulantProfileFieldType,
} from 'types/IPostulantProfile';
import { Moment } from 'types/misc';

import NumberFormatInput from '../inputs/NumberFormatInput';

const StyledTextFieldInput = styled(TextFieldInput)`
  .MuiFormLabel-root.Mui-disabled[data-shrink='false'] {
    color: ${colors.grey};
    opacity: 0.7;
  }
`;

const StyledDatePickerInput = styled(DatePickerInput)`
  && label {
    font-size: 12px;
  }
  && input {
    font-size: 16px;
    padding: 6px 0;
  }
  button {
    margin-right: -12px;
  }
  [class*='MuiInput-underline'] {
    &:before,
    &.Mui-disabled:before {
      border-color: ${colors.grey};
    }
  }
`;

const TextAreaContainer = styled.div`
  grid-column: 1 / -1;
  span {
    color: ${colors.textLightGrey};
  }
`;

const StyledTextAreaFieldInput = styled(TextAreaFieldInput)`
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
`;

interface Props {
  disabled: boolean;
  field: IPostulantProfileField;
  formMethods: UseFormReturn<IPostulantProfileFormData>;
  onChange?: (option: IConstant) => void;
  choices: IConstant[];
}

const PostulantProfileField: React.FunctionComponent<Props> = ({
  disabled,
  field,
  formMethods,
  onChange,
  choices,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const dictionary = useDictionary();
  const { required } = field.validation;
  const minLength = Number(field.validation.min_length);
  const maxLength = Number(field.validation.max_length);
  const label = `${field.name}${required ? ' *' : ''}`;
  const isRequired = required && dictionary.general.fieldIsRequired;

  if (field.field_type === PostulantProfileFieldType.CHAR_FIELD) {
    if (field.validation.alphabet === PostulantProfileFieldAlphabet.NUMERIC) {
      return (
        <NumberFormatInput
          name={String(field.id)}
          label={label}
          control={formMethods.control}
          maxLength={maxLength || 256}
          disabled={disabled}
          thousandSeparator=""
          helperText={field.helper_text}
          defaultValue={field.content ?? null}
          rules={{
            required: isRequired,
            minLength: {
              value: minLength,
              message: replaceStringVariables(
                dictionary.postulantProfile.minLengthMessage,
                { minLength },
              ),
            },
          }}
        />
      );
    }
    return (
      <StyledTextFieldInput
        name={String(field.id)}
        label={label}
        defaultValue={field.content ?? ''}
        control={formMethods.control}
        helperText={field.helper_text}
        disabled={disabled}
        inputProps={{
          maxLength: maxLength || 256,
        }}
        rules={{
          required: isRequired,
          minLength: {
            value: minLength,
            message: replaceStringVariables(
              dictionary.postulantProfile.minLengthMessage,
              { minLength },
            ),
          },
        }}
      />
    );
  }

  if (field.field_type === PostulantProfileFieldType.AUTOCOMPLETE_FIELD) {
    const defaultValue = field.choices.find(
      (choice) => choice.value === field.content,
    );
    return (
      <AutoCompleteInput
        name={String(field.id)}
        options={choices}
        onChangeAction={onChange}
        placeholder={label}
        disabled={disabled}
        helperText={field.helper_text}
        getOptionLabel={(option) => option?.name}
        defaultValue={defaultValue ?? null}
        formMethods={formMethods}
        rules={{
          required: isRequired,
        }}
      />
    );
  }

  if (field.field_type === PostulantProfileFieldType.DATE_FIELD) {
    return (
      <StyledDatePickerInput
        name={String(field.id)}
        label={label}
        defaultValue={
          (field.content && moment(field.content as string)) || null
        }
        disabled={disabled}
        helperText={field.helper_text}
        control={formMethods.control}
        keyboardIcon={<Icon src={calendarIcon} />}
        rules={{
          required: isRequired,
          validate: (date: Moment | null) =>
            !date ||
            date.isValid() ||
            dictionary.postulantProfile.dateFormatError,
        }}
      />
    );
  }

  if (field.field_type === PostulantProfileFieldType.TEXT_FIELD) {
    return (
      <TextAreaContainer>
        <StyledTextAreaFieldInput
          name={String(field.id)}
          label={label}
          defaultValue={field.content ?? ''}
          placeholder={field.placeholder}
          formMethods={formMethods}
          disabled={disabled}
          maxCount={maxLength || 1000}
          rules={{
            required: isRequired,
            minLength: {
              value: minLength,
              message: replaceStringVariables(
                dictionary.postulantProfile.minLengthMessage,
                { minLength },
              ),
            },
          }}
        />
      </TextAreaContainer>
    );
  }

  return null;
};

export default PostulantProfileField;
