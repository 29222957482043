import { processTypes } from 'conf/constants';
import useDictionary from 'hooks/useDictionary';
import usePostApplication from 'mutations/application';
import { useApplicationFields, usePublication } from 'queries/publication';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import IPublication from 'types/IPublication';
import { IAnswer, QuestionType } from 'types/IQuestion';
import { IDictionary } from 'types/misc';
import { getIsClientError } from 'utils/fetch';
import { getIdFromPublicationSlug } from 'utils/utils';

import { CompletedApplicationPage, Page404, QuestionsPage } from '.';
import ApplicantDataPage from './application-steps/ApplicantData';

const getPositionName = (publication?: IPublication) => {
  return publication?.title || '';
};

const getCompanyName = (
  dictionary: IDictionary,
  publication?: IPublication,
) => {
  return publication?.is_enterprise_confidential
    ? dictionary.completed.ourCompany
    : publication?.enterprise?.name;
};

const getUrlPath = (publication?: IPublication) => {
  return publication?.process_type === processTypes.MINIMUM ? 'base' : 'full';
};

const getAnswers = (formAnswers: any, publication?: IPublication) => {
  return publication?.questions.map((processQuestion) => {
    const { id: questionId, question } = processQuestion;
    const questionAnswer = formAnswers[questionId];
    const answer: IAnswer = {
      question_id: questionId,
      content: questionAnswer,
      question_type: question.question_type,
    };
    if (question.question_type === QuestionType.SELECT_MULTIPLE) {
      answer.content = '';
      answer.options_selected = questionAnswer.map(
        (choiceIndex: number) => question.choices?.[choiceIndex],
      );
    }
    return answer;
  });
};

const getFileRequests = (formAnswers: any, publication?: IPublication) => {
  const fileRequests = publication?.file_requests || [];
  return fileRequests
    .map(({ id }) => ({ id, file: formAnswers[`file-${id}`] }))
    .filter((fileRequest) => !!fileRequest.file);
};

interface Params {
  publicationSlug: string;
}

const ApplicationSteps = (): ReactElement => {
  const { publicationSlug } = useParams<Params>();
  const id = getIdFromPublicationSlug(publicationSlug);
  const { data: publication, isError, error } = usePublication(id);
  const { data: applicationFields } = useApplicationFields(id);
  const [step, setStep] = useState(1);
  const applicantDataFormMethods = useForm();
  const questionsFormMethods = useForm();
  const urlPath = getUrlPath(publication);
  const dictionary = useDictionary(publication);

  const applicationMutation = usePostApplication(
    id,
    applicationFields || [],
    urlPath,
  );

  useEffect(() => {
    document.title = dictionary.applicationStepsBase.documentTitle;
  }, []);

  const positionName = getPositionName(publication);
  const companyName = getCompanyName(dictionary, publication);

  const getApplicantDataAndAnswers = () => {
    const applicantData = applicantDataFormMethods.getValues();
    const formAnswers = questionsFormMethods.getValues();
    const answers = getAnswers(formAnswers, publication);
    const fileRequests = getFileRequests(formAnswers, publication);

    return {
      ...applicantData,
      answers,
      fileRequests,
    };
  };

  const onSubmitApplication = questionsFormMethods.handleSubmit(() => {
    const applicantDataAndAnswers = getApplicantDataAndAnswers();
    applicationMutation.mutate(applicantDataAndAnswers, {
      onSuccess: () => {
        setStep(3);
      },
    });
  });

  if (getIsClientError(isError, error)) {
    return <Page404 />;
  }

  return (
    <>
      {step === 1 && (
        <ApplicantDataPage
          publicationSlug={publication?.slug}
          positionName={positionName}
          formMethods={applicantDataFormMethods}
          onContinue={() => setStep(2)}
          applicationFields={applicationFields || []}
        />
      )}
      {step === 2 && (
        <QuestionsPage
          isSubmittingApplication={applicationMutation.isLoading}
          positionName={positionName}
          questions={publication?.questions}
          fileRequests={publication?.file_requests}
          formMethods={questionsFormMethods}
          onGoBack={() => setStep(1)}
          onSubmitApplication={onSubmitApplication}
        />
      )}
      {step === 3 && (
        <CompletedApplicationPage
          publicationSlug={publicationSlug}
          positionName={positionName}
          companyName={companyName}
        />
      )}
    </>
  );
};

export default ApplicationSteps;
