import { AxiosError } from 'axios';
import apiRoutes from 'conf/api-routes';
import { CONTRACT_TYPES } from 'conf/query-keys';
import { useQuery, UseQueryResult } from 'react-query';
import IConstant from 'types/IConstant';
import { apiFetcher } from 'utils/fetch';

export const useContractTypes = (
  queryOptions = {},
): UseQueryResult<IConstant[], AxiosError> => {
  return useQuery(
    [CONTRACT_TYPES],
    () =>
      apiFetcher({
        apiPath: apiRoutes.contractTypes,
        withSubdomain: true,
      }).then((response) => response.data),
    {
      ...queryOptions,
    },
  );
};

export default useContractTypes;
