import { HexAlphaColor } from './ICompany';
import IConstant from './IConstant';
import IFileRequest from './IFileRequest';
import { IProcessQuestion } from './IQuestion';
import { IDepartment } from './misc';

export enum PortalCode {
  PRIVATE = 'PRIVATE',
  MMD = 'MMD',
  TRABAJANDO = 'TRABAJANDO',
}

export interface IBenefit {
  id: number;
  name: string;
  description: string;
  icon: string;
  icon_url: string;
}

export interface ICountry {
  name: string;
  code: string;
  flag: string;
}

export interface ICompanyPublicationData {
  id: number;
  name: string;
  logo: string;
  description: string;
}

interface IPublication {
  title: string;
  external_id: string;
  description: string;
  contract_type: string;
  city: string;
  region: string;
  department: string;
  requirements: string;
  type: string;
  created_on: string;
  is_enterprise_confidential: boolean;
  is_salary_confidential: boolean;
  is_active: boolean;
  enterprise?: ICompanyPublicationData;
  enterprise_name_slug?: string;
  country_data: ICountry;
  salary_range: {
    lower: number;
    upper: number;
  };
  questions: IProcessQuestion[];
  uuid: string;
  benefits: IBenefit[];
  process_type: string;
  file_requests?: IFileRequest[];
  url_return: string | undefined;
  header_background_color: HexAlphaColor | undefined;
  button_color: HexAlphaColor | undefined;
  text_color: HexAlphaColor | undefined;
  slug: string;
}

export interface IPublicationsFiltersFormData {
  searchText?: string;
  department?: IDepartment;
  contractType?: IConstant;
}

export interface IPublicationListData {
  uuid: string;
  name: string;
  company_id: number;
  company_uuid: string;
  city_name: string;
  country_name: string;
  country_flag: string;
  department_name: string;
  contract_type_value: string;
  portal_logo: string;
  portal_name: string;
  portal_code: PortalCode;
  publication_url: string;
  created_on: string;
  region_name: string;
  benefits: IBenefit[];
}

export interface ICompanyPublications {
  limit: number;
  results: IPublicationListData[];
  page_id: string | null;
}

export default IPublication;
