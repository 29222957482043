import checkCircleIcon from '@rexmas/rexmas-shared/images/check-circle-green.svg';
import documentIcon from '@rexmas/rexmas-shared/images/document.svg';
import downloadIcon from '@rexmas/rexmas-shared/images/download-light-blue.svg';
import infoIcon from '@rexmas/rexmas-shared/images/info-light-blue.svg';
import { RoundedButton } from '@rexmas/rexmas-shared/lib/components/atoms/buttons';
import {
  ColorableIcon,
  Icon,
} from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import Skeleton from '@rexmas/rexmas-shared/lib/components/atoms/misc/Skeleton';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import TextAreaFieldInput from '@rexmas/rexmas-shared/lib/components/molecules/forms/inputs/TextAreaFieldInput';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { elevations } from '@rexmas/rexmas-shared/lib/conf/constants/elevations';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import AutoCompleteInput from 'components/molecules/inputs/AutoCompleteInput';
import useDictionary from 'hooks/useDictionary';
import logoSeleccion from 'images/logo-seleccion-circle.svg';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import {
  IOfferLetter,
  IOfferLetterFormData,
  OfferLetterResponseOption,
  OfferLetterState,
} from 'types/IOfferLetter';

const Container = styled.div`
  min-height: 100vh;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.darkDash};
  padding: 24px 24px 132px;
  min-height: 475px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 48px 60px 132px;
  }
`;

const Logo = styled(Icon)`
  height: 56px;
  width: 133px;
  margin-right: auto;
`;

const StyledText = styled(Text)`
  color: ${colors.whiteTwo};
  text-align: center;
`;

const ApplicantNameText = styled(StyledText)`
  margin: 28px 0 28px;
`;

const ProcessNameText = styled(StyledText)`
  margin: 20px 0 44px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.aliceBlue};
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 414px;
  padding: 16px 24px 24px;
  border-radius: 15px;
  box-shadow: ${elevations.elevation1};
  background-color: ${colors.white};
  margin: -82px 24px 0;
`;

const CardTitle = styled(Text)`
  color: ${colors.cobalt};
  margin: 12px auto 28px;
`;

const SubmitButton = styled(RoundedButton)`
  min-width: 158px;
  height: auto;
  border-radius: 32px;
  padding: 6px 8px;
  margin: 24px auto 0;
  &:hover {
    background-color: ${colors.dash};
  }
  color: ${(props) => (props.disabled ? colors.textLightGrey : colors.white)};
  letter-spacing: normal;
  .MuiCircularProgress-root {
    color: ${colors.textLightGrey};
    ${(props) => props.isLoading && 'margin: 2px 0;'}
  }
`;

const DownloadOfferLetterButton = styled(RoundedButton)`
  border: 2px solid ${colors.dash};
  height: auto;
  border-radius: 32px;
  padding: 4px 8px 4px 20px;
  margin: 48px 0;
  .MuiButton-endIcon {
    margin: 0 8px;
  }
  color: ${colors.dash};
  letter-spacing: normal;
`;

const StyledAutoCompleteInput = styled(AutoCompleteInput)`
  margin: 20px 0 32px;
`;

const StyledSkeleton = styled(Skeleton)`
  max-width: 100%;
  margin: 0 auto;
`;

const OfferLetterRespondedCard = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 24px);
  margin: 0;
`;

const OfferLetterRespondedText = styled(Text)`
  margin-bottom: 24px;
`;

const StyledIcon = styled(ColorableIcon)`
  margin: 0 auto;
`;

const CardIcon = styled(Icon)`
  margin: 0 auto;
`;

const Email = styled(Text)`
  color: ${colors.dash};
  margin-top: 16px;
`;

const textSkeletons = (
  <>
    <ApplicantNameText variant="heading4">
      <StyledSkeleton width={500} />
    </ApplicantNameText>
    <StyledText variant="heading6">
      <StyledSkeleton width={960} />
    </StyledText>
    <ProcessNameText variant="style5">
      <StyledSkeleton width={500} />
    </ProcessNameText>
    <StyledText variant="style19">
      <StyledSkeleton width={760} />
    </StyledText>
  </>
);

interface Props {
  isLoading: boolean;
  isSubmittingResolution: boolean;
  isSubmitSuccess: boolean;
  offerLetter: IOfferLetter | undefined;
  formMethods: UseFormReturn<IOfferLetterFormData>;
  onSubmit: () => void;
}

const OfferLetterTemplate: React.FunctionComponent<Props> = ({
  isLoading,
  isSubmittingResolution,
  isSubmitSuccess,
  offerLetter,
  formMethods,
  onSubmit,
}) => {
  const dictionary = useDictionary();
  const selectedOption = formMethods.watch('state');

  const offerLetterResponseOptions = [
    {
      name: dictionary.offerLetter.acceptOfferLetter,
      value: OfferLetterResponseOption.ACCEPTED,
    },
    {
      name: dictionary.offerLetter.rejectOfferLetter,
      value: OfferLetterResponseOption.REJECTED,
    },
  ];

  if (isSubmitSuccess) {
    return (
      <Container>
        <TextContainer>
          <Logo src={logoSeleccion} />
        </TextContainer>
        <CardContainer>
          <OfferLetterRespondedCard>
            <CardIcon src={checkCircleIcon} size={36} />
            <CardTitle variant="heading6">
              {dictionary.offerLetter.sendSuccess}
            </CardTitle>
            <Text>{dictionary.offerLetter.thankYouForResponding}</Text>
            <OfferLetterRespondedText>
              {dictionary.offerLetter.weHaveReceivedYourResponse}
            </OfferLetterRespondedText>
            <Text>
              {dictionary.offerLetter.ifYouHaveQuestionsYouCanWriteTo}
            </Text>
            <Email variant="style23">{offerLetter?.support_email}</Email>
          </OfferLetterRespondedCard>
        </CardContainer>
      </Container>
    );
  }

  if (offerLetter && offerLetter.state !== OfferLetterState.PENDING) {
    return (
      <Container>
        <TextContainer>
          <Logo src={logoSeleccion} />
        </TextContainer>
        <CardContainer>
          <OfferLetterRespondedCard>
            <CardIcon src={infoIcon} size={36} />
            <CardTitle variant="heading6">
              {dictionary.offerLetter.offerLetterNotAvailable}
            </CardTitle>
            <OfferLetterRespondedText>
              {dictionary.offerLetter.linkHasExpired}
            </OfferLetterRespondedText>
            <Text>
              {dictionary.offerLetter.ifYouHaveQuestionsYouCanWriteTo}
            </Text>
            <Email variant="style23">{offerLetter.support_email}</Email>
          </OfferLetterRespondedCard>
        </CardContainer>
      </Container>
    );
  }

  return (
    <Container>
      <TextContainer>
        <Logo src={logoSeleccion} />
        {isLoading ? (
          textSkeletons
        ) : (
          <>
            <ApplicantNameText variant="heading4">
              {replaceStringVariables(dictionary.offerLetter.title, {
                name: `${offerLetter?.applicant_first_name} ${offerLetter?.applicant_last_name}`,
              })}
            </ApplicantNameText>
            <StyledText variant="heading6">
              {dictionary.offerLetter.firstParagraph}
            </StyledText>
            <ProcessNameText variant="style5">
              {offerLetter?.process_name}
            </ProcessNameText>
            <StyledText variant="style19">
              {dictionary.offerLetter.secondParagraph}
            </StyledText>
          </>
        )}
      </TextContainer>
      {!isLoading && (
        <CardContainer>
          <Card>
            <StyledIcon
              src={documentIcon}
              color={colors.veryLightBlueGrey}
              size={36}
            />
            <CardTitle variant="heading6">
              {dictionary.offerLetter.acceptOrRejectOfferLetter}
            </CardTitle>
            <Text>
              {dictionary.offerLetter.acceptOrRejectOfferLetterDescription}
            </Text>
            <StyledAutoCompleteInput
              name="state"
              disabled={isLoading}
              options={offerLetterResponseOptions}
              placeholder={dictionary.offerLetter.chooseOption}
              getOptionLabel={(option) => option?.name}
              rules={{ required: dictionary.general.fieldIsRequired }}
              formMethods={formMethods}
              hideSearch
            />
            {selectedOption?.value === OfferLetterResponseOption.REJECTED && (
              <div>
                <Text>{dictionary.offerLetter.giveAReason}</Text>
                <TextAreaFieldInput
                  name="message"
                  placeholder={dictionary.offerLetter.writeReason}
                  rules={{ required: dictionary.general.fieldIsRequired }}
                  formMethods={formMethods}
                  maxCount={200}
                  charactersText=""
                  shouldUnregister
                />
              </div>
            )}
            <SubmitButton
              variant="secondary"
              onClick={onSubmit}
              isLoading={isSubmittingResolution}
              disabled={isSubmittingResolution || !selectedOption}
            >
              {dictionary.offerLetter.sendResponse}
            </SubmitButton>
          </Card>
          <DownloadOfferLetterButton
            variant="quinary"
            onClick={() => window.open(offerLetter?.offer_letter_file)}
            endIcon={<Icon src={downloadIcon} />}
          >
            {dictionary.offerLetter.downloadOfferLetter}
          </DownloadOfferLetterButton>
        </CardContainer>
      )}
    </Container>
  );
};

export default OfferLetterTemplate;
