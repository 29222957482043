import InfoIcon from '@rexmas/rexmas-shared/images/info.svg';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { IconText } from '@rexmas/rexmas-shared/lib/components/molecules/text';
import StepNumber from '@rexmas/rexmas-shared/lib/components/organisms/steps/StepNumber';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import dictionary from 'conf/dictionaries/es_CL';
import styled, { css } from 'styled-components';
import { StepNumberTheme } from 'types/misc';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 52px 16px 32px;
  min-height: 100vh;
  background-color: ${colors.white};
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 52px 84px 64px;
  }
`;

const ContentContainer = styled.div`
  max-width: 880px;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 422px;
  margin: 16px 0 12px;
  @media screen and (min-width: ${mediaQueries.md}px) {
    margin: 0 0 12px;
  }
`;

const activeStep = css`
  color: ${colors.whiteTwo};
  border-color: ${colors.highlighted};
  background-color: ${colors.highlighted};
`;

const StyledStepNumber = styled(StepNumber)`
  .StepNumber-Number {
    font-size: 16px;
    color: ${colors.textDarkGrey};
    background-color: ${colors.white};
    border: 1px solid ${colors.placeholderGrey};
    ${(props) => props.step.number <= props['current-step'] && activeStep};
  }
  .StepNumber-Line {
    margin: 0;
    opacity: 1;
    height: 1px;
    background-color: ${colors.placeholderGrey};
  }
  .StepNumber-TitleContainer {
    display: none;
  }
`;

const TitleText = styled(Text)`
  font-size: 24px;
  margin-top: 32px;
`;

const StyledIconText = styled(IconText)`
  align-items: center;
  .colorable-icon {
    padding: 0 12px;
  }
  .text {
    color: ${colors.textDarkGrey};
  }
`;

interface Props {
  currentStep: number;
  header: string;
  title: string | React.ReactElement;
}

const ApplicationStepsBase: React.FunctionComponent<Props> = ({
  currentStep,
  header,
  title,
  children,
}) => {
  const steps = [
    {
      number: 1,
      variant: StepNumberTheme.DOING,
      isLast: false,
    },
    {
      number: 2,
      variant: StepNumberTheme.DOING,
      isLast: false,
    },
    {
      number: 3,
      variant: StepNumberTheme.DOING,
      isLast: true,
    },
  ];

  return (
    <Container>
      <StepsContainer>
        {steps.map((step) => (
          <StyledStepNumber
            key={step.number}
            step={step}
            current-step={currentStep}
          />
        ))}
      </StepsContainer>
      <Text variant="style18">{header}</Text>
      <TitleText>{title}</TitleText>
      <ContentContainer>
        {children}
        <StyledIconText
          text={dictionary.applicationStepsBase.dataPrivacyStatement}
          iconColor={colors.veryLightBlueGrey}
          iconSrc={InfoIcon}
          textClassName="text"
          textVariant="style19"
        />
      </ContentContainer>
    </Container>
  );
};

export default ApplicationStepsBase;
