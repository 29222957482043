import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { HexAlphaColor, ICompanyColors } from 'types/ICompany';
import { to8DigitsHexColor } from 'utils/utils';

export const COMPANY_LOGO_MAX_FILE_SIZE = 3 * 1024 * 1024;
export const COMPANY_LOGO_FILE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];

export const defaultCompanyColors = {
  textColor: colors.whiteTwo,
  buttonColor: colors.lightHighlighted,
  backgroundColor: colors.cobalt,
};

type Colors = {
  text_color?: HexAlphaColor;
  button_color?: HexAlphaColor;
  header_background_color?: HexAlphaColor;
};

export const getCompanyColors = <T extends Colors>(
  values?: T,
): ICompanyColors => {
  if (!values) {
    return defaultCompanyColors;
  }
  const {
    text_color: textColor,
    button_color: buttonColor,
    header_background_color: backgroundColor,
  } = values;

  return {
    textColor: textColor
      ? to8DigitsHexColor(textColor)
      : defaultCompanyColors.textColor,

    buttonColor: buttonColor
      ? to8DigitsHexColor(buttonColor)
      : defaultCompanyColors.buttonColor,

    backgroundColor: backgroundColor
      ? to8DigitsHexColor(backgroundColor)
      : defaultCompanyColors.backgroundColor,
  };
};
