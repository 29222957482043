import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { RoundedIconButton } from '@rexmas/rexmas-shared/lib/components/molecules/buttons';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import dictionary from 'conf/dictionaries/es_CL';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import PostulationFormBase from '../ApplicationStepsBase';

const SubTitleText = styled(Text)`
  display: block;
  margin-top: 16px;
`;

const PositionName = styled(Text)`
  color: ${colors.cobalt};
  font-size: inherit;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const ContinueButton = styled(RoundedIconButton)`
  width: auto;
  height: 42px;
  padding: 0 12px 0 20px;
  margin-bottom: 24px;
  > div {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding-right: 8px;
  }
  > div:nth-child(2) {
    display: none;
  }
`;

interface Props {
  publicationSlug: string | undefined;
  positionName: string;
  companyName: string;
}

const CompletedApplication: React.FunctionComponent<Props> = ({
  publicationSlug,
  positionName,
  companyName,
}) => {
  const location = useLocation();
  const parsedSubtitle = replaceStringVariables(
    dictionary.completed.subTitlePartTwo,
    { company: companyName },
  );

  const subTitle = (
    <>
      {dictionary.completed.subTitlePartOne}
      <PositionName variant="style23"> {positionName} </PositionName>
      {parsedSubtitle}
    </>
  );

  return (
    <PostulationFormBase
      currentStep={3}
      header={dictionary.completed.applicationCompleted}
      title={dictionary.completed.applicationSuccess}
    >
      <SubTitleText variant="style19">{subTitle}</SubTitleText>
      <ButtonsContainer>
        <Link to={`/${publicationSlug}${location.search}`}>
          <ContinueButton
            icon=""
            variant="terciary"
            text={dictionary.completed.backToHome}
            data-testid="go-home-button"
          />
        </Link>
      </ButtonsContainer>
    </PostulationFormBase>
  );
};

export default CompletedApplication;
