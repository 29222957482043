import { showErrorAction } from '@rexmas/rexmas-shared/lib/redux/actionCreators/errors';
import PostulantProfileTemplate from 'components/templates/PostulantProfile';
import useDictionary from 'hooks/useDictionary';
import { useSendPostulantProfileAnswers } from 'mutations/postulantProfile';
import { usePostulantProfile } from 'queries/postulantProfile';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IPostulantProfileFormData } from 'types/IPostulantProfile';
import { IFieldError } from 'types/misc';
import { getIsClientError } from 'utils/fetch';
import { getErrorMessage } from 'utils/utils';

import Page404 from './404';

interface Params {
  id: string;
}

const PostulantProfile: React.FunctionComponent = () => {
  const { id } = useParams<Params>();
  const dispatch = useDispatch();
  const dictionary = useDictionary();
  const {
    data: postulantProfile,
    isLoading,
    isError,
    error,
  } = usePostulantProfile(id);
  const sendPostulantProfileMutation = useSendPostulantProfileAnswers(id);
  const formMethods = useForm<IPostulantProfileFormData>();
  const [formStep, setFormStep] = useState(1);

  if (getIsClientError(isError, error)) {
    return <Page404 />;
  }

  const onSubmit = (data: IPostulantProfileFormData) => {
    sendPostulantProfileMutation.mutate(data, {
      onError: (response: any) => {
        if (response.response?.status === 422) {
          setFormStep(1);
          response.response?.data.errors?.forEach((fieldError: IFieldError) => {
            formMethods.setError(
              String(fieldError.field),
              { type: 'manual', message: fieldError.message },
              { shouldFocus: true },
            );
          });
        }
        dispatch(
          showErrorAction({
            message:
              response.response?.status === 422
                ? dictionary.postulantProfile.fieldsValidationErrors
                : getErrorMessage(response),
          }),
        );
      },
    });
  };

  const onGoToFirstStep = () => {
    setFormStep(1);
    window.scrollTo({ top: 0 });
  };

  const onGoToSecondStep = formMethods.handleSubmit(() => {
    setFormStep(2);
    window.scrollTo({ top: 0 });
  });

  return (
    <PostulantProfileTemplate
      isLoading={isLoading}
      isSubmitting={sendPostulantProfileMutation.isLoading || false}
      formStep={formStep}
      postulantProfile={postulantProfile}
      formMethods={formMethods}
      onGoToFirstStep={onGoToFirstStep}
      onGoToSecondStep={onGoToSecondStep}
      onSubmit={formMethods.handleSubmit(onSubmit as any)}
    />
  );
};

export default PostulantProfile;
