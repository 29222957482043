import arrowLeftIcon from '@rexmas/rexmas-shared/images/arrow-left-dash.svg';
import arrowRightIcon from '@rexmas/rexmas-shared/images/arrow-right-blue.svg';
import DollarIcon from '@rexmas/rexmas-shared/images/dollar-sign-highlight.svg';
import { Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { RoundedIconButton } from '@rexmas/rexmas-shared/lib/components/molecules/buttons';
import DatePickerInput from '@rexmas/rexmas-shared/lib/components/molecules/forms/inputs/DatePickerInput';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import patterns from '@rexmas/rexmas-shared/lib/conf/dictionary/regex';
import AutoCompleteInput from 'components/molecules/inputs/AutoCompleteInput';
import FileInput from 'components/molecules/inputs/FileInput';
import NumberFormatInput from 'components/molecules/inputs/NumberFormatInput';
import TextFieldInput from 'components/molecules/inputs/TextFieldInput';
import {
  AVATAR_FILE_EXTENSIONS,
  AVATAR_MAX_FILE_SIZE_B,
  AVATAR_MAX_FILE_SIZE_MB,
  CV_FILE_EXTENSIONS,
  CV_MAX_FILE_SIZE_B,
  CV_MAX_FILE_SIZE_MB,
  getInvalidFileErrorMessage,
  INTRODUCTION_VIDEO_FILE_EXTENSIONS,
  INTRODUCTION_VIDEO_MAX_FILE_SIZE_B,
  INTRODUCTION_VIDEO_MAX_FILE_SIZE_MB,
} from 'conf/applicantData';
import { genders } from 'conf/constants';
import useDictionary from 'hooks/useDictionary';
import { UseFormReturn, ValidateResult } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { format, validate } from 'rut.js';
import styled from 'styled-components';
import IConstant from 'types/IConstant';
import { CountryCode } from 'types/misc';

import PostulationFormBase from '../ApplicationStepsBase';

const SubTitleText = styled(Text)`
  display: block;
  margin-top: 16px;
  text-align: center;
`;

const PositionName = styled(Text)`
  color: ${colors.cobalt};
  font-size: inherit;
`;

const InputsContainer = styled.div`
  margin: 32px 0 40px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  column-gap: 24px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ContinueButton = styled(RoundedIconButton)`
  width: auto;
  margin-left: auto;
  height: 42px;
  padding: 0 12px 0 20px;
  margin-bottom: 24px;
  > div {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &&.Mui-disabled {
    color: ${colors.textLightGrey};
    border-color: ${colors.grey};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GoBackButton = styled(ContinueButton)`
  border: none;
  padding: 0 20px 0 0;
  margin-left: 0;
  &:hover {
    color: ${colors.cobalt};
    background-color: ${colors.transparent};
  }
  [class*='StyledIconContainer'] {
    margin-left: 0;
    left: 0;
  }
`;

const StyledDatePickerInput = styled(DatePickerInput)`
  [class*='MuiInputBase-input'] {
    padding-left: 0px;
  }
`;

interface Props {
  publicationSlug: string | undefined;
  positionName: string;
  formMethods: UseFormReturn;
  cities: IConstant[];
  countries: IConstant[];
  isLoadingNationalities: boolean;
  onContinue: () => void;
  applicationFields: string[];
}

const ApplicantData: React.FunctionComponent<Props> = ({
  publicationSlug,
  positionName,
  formMethods,
  cities,
  countries,
  isLoadingNationalities,
  onContinue,
  applicationFields,
}) => {
  const location = useLocation();
  const dictionary = useDictionary();

  const title = (
    <>
      {dictionary.applicantData.youAreApplyingTo}
      <PositionName variant="style23"> {positionName}</PositionName>
    </>
  );

  const formFields: Record<string, JSX.Element> = {
    first_name: (
      <TextFieldInput
        name="first_name"
        label={dictionary.applicantData.inputs.firstName}
        rules={{ required: dictionary.general.fieldIsRequired }}
        control={formMethods.control}
      />
    ),
    last_name: (
      <TextFieldInput
        name="last_name"
        label={dictionary.applicantData.inputs.lastName}
        rules={{ required: dictionary.general.fieldIsRequired }}
        control={formMethods.control}
      />
    ),
    national_id: (
      <TextFieldInput
        name="national_id"
        label={dictionary.applicantData.inputs.nationalId}
        rules={{
          required: dictionary.general.fieldIsRequired,
          validate: (value: string): ValidateResult => {
            if (
              dictionary.general.countryCode === CountryCode.CL &&
              !validate(value)
            ) {
              return dictionary.applicantData.inputs.invalidNationalId;
            }
            return true;
          },
          onChange: (e) => {
            const { value } = e.target;
            if (dictionary.general.countryCode === CountryCode.CL) {
              const formattedValue = format(value, { dots: false });
              formMethods.setValue('national_id', formattedValue);
            } else {
              formMethods.setValue('national_id', value);
            }
          },
        }}
        control={formMethods.control}
      />
    ),
    email: (
      <TextFieldInput
        name="email"
        label={dictionary.applicantData.inputs.email}
        rules={{
          pattern: {
            value: patterns.email,
            message: dictionary.applicantData.inputs.invalidEmail,
          },
          required: dictionary.general.fieldIsRequired,
        }}
        control={formMethods.control}
      />
    ),
    phone: (
      <NumberFormatInput
        name="phone"
        label={dictionary.applicantData.inputs.phone}
        control={formMethods.control}
        maxLength={12}
        thousandSeparator=""
        rules={{
          minLength: {
            value: 9,
            message: dictionary.applicantData.inputs.invalidPhoneLength,
          },
          required: dictionary.general.fieldIsRequired,
        }}
      />
    ),
    linkedin: (
      <TextFieldInput
        name="linkedIn"
        label={dictionary.applicantData.inputs.linkedIn}
        control={formMethods.control}
      />
    ),
    gender: (
      <AutoCompleteInput
        name="gender"
        options={genders}
        formMethods={formMethods}
        placeholder={dictionary.applicantData.inputs.gender}
        getOptionLabel={(option) => option?.name}
        rules={{ required: dictionary.general.fieldIsRequired }}
        hideSearch
      />
    ),
    birth_date: (
      <StyledDatePickerInput
        name="birth_date"
        label={dictionary.applicantData.inputs.birthDate}
        control={formMethods.control}
        defaultValue=""
        rules={{
          required: dictionary.general.fieldIsRequired,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          validate: (date?: any) =>
            !date ||
            ((date?.isValid() ||
              dictionary.applicantData.inputs
                .dateFormatError) as ValidateResult),
        }}
        disableFuture
      />
    ),
    country: (
      <AutoCompleteInput
        name="country"
        options={countries}
        formMethods={formMethods}
        placeholder={dictionary.applicantData.inputs.nationality}
        getOptionLabel={(option) => option?.name}
        rules={{ required: dictionary.general.fieldIsRequired }}
        hideSearch={false}
        loading={isLoadingNationalities}
        loadingText={dictionary.applicantData.inputs.loadingCountries}
      />
    ),
    profile_pic: (
      <FileInput
        name="avatar"
        placeholder={dictionary.applicantData.inputs.avatar}
        maxSize={AVATAR_MAX_FILE_SIZE_B}
        accept={AVATAR_FILE_EXTENSIONS}
        formMethods={formMethods}
        rules={{
          validate: {
            validFile: (file) =>
              !file ||
              getInvalidFileErrorMessage(file, {
                maxFileSize: AVATAR_MAX_FILE_SIZE_MB,
                allowedExtensions: AVATAR_FILE_EXTENSIONS,
              }),
          },
        }}
      />
    ),
    residence: (
      <AutoCompleteInput
        name="residence"
        options={countries}
        formMethods={formMethods}
        placeholder={dictionary.applicantData.inputs.residence}
        getOptionLabel={(option) => option?.name}
        rules={{ required: dictionary.general.fieldIsRequired }}
        hideSearch={false}
        loading={isLoadingNationalities}
        loadingText={dictionary.applicantData.inputs.loadingCountries}
      />
    ),
    city:
      cities.length > 0 ? (
        <AutoCompleteInput
          name="city"
          options={cities}
          formMethods={formMethods}
          placeholder={dictionary.applicantData.inputs.city}
          getOptionLabel={(option) => option?.value}
          rules={{ required: dictionary.general.fieldIsRequired }}
          hideSearch={false}
        />
      ) : (
        <></>
      ),
    address: (
      <TextFieldInput
        name="address"
        label={dictionary.applicantData.inputs.address}
        rules={{ required: dictionary.general.fieldIsRequired }}
        control={formMethods.control}
      />
    ),
    salary_claim: (
      <NumberFormatInput
        name="salary"
        label={dictionary.applicantData.inputs.salaryExpectation}
        prefix={dictionary.general.currencyPrefix}
        thousandSeparator={dictionary.general.currencyThousandSeparator}
        rules={{ required: dictionary.general.fieldIsRequired }}
        control={formMethods.control}
        maxLength={12}
        InputProps={{
          endAdornment: <Icon src={DollarIcon} size={24} />,
        }}
      />
    ),
    cv: (
      <FileInput
        name="cv"
        placeholder={dictionary.applicantData.inputs.cv}
        helperText={dictionary.applicantData.inputs.cvHelperText}
        maxSize={CV_MAX_FILE_SIZE_B}
        accept={CV_FILE_EXTENSIONS}
        formMethods={formMethods}
        rules={{
          required: dictionary.general.fieldIsRequired,
          validate: {
            validFile: (file) =>
              getInvalidFileErrorMessage(file, {
                maxFileSize: CV_MAX_FILE_SIZE_MB,
                allowedExtensions: CV_FILE_EXTENSIONS,
              }),
          },
        }}
      />
    ),
    introduction_video: (
      <FileInput
        name="introduction_video"
        placeholder={dictionary.applicantData.inputs.introductionVideo}
        helperText={dictionary.applicantData.inputs.introductionVideoHelperText}
        maxSize={INTRODUCTION_VIDEO_MAX_FILE_SIZE_B}
        accept={INTRODUCTION_VIDEO_FILE_EXTENSIONS}
        formMethods={formMethods}
        rules={{
          validate: {
            validFile: (file) =>
              !file ||
              getInvalidFileErrorMessage(file, {
                maxFileSize: INTRODUCTION_VIDEO_MAX_FILE_SIZE_MB,
                allowedExtensions: INTRODUCTION_VIDEO_FILE_EXTENSIONS,
              }),
          },
        }}
      />
    ),
  };

  return (
    <PostulationFormBase
      currentStep={1}
      header={dictionary.applicantData.personalData}
      title={title}
    >
      <SubTitleText variant="style19">
        {applicationFields.includes('cv')
          ? dictionary.applicantData.subTitle
          : dictionary.applicantData.subTitleNoCv}
      </SubTitleText>
      <InputsContainer>
        {applicationFields.map((field: string) => formFields[field])}
      </InputsContainer>
      <ButtonsContainer>
        {publicationSlug && (
          <Link to={`/${publicationSlug}${location.search}`}>
            <GoBackButton
              isIconLeft
              icon={arrowLeftIcon}
              variant="terciary"
              text={dictionary.applicationStepsBase.goBack}
              data-testid="go-back-button"
            />
          </Link>
        )}
        <ContinueButton
          icon={arrowRightIcon}
          iconColor={colors.dash}
          variant="terciary"
          text={dictionary.applicationStepsBase.continue}
          data-testid="continue-button"
          onClick={onContinue}
        />
      </ButtonsContainer>
    </PostulationFormBase>
  );
};

export default ApplicantData;
