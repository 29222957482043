import { createContext } from 'react';
import { IDictionary } from 'types/misc';

import dictionaryCL from './dictionaries/es_CL';

interface IDictionaryContext {
  dictionary: IDictionary;
  setDictionary: (dictionary: IDictionary) => void;
}

export const initialDictionaryContext = {
  dictionary: dictionaryCL,
  setDictionary: (): null => null,
};

export const DictionaryContext = createContext<IDictionaryContext>(
  initialDictionaryContext,
);
