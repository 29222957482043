import errorsReducer from '@rexmas/rexmas-shared/lib/redux/reducers/errorsReducer';
import messagesReducer from '@rexmas/rexmas-shared/lib/redux/reducers/messagesReducer';
import { Action, CombinedState, combineReducers } from 'redux';

const appReducer = combineReducers({
  errorsReducer,
  messagesReducer,
});

type State = CombinedState<{
  errorsReducer: unknown;
  messagesReducer: unknown;
}>;

const rootReducer = (state: State | undefined, action: Action): State =>
  appReducer(state, action);

export default rootReducer;
