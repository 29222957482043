import { InputBaseProps } from '@mui/material';
import { UnderlinedNumberFormatTextField } from '@rexmas/rexmas-shared/lib/components/molecules/inputs';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';

const StyledNumberFormatTextField = styled(UnderlinedNumberFormatTextField)`
  .MuiFormLabel-root {
    color: ${colors.disabledGrey};
  }
`;

interface Props {
  name: string;
  label: string;
  prefix?: string;
  maxLength?: number;
  thousandSeparator?: string;
  rules?: RegisterOptions;
  control: Control;
  InputProps?: InputBaseProps;
  disabled?: boolean;
  helperText?: string;
  defaultValue?: string | null;
}

const NumberFormatInput: React.FunctionComponent<Props> = ({
  name,
  prefix,
  label,
  maxLength,
  thousandSeparator,
  rules,
  control,
  InputProps,
  disabled = false,
  helperText = '',
  defaultValue = '',
}) => {
  return (
    <Controller
      name={`${name}` as const}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => (
        <StyledNumberFormatTextField
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          error={invalid}
          helperText={invalid ? error?.message : helperText}
          disabled={disabled}
          decimalScale={0}
          prefix={prefix}
          thousandSeparator={thousandSeparator}
          decimalSeparator={null}
          inputProps={{ maxLength }}
          InputProps={InputProps}
        />
      )}
    />
  );
};

export default NumberFormatInput;
