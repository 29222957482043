import chevronDownIcon from '@rexmas/rexmas-shared/images/chevron-down.svg';
import editIcon from '@rexmas/rexmas-shared/images/edit.svg';
import MagnifyingGlassIcon from '@rexmas/rexmas-shared/images/magnifying-glass.svg';
import xIcon from '@rexmas/rexmas-shared/images/x-highlighted.svg';
import { IconButton } from '@rexmas/rexmas-shared/lib/components/atoms/buttons';
import { Hidden } from '@rexmas/rexmas-shared/lib/components/atoms/core';
import {
  ColorableIcon,
  Icon,
} from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import {
  CircularProgress,
  Skeleton,
} from '@rexmas/rexmas-shared/lib/components/atoms/misc';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import { AutoComplete } from '@rexmas/rexmas-shared/lib/components/molecules/inputs';
import ErrorDetail from '@rexmas/rexmas-shared/lib/components/organisms/ErrorDetail';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import { useMediaQuery } from '@rexmas/rexmas-shared/lib/utils/hooks';
import { replaceStringVariables } from '@rexmas/rexmas-shared/lib/utils/misc';
import JobOffer from 'components/molecules/cards/JobOffer';
import AutoCompleteInput from 'components/molecules/inputs/AutoCompleteInput';
import TextFieldInput from 'components/molecules/inputs/TextFieldInput';
import CustomizeWebsiteDrawer from 'components/organisms/CustomizeWebsiteDrawer';
import { getCompanyColors } from 'conf/companies';
import { getPublishedDate } from 'conf/jobOffers';
import { ChangeEvent, RefObject } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled, { ThemeProvider } from 'styled-components';
import {
  ICompany,
  ICompanyColors,
  ICustomizeCompanyFormData,
} from 'types/ICompany';
import IConstant from 'types/IConstant';
import {
  IPublicationListData,
  IPublicationsFiltersFormData,
} from 'types/IPublication';
import { IDepartment } from 'types/misc';
import { getElapsedDays } from 'utils/utils';

import dictionary from '../../conf/dictionaries/es_CL';
import logo from '../../images/logo-seleccion.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundColor};
`;

const CompanyLogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TopItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 0 0 16px;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 24px 60px 0;
  }
`;

const CompanySelector = styled(AutoComplete)`
  label + .MuiTextField-root {
    input {
      color: ${(props) => props.theme.textColor};
      font-size: 18px;
      font-weight: 500;
    }
    .MuiInput-underline {
      :before,
      :after {
        border: none;
      }
    }
  }
  .MuiInput-underline {
    flex-direction: row-reverse;
    gap: 8px;
  }
  .MuiInputLabel-root {
    margin-bottom: 12px;
  }
  .MuiInputAdornment-root {
    margin: 0;
  }
`;

const CompanyLogo = styled(Icon)`
  border-radius: 50%;
  flex: 0 0 auto;
  object-fit: contain;
`;

const CompanyName = styled(Text)`
  font-size: 18px;
  color: ${(props) => props.theme.textColor};
`;

const Title = styled(Text)`
  font-size: 28px;
  color: ${(props) => props.theme.textColor};
  padding: 56px 16px;
  text-align: center;
  width: 100%;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 56px 60px;
    font-size: 36px;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  background-color: ${colors.whiteTwo};
  @media screen and (min-width: ${mediaQueries.sm}px) {
    padding: 12px 60px 36px;
    background-color: ${colors.transparent};
  }
`;

const StyledTextFieldInput = styled(TextFieldInput)`
  @media screen and (min-width: ${mediaQueries.sm}px) {
    width: 280px;
    height: auto;
    margin-bottom: 6px;
    .MuiInput-root.MuiInput-underline {
      :before {
        border-color: ${(props) => props.theme.textColor};
        opacity: 0.6;
      }
      :after,
      :hover:before {
        border-color: ${(props) => props.theme.buttonColor};
        opacity: 0.6;
      }
    }
    && .MuiFormLabel-root {
      color: ${(props) => props.theme.textColor};
      opacity: 0.4;
    }
    input {
      color: ${(props) => props.theme.textColor};
    }
  }
`;

const StyledAutoCompleteInput = styled(AutoCompleteInput)`
  @media screen and (min-width: ${mediaQueries.sm}px) {
    width: 280px;
    label + .MuiTextField-root .MuiInput-underline {
      :before {
        border-color: ${(props) => props.theme.textColor};
        opacity: 0.6;
      }
      :after,
      :hover:before {
        border-color: ${(props) => props.theme.buttonColor};
      }
    }
    .MuiFormLabel-root {
      color: ${(props) => props.theme.textColor};
      opacity: 0.4;
    }
    & > .MuiFormControl-root input {
      color: ${(props) => props.theme.textColor};
    }
  }
`;

const StyledColorableIcon = styled(ColorableIcon)`
  &:hover {
    cursor: pointer;
  }
`;

const AutoCompleteIcon = styled(ColorableIcon)`
  height: 20px;
`;

const JobOffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 16px auto;
  background-color: ${colors.whiteTwo};
  height: 100%;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    margin: 32px 60px auto;
  }
`;

const FooterText = styled(Text)`
  margin: 64px auto 28px;
`;

const TitleSkeleton = styled(Skeleton)`
  margin: auto;
`;

const JobOfferSkeleton = styled(Skeleton)`
  margin: 12px 0;
  border-radius: 15px;
  background-color: ${colors.lightGrey};
`;

const CircularProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

const CustomizePlatformButton = styled(IconButton)`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform-origin: 100% 50%;
  transform: rotate(-90deg) translate(50%, -50%);
  margin: auto;
  background-color: ${colors.highlighted};
  border-radius: 5px 5px 0 0;
  height: 42px;
  &:hover {
    background-color: ${colors.highlighted};
  }
  @media screen and (min-width: ${mediaQueries.sm}px) {
    display: flex;
  }
`;

const EditIcon = styled(ColorableIcon)`
  transform: rotate(90deg);
  margin-right: 4px;
`;

const CutomizeButtonText = styled(Text)`
  margin: 0 12px;
  color: ${colors.white};
`;

const jobOfferListSkeleton = (
  <>
    <JobOfferSkeleton variant="rect" height={140} />
    <JobOfferSkeleton variant="rect" height={140} />
    <JobOfferSkeleton variant="rect" height={140} />
    <JobOfferSkeleton variant="rect" height={140} />
    <JobOfferSkeleton variant="rect" height={140} />
  </>
);

const getNoResultsDetail = (filters: IPublicationsFiltersFormData) => {
  const { searchText, department, contractType } = filters;
  const isFilterActive = searchText ?? department ?? contractType;
  return isFilterActive ? (
    <ErrorDetail
      header={dictionary.jobOffers.noResultsFoundHeader}
      firstSentence={dictionary.jobOffers.noResultsFoundHeaderParagraph}
      withGoBackButton={false}
    />
  ) : (
    <ErrorDetail
      header={dictionary.jobOffers.noJobOffersAvailableHeader}
      firstSentence={dictionary.jobOffers.noJobOffersAvailableParagraph}
      withGoBackButton={false}
    />
  );
};

const getHeader = (
  headerText: string | undefined,
  company: ICompany | undefined,
  isLoading: boolean,
) => {
  if (isLoading) {
    return <TitleSkeleton width="50%" />;
  }
  const titleText = replaceStringVariables(dictionary.jobOffers.workWithUsAt, {
    companyName: company?.name,
  });
  return headerText ?? titleText;
};

const getFooter = (footerText?: string) => {
  return footerText ?? dictionary.jobOffers.footer;
};

const getSearchInputIcon = (searchText?: string) => {
  return searchText ? xIcon : MagnifyingGlassIcon;
};

const getCompanyLogo = (
  companyValues: ICustomizeCompanyFormData,
  selectedCompany?: ICompany,
) => {
  const formLogo = companyValues.thumbnail_logo?.[0];
  if (formLogo) {
    return URL.createObjectURL(formLogo);
  }
  return selectedCompany?.thumbnail_logo ?? logo;
};

const getInputIconColor = (
  isMobile: boolean,
  companyColors: ICompanyColors,
) => {
  return isMobile ? colors.highlighted : companyColors.buttonColor;
};

interface Props {
  isLoadingJobOffers: boolean;
  isLoadingCompanies: boolean;
  isCustomizeWebsiteDrawerOpen: boolean;
  isSavingCustomizeWebsiteChanges: boolean;
  hasNextPage?: boolean;
  isAdmin?: boolean;
  company?: ICompany;
  companies?: ICompany[];
  departmentsOptions?: IDepartment[];
  contractTypesOptions?: IConstant[];
  jobOffers: IPublicationListData[];
  filtersFormMethods: UseFormReturn<IPublicationsFiltersFormData>;
  customizeCompanyFormMethods: UseFormReturn<ICustomizeCompanyFormData>;
  fetchNextPageRef: RefObject<HTMLDivElement>;
  onChangeSelectedCompany: (company: ICompany) => void;
  onSaveHeaderChanges: () => void;
  onSaveFooterChanges: () => void;
  onResetHeaderChanges: () => void;
  setIsCustomizeWebsiteDrawerOpen: (open: boolean) => void;
  fetchNextPage: () => void;
}

const JobOffers: React.FunctionComponent<Props> = ({
  isLoadingJobOffers,
  isLoadingCompanies,
  isCustomizeWebsiteDrawerOpen,
  isSavingCustomizeWebsiteChanges,
  isAdmin = false,
  hasNextPage,
  company,
  companies,
  departmentsOptions = [],
  contractTypesOptions = [],
  jobOffers,
  filtersFormMethods,
  customizeCompanyFormMethods,
  onChangeSelectedCompany,
  onSaveHeaderChanges,
  onSaveFooterChanges,
  onResetHeaderChanges,
  setIsCustomizeWebsiteDrawerOpen,
  fetchNextPageRef,
}) => {
  const isMobile = useMediaQuery('down', 'sm');
  const filtersFormValues = filtersFormMethods.watch();
  const companyFormValues = customizeCompanyFormMethods.watch();

  const noOffersComponent =
    isLoadingJobOffers || isLoadingCompanies
      ? jobOfferListSkeleton
      : getNoResultsDetail(filtersFormValues);

  const pageColors = getCompanyColors(companyFormValues);
  const inputIconColor = getInputIconColor(isMobile, pageColors);

  const header = getHeader(
    companyFormValues.header_text,
    company,
    isLoadingCompanies,
  );
  const footer = getFooter(companyFormValues.footer_text);

  const companyLogoName = (
    <CompanyLogoContainer>
      <CompanyLogo src={getCompanyLogo(companyFormValues, company)} size={44} />
      <CompanyName>{company?.name}</CompanyName>
    </CompanyLogoContainer>
  );

  const companyLogoSelector = (
    <CompanyLogoContainer>
      <CompanyLogo src={getCompanyLogo(companyFormValues, company)} size={44} />
      <CompanySelector
        value={company?.name}
        onChange={(_event: ChangeEvent, value: ICompany) =>
          onChangeSelectedCompany(value)
        }
        options={companies}
        hideSearch
        getOptionLabel={(option: ICompany) => option.name}
        getOptionElement={(option: ICompany) => option.name}
        InputProps={{
          endAdornment: (
            <ColorableIcon src={chevronDownIcon} color={pageColors.textColor} />
          ),
        }}
      />
    </CompanyLogoContainer>
  );

  const companyLogo = isAdmin ? companyLogoSelector : companyLogoName;

  return (
    <ThemeProvider theme={pageColors}>
      <Container>
        <HeaderContainer data-testid="header-container">
          <TopItemsContainer>
            {isLoadingCompanies ? (
              <CompanyLogoContainer>
                <Skeleton variant="circular" width={44} height={44} />
                <Skeleton width={100} />
              </CompanyLogoContainer>
            ) : (
              companyLogo
            )}
            {isAdmin && company && (
              <Hidden smUp>
                <IconButton
                  onClick={() => setIsCustomizeWebsiteDrawerOpen(true)}
                >
                  <ColorableIcon src={editIcon} color={pageColors.textColor} />
                </IconButton>
              </Hidden>
            )}
          </TopItemsContainer>
          <Title variant="style5" data-testid="header-title">
            {header}
          </Title>
          <InputsContainer>
            <StyledTextFieldInput
              name="searchText"
              label={dictionary.jobOffers.searchPlaceholder}
              control={filtersFormMethods.control}
              InputProps={{
                endAdornment: (
                  <StyledColorableIcon
                    src={getSearchInputIcon(filtersFormValues.searchText)}
                    color={inputIconColor}
                    onClick={() =>
                      filtersFormMethods.setValue('searchText', '')
                    }
                  />
                ),
              }}
            />
            <StyledAutoCompleteInput
              name="department"
              options={departmentsOptions}
              placeholder={dictionary.jobOffers.departmentsPlaceholder}
              getOptionLabel={(option) => option?.name}
              clearable
              formMethods={filtersFormMethods}
              InputProps={{
                endAdornment: (
                  <AutoCompleteIcon
                    src={chevronDownIcon}
                    size={24}
                    color={inputIconColor}
                  />
                ),
              }}
            />
            <StyledAutoCompleteInput
              name="contractType"
              options={contractTypesOptions}
              placeholder={dictionary.jobOffers.contractTypesPlaceholder}
              getOptionLabel={(option) => option?.name}
              hideSearch
              clearable
              formMethods={filtersFormMethods}
              InputProps={{
                endAdornment: (
                  <AutoCompleteIcon
                    src={chevronDownIcon}
                    size={24}
                    color={inputIconColor}
                  />
                ),
              }}
            />
          </InputsContainer>
        </HeaderContainer>
        <JobOffersContainer>
          {jobOffers.length === 0
            ? noOffersComponent
            : jobOffers.map((jobOffer) => {
                const daysPublished = getElapsedDays(jobOffer.created_on);
                const publishedDate = getPublishedDate(jobOffer.created_on);
                const isNew = daysPublished <= 7;
                return (
                  <JobOffer
                    key={jobOffer.uuid}
                    isNew={isNew}
                    name={jobOffer.name}
                    contractType={jobOffer.contract_type_value}
                    department={jobOffer.department_name}
                    publishedDate={publishedDate}
                    countryName={jobOffer.country_name}
                    countryFlag={jobOffer.country_flag}
                    city={jobOffer.city_name}
                    portalLogo={jobOffer.portal_logo}
                    portalName={jobOffer.portal_name}
                    benefits={jobOffer.benefits}
                    publicationUrl={jobOffer.publication_url}
                    portalCode={jobOffer.portal_code}
                  />
                );
              })}
        </JobOffersContainer>
        <div ref={fetchNextPageRef}>
          {hasNextPage && (
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          )}
        </div>
        <FooterText variant="style36" data-testid="footer">
          {footer}
        </FooterText>
        {isAdmin && company && (
          <>
            <CustomizePlatformButton
              disableRipple
              data-testid="customize-platform-button"
              onClick={() => setIsCustomizeWebsiteDrawerOpen(true)}
            >
              <CutomizeButtonText variant="style18">
                {dictionary.customizeWebsiteDrawer.customizeWebsite}
              </CutomizeButtonText>
              <EditIcon src={editIcon} color={colors.white} />
            </CustomizePlatformButton>
            <CustomizeWebsiteDrawer
              open={isCustomizeWebsiteDrawerOpen}
              isSavingChanges={isSavingCustomizeWebsiteChanges}
              selectedCompany={company}
              formMethods={customizeCompanyFormMethods}
              onClose={() => setIsCustomizeWebsiteDrawerOpen(false)}
              onSaveHeaderChanges={onSaveHeaderChanges}
              onSaveFooterChanges={onSaveFooterChanges}
              onResetHeaderChanges={onResetHeaderChanges}
            />
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default JobOffers;
