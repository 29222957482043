import Backdrop from '@mui/material/Backdrop';
import uploadIcon from '@rexmas/rexmas-shared/images/upload-light-blue.svg';
import { RoundedButton } from '@rexmas/rexmas-shared/lib/components/atoms/buttons';
import { Card } from '@rexmas/rexmas-shared/lib/components/atoms/cards';
import { Icon } from '@rexmas/rexmas-shared/lib/components/atoms/icons';
import { Modal } from '@rexmas/rexmas-shared/lib/components/atoms/misc';
import { Text } from '@rexmas/rexmas-shared/lib/components/atoms/texts';
import FileUpload from '@rexmas/rexmas-shared/lib/components/molecules/forms/FileUpload';
import colors from '@rexmas/rexmas-shared/lib/conf/colors/colors';
import { elevations } from '@rexmas/rexmas-shared/lib/conf/constants/elevations';
import { mediaQueries } from '@rexmas/rexmas-shared/lib/conf/constants/mediaqueries';
import {
  SEND_MESSAGE_FILE_EXTENSIONS,
  SEND_MESSAGE_MAX_FILE_SIZE,
} from 'conf/chat';
import useDictionary from 'hooks/useDictionary';
import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const StyledBackdrop = styled(Backdrop)`
  background-color: ${colors.modalBackgroundFaded};
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 16px;
  outline: none;
  @media screen and (min-width: ${mediaQueries.sm}px) {
    width: 380px;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 28px;
  box-shadow: ${elevations.elevation1};
  border-radius: 15px;
  width: 100%;
`;

const Title = styled(Text)`
  color: ${colors.cobalt};
  margin: 8px 0 24px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin: 32px 0 0 0;
`;

const SubmitButton = styled(RoundedButton)`
  min-width: 92px;
`;

const CancelButton = styled(RoundedButton)`
  border: 2px solid ${colors.cobalt};
`;

const ColoredText = styled(Text)`
  color: ${(props) => props.color as string};
  margin: 0 4px;
`;

const FileUploadContainer = styled.div`
  margin-top: 8px;
`;

interface Props {
  open: boolean;
  onAddFile: (file: File) => void;
  onClose: () => void;
}

const AddFileModal: React.FunctionComponent<Props> = ({
  open,
  onAddFile,
  onClose,
}) => {
  const dictionary = useDictionary();
  const formMethods = useForm();
  const file = formMethods.watch('file')?.[0];

  return (
    <Modal
      open={open}
      slots={{ backdrop: StyledBackdrop }}
      closeOnBackdropClick
      onClose={onClose}
    >
      <ModalContainer>
        <StyledCard>
          <Icon src={uploadIcon} size={36} />
          <Title variant="heading6">{dictionary.chat.addFile}</Title>
          <FileUploadContainer>
            <FileUpload
              name="file"
              file={file}
              helperText={dictionary.chat.addFileHelperText}
              fileRestrictionsText={dictionary.chat.addFileRestrictionsText}
              formMethods={formMethods}
              accept={SEND_MESSAGE_FILE_EXTENSIONS}
              maxSize={SEND_MESSAGE_MAX_FILE_SIZE}
              shouldUnregister
            />
          </FileUploadContainer>
          <ButtonsContainer>
            <CancelButton variant="quinary" onClick={onClose}>
              <ColoredText variant="style23" color={colors.cobalt}>
                {dictionary.chat.cancel}
              </ColoredText>
            </CancelButton>
            <SubmitButton
              variant="secondary"
              type="submit"
              onClick={formMethods.handleSubmit(() => {
                onAddFile(file);
                onClose();
              })}
            >
              <ColoredText variant="style23" color={colors.white}>
                {dictionary.chat.add}
              </ColoredText>
            </SubmitButton>
          </ButtonsContainer>
        </StyledCard>
      </ModalContainer>
    </Modal>
  );
};

export default AddFileModal;
